import * as GlobalConfig from '../../../GlobalConfig'
const initialState = [];

function getCountryReducer(state = { apiData: [], fetching: false, fetched: false, error: null }, action) {
    switch (action.type) {
        case GlobalConfig.HC_COUNTRY_LIST_REQUEST: {
            return { ...state, fetching: true, fetched: false, error: null };
        }
        case GlobalConfig.HC_COUNTRY_LIST_FAIL: {
            return { ...state, fetching: false, fetched: true, error: action.payload, };
        }
        case GlobalConfig.HC_COUNTRY_LIST_SUCCESS: {
            return { ...state, fetching: false, fetched: true, apiData: action.payload, error: null,};
        }
        default:
            return state;
    }
}

function getStateReducer(state = { apiData: [], fetching: false, fetched: false, error: null }, action) {
    switch (action.type) {
        case GlobalConfig.HC_STATE_LIST_REQUEST: {
            return { ...state, fetching: true, fetched: false, error: null };
        }
        case GlobalConfig.HC_STATE_LIST_FAIL: {
            return { ...state, fetching: false, fetched: true, error: action.payload, };
        }
        case GlobalConfig.HC_STATE_LIST_SUCCESS: {
            return { ...state, fetching: false, fetched: true, apiData: action.payload, error: null,};
        }
        default:
            return state;
    }
}

function getCityReducer(state = { apiData: [], fetching: false, fetched: false, error: null }, action) {
    switch (action.type) {
        case GlobalConfig.HC_CITY_LIST_REQUEST: {
            return { ...state, fetching: true, fetched: false, error: null };
        }
        case GlobalConfig.HC_CITY_LIST_FAIL: {
            return { ...state, fetching: false, fetched: true, error: action.payload, };
        }
        case GlobalConfig.HC_CITY_LIST_SUCCESS: {
            return { ...state, fetching: false, fetched: true, apiData: action.payload, error: null,};
        }
        default:
            return state;
    }
}

function getPincodeReducer(state = { apiData: [], fetching: false, fetched: false, error: null }, action) {
    switch (action.type) {
        case GlobalConfig.HC_PINCODE_LIST_REQUEST: {
            return { ...state, fetching: true, fetched: false, error: null };
        }
        case GlobalConfig.HC_PINCODE_LIST_FAIL: {
            return { ...state, fetching: false, fetched: true, error: action.payload, };
        }
        case GlobalConfig.HC_PINCODE_LIST_SUCCESS: {
            return { ...state, fetching: false, fetched: true, apiData: action.payload, error: null,};
        }
        default:
            return state;
    }
}


function getCountryFilterReducer(state = { apiData: [], fetching: false, fetched: false, error: null }, action) {
    switch (action.type) {
        case GlobalConfig.HC_COUNTRY_LIST_FILTER: {
            return { ...state, fetching: false, fetched: true, apiData: action.payload, error: null,}
        }
        default:
            return state;
    }
}

function getStateFilterReducer(state = { apiData: [], fetching: false, fetched: false, error: null }, action) {
    switch (action.type) {
        case GlobalConfig.HC_STATE_LIST_FILTER: {
            return { ...state, fetching: false, fetched: true, apiData: action.payload, error: null,}
        }
        default:
            return state;
    }
}

function getCityFilterReducer(state = { apiData: [], fetching: false, fetched: false, error: null }, action) {
    switch (action.type) {
        case GlobalConfig.HC_CITY_LIST_FILTER: {
            return { ...state, fetching: false, fetched: true, apiData: action.payload, error: null,}
        }
        default:
            return state;
    }
}


export { getCountryReducer, getStateReducer, getCityReducer, getPincodeReducer, getCountryFilterReducer, getStateFilterReducer, getCityFilterReducer };