import {SELECTED_DOCOTORLIST_SUCCESS,SELECTED_DOCOTORLIST_FAIL} from '../Constants';

const initialState = {};

const saveDoctorDetails = (state = initialState, action) => {
    switch(action.type){
        case SELECTED_DOCOTORLIST_SUCCESS :{ return {...state,payload:action.payload}}
        case SELECTED_DOCOTORLIST_FAIL :{ return {...state,payload:{}}}
        default: return state
    }
}

export {saveDoctorDetails}