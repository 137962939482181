
import * as DBCnst from '../Constants/HospDBConstant';
const intialstate = {};

function AppHospitalperformanceDocterDetailsListReducer(state = {
    HospitalperformanceDocterDetailsList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case DBCnst.HospitalperformanceDocterDetailsList_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case DBCnst.HospitalperformanceDocterDetailsList_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case DBCnst.HospitalperformanceDocterDetailsList_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitalperformanceDocterDetailsList: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitalPerformanceDaslistListReducer(state = {
    HospitalPerformanceDaslist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case DBCnst.HospitalPerformanceDaslist_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case DBCnst.HospitalPerformanceDaslist_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case DBCnst.HospitalPerformanceDaslist_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitalPerformanceDaslist: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitalPerformanceREVlistReducer(state = {
    HospitalPerformanceREVlist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case DBCnst.HospitalPerformanceREVlist_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case DBCnst.HospitalPerformanceREVlist_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case DBCnst.HospitalPerformanceREVlist_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitalPerformanceREVlist: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitalPerformanceREV_divlistReducer(state = {
    HospitalPerformanceREV_divlist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case DBCnst.HospitalPerformanceREV_divlist_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case DBCnst.HospitalPerformanceREV_divlist_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case DBCnst.HospitalPerformanceREV_divlist_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitalPerformanceREV_divlist: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitalPerformanceSCM_divlistReducer(state = {
    HospitalPerformanceSCM_divlist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case DBCnst.HospitalPerformanceSCM_divlist_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case DBCnst.HospitalPerformanceSCM_divlist_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case DBCnst.HospitalPerformanceSCM_divlist_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitalPerformanceSCM_divlist: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitaldashboardDetailslistReducer(state = {
    HospitaldashbaordDetailslist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case DBCnst.HospitaldashboardDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case DBCnst.HospitaldashboardDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case DBCnst.HospitaldashboardDetails_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitaldashbaordDetailslist: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitaldashboardSCMlistReducer(state = {
    HospitaldashbaordSCMlist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case DBCnst.HospitaldashboardSCM_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case DBCnst.HospitaldashboardSCM_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case DBCnst.HospitaldashboardSCM_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitaldashbaordSCMlist: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitaldashboardREVlistReducer(state = {
    HospitaldashbaordREV: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case DBCnst.HospitaldashboardREV_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case DBCnst.HospitaldashboardREV_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case DBCnst.HospitaldashboardREV_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitaldashbaordREV: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitaldashbaordsetuplistReducer(state = {
    Hospitaldashbaordsetuplist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case DBCnst.Hospitaldashbaordsetup_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case DBCnst.Hospitaldashbaordsetup_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case DBCnst.Hospitaldashbaordsetup_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                Hospitaldashbaordsetuplist: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitalperformancelistdetailsReducer(state = {
    Hospitalperformancelistdetails: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case DBCnst.HospitalperformanceDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case DBCnst.HospitalperformanceDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case DBCnst.HospitalperformanceDetails_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                Hospitalperformancelistdetails: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitalperformancelistReducer(state = {
    Hospitalperformancelist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case DBCnst.Hospitalperformance_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case DBCnst.Hospitalperformance_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case DBCnst.Hospitalperformance_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                Hospitalperformancelist: action.payload
            }
        }
        default: return state;
    }

}

export {
     AppHospitalperformanceDocterDetailsListReducer, AppHospitalPerformanceDaslistListReducer, AppHospitalPerformanceREVlistReducer, AppHospitalperformancelistdetailsReducer,
      AppHospitalPerformanceREV_divlistReducer, AppHospitalperformancelistReducer, AppHospitalPerformanceSCM_divlistReducer, AppHospitaldashboardDetailslistReducer,
      AppHospitaldashboardSCMlistReducer, AppHospitaldashboardREVlistReducer, AppHospitaldashbaordsetuplistReducer

};