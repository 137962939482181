export const Country_List_REQUEST = 'Country_List_REQUEST';
export const Country_List_SUCCESS = 'Country_List_SUCCESS';
export const Country_List_FAIL = 'Country_List_FAIL';

export const Nationality_List_REQUEST = 'Nationality_List_REQUEST';
export const Nationality_List_SUCCESS = 'Nationality_List_SUCCESS';
export const Nationality_List_FAIL = 'Nationality_List_FAIL';

export const PARAMETEREMP_SETUP_REQUEST = 'PARAMETEREMP_SETUP_REQUEST'
export const PARAMETEREMP_SETUP_SUCCESS = 'PARAMETEREMP_SETUP_SUCCESS'
export const PARAMETEREMP_SETUP_FAIL = 'PARAMETEREMP_SETUP_FAIL'

export const State_List_REQUEST = 'State_List_REQUEST';
export const State_List_SUCCESS = 'State_List_SUCCESS';
export const State_List_FAIL = 'State_List_FAIL';

export const City_List_REQUEST = 'City_List_REQUEST';
export const City_List_SUCCESS = 'City_List_SUCCESS';
export const City_List_FAIL = 'City_List_FAIL';

export const Area_List_REQUEST = 'Area_List_REQUEST';
export const Area_List_SUCCESS = 'Area_List_SUCCESS';
export const Area_List_FAIL = 'Area_List_FAIL';

export const UserGroup_List_REQUEST = 'UserGroup_List_REQUEST';
export const UserGroup_List_SUCCESS = 'UserGroup_List_SUCCESS';
export const UserGroup_List_FAIL = 'UserGroup_List_FAIL';

export const UserSubGroup_List_REQUEST = 'UserSubGroup_List_REQUEST';
export const UserSubGroup_List_SUCCESS = 'UserSubGroup_List_SUCCESS';
export const UserSubGroup_List_FAIL = 'UserSubGroup_List_FAIL';

export const Titles_List_REQUEST = 'Titles_List_REQUEST';
export const Titles_List_SUCCESS = 'Titles_List_SUCCESS';
export const Titles_List_FAIL = 'Titles_List_FAIL';

export const Department_List_REQUEST = 'Department_List_REQUEST';
export const Department_List_SUCCESS = 'Department_List_SUCCESS';
export const Department_List_FAIL = 'Department_List_FAIL';

export const Department_Master_REQUEST = 'Department_Master_REQUEST';
export const Department_Master_FAIL = 'Department_Master_FAIL';
export const Department_Master_SUCCESS = 'Department_Master_SUCCESS';

export const EmploymentType_List_REQUEST = 'EmploymentType_List_REQUEST';
export const EmploymentType_List_SUCCESS = 'EmploymentType_List_SUCCESS';
export const EmploymentType_List_FAIL = 'EmploymentType_List_FAIL';

export const PrimarySpecility_List_REQUEST = 'PrimarySpecility_List_REQUEST';
export const PrimarySpecility_List_SUCCESS = 'PrimarySpecility_List_SUCCESS';
export const PrimarySpecility_List_FAIL = 'PrimarySpecility_List_FAIL';

export const SecondarySpecility_List_REQUEST = 'SecondarySpecility_List_REQUEST';
export const SecondarySpecility_List_SUCCESS = 'SecondarySpecility_List_SUCCESS';
export const SecondarySpecility_List_FAIL = 'SecondarySpecility_List_FAIL';

export const DefaultlandingPage_List_REQUEST = 'DefaultlandingPage_List_REQUEST';
export const DefaultlandingPage_List_SUCCESS = 'DefaultlandingPage_List_SUCCESS';
export const DefaultlandingPage_List_FAIL = 'DefaultlandingPage_List_FAIL';

export const PasswordPolicy_List_REQUEST = 'PasswordPolicy_List_REQUEST';
export const PasswordPolicy_List_SUCCESS = 'PasswordPolicy_List_SUCCESS';
export const PasswordPolicy_List_FAIL = 'PasswordPolicy_List_FAIL';

export const Role_List_REQUEST = 'Role_List_REQUEST';
export const Role_List_SUCCESS = 'Role_List_SUCCESS';
export const Role_List_FAIL = 'Role_List_FAIL';

export const Role_TagFromEmployee_List_REQUEST = 'Role_TagFromEmployee_List_REQUEST';
export const Role_TagFromEmployee_List_SUCCESS = 'Role_TagFromEmployee_List_SUCCESS';
export const Role_TagFromEmployee_List_FAIL = 'Role_TagFromEmployee_List_FAIL';

export const User_SAVE_REQUEST = 'User_SAVE_REQUEST';
export const User_SAVE_SUCCESS = 'User_SAVE_SUCCESS';
export const User_SAVE_FAIL = 'User_SAVE_FAIL';
export const User_SAVE_RESET = 'User_SAVE_RESET';

export const User_SAVE_Emp_Img_REQUEST = 'User_SAVE_Emp_Img_REQUEST';
export const User_SAVE_Emp_Img_SUCCESS = 'User_SAVE_Emp_Img_SUCCESS';
export const User_SAVE_Emp_Img_FAIL = 'User_SAVE_Emp_Img_FAIL';

export const User_SAVE_Emp_Sign_REQUEST = 'User_SAVE_Emp_Sign_REQUEST';
export const User_SAVE_Emp_Sign_SUCCESS = 'User_SAVE_Emp_Sign_SUCCESS';
export const User_SAVE_Emp_Sign_FAIL = 'User_SAVE_Emp_Sign_FAIL';


export const PasswordPolicy_SAVE_REQUEST = 'PasswordPolicy_SAVE_REQUEST';
export const PasswordPolicy_SAVE_SUCCESS = 'PasswordPolicy_SAVE_SUCCESS';
export const PasswordPolicy_SAVE_FAIL = 'PasswordPolicy_SAVE_FAIL';
export const PasswordPolicy_SAVE_RESET = 'PasswordPolicy_SAVE_RESET';

export const Role_SAVE_REQUEST = 'Role_SAVE_REQUEST';
export const Role_SAVE_SUCCESS = 'Role_SAVE_SUCCESS';
export const Role_SAVE_FAIL = 'Role_SAVE_FAIL';
export const Role_SAVE_RESET = 'Role_SAVE_RESET';

export const Delete_REQUEST = 'Delete_REQUEST';
export const Delete_SUCCESS = 'Delete_SUCCESS';
export const Delete_Fail = 'Delete_Fail';


export const ClientVisit_List_REQUEST = 'ClientVisit_List_REQUEST';
export const ClientVisit_List_SUCCESS = 'ClientVisit_List_SUCCESS';
export const ClientVisit_List_FAIL = 'ClientVisit_List_FAIL';

export const IPVisit_List_REQUEST = 'IPVisit_List_REQUEST';
export const IPVisit_List_SUCCESS = 'IPVisit_List_SUCCESS';
export const IPVisit_List_FAIL = 'IPVisit_List_FAIL';

export const Service_List_REQUEST = 'Service_List_REQUEST';
export const Service_List_SUCCESS = 'Service_List_SUCCESS';
export const Service_List_FAIL = 'Service_List_FAIL';

export const Service_List_OP_REQUEST = 'Service_List_OP_REQUEST';
export const Service_List_OP_SUCCESS = 'Service_List_OP_SUCCESS';
export const Service_List_OP_FAIL = 'Service_List_OP_FAIL';

export const List_REQUEST = 'List_REQUEST';
export const List_SUCCESS = 'List_SUCCESS';
export const List_FAIL = 'List_FAIL';

export const Facility_List_REQUEST = 'Facility_List_REQUEST';
export const Facility_List_SUCCESS = 'Facility_List_SUCCESS';
export const Facility_List_FAIL = 'Facility_List_FAIL';

export const Module_List_REQUEST = 'Module_List_REQUEST';
export const Module_List_SUCCESS = 'Module_List_SUCCESS';
export const Module_List_FAIL = 'Module_List_FAIL';

export const Page_LIST_REQUEST = 'Page_LIST_REQUEST';
export const Page_LIST_SUCCESS = 'Page_LIST_SUCCESS';
export const Page_LIST_FAIL = 'Page_LIST_FAIL';

export const Section_LIST_REQUEST = 'Section_LIST_REQUEST';
export const Section_LIST_SUCCESS = 'Section_LIST_SUCCESS';
export const Section_LIST_FAIL = 'Section_LIST_FAIL';

export const Button_LIST_REQUEST = 'Button_LIST_REQUEST';
export const Button_LIST_SUCCESS = 'Button_LIST_SUCCESS';
export const Button_LIST_FAIL = 'Button_LIST_FAIL';

export const Document_UHID_REQUEST = 'Document_UHID_REQUEST';
export const Document_UHID_SUCCESS = 'Document_UHID_SUCCESS';
export const Document_UHID_FAIL = 'Document_UHID_FAIL';

export const EmploymentType_LIST_REQUEST = 'EmploymentType_LIST_REQUEST';
export const EmploymentType_LIST_SUCCESS = 'EmploymentType_LIST_SUCCESS';
export const EmploymentType_LIST_FAIL = 'EmploymentType_LIST_FAIL';


export const SeachEmployee_LIST_REQUEST = 'SeachEmployee_LIST_REQUEST';
export const SeachEmployee_LIST_SUCCESS = 'SeachEmployee_LIST_SUCCESS';
export const SeachEmployee_LIST_FAIL = 'SeachEmployee_LIST_FAIL';

export const DoctorTeamsdetails_LIST_REQUEST = 'DoctorTeamsdetails_LIST_REQUEST';
export const DoctorTeamsdetails_LIST_SUCCESS = 'DoctorTeamsdetails_LIST_SUCCESS';
export const DoctorTeamsdetails_LIST_FAIL = 'DoctorTeamsdetails_LIST_FAIL';

export const Team_LIST_REQUEST = 'Team_LIST_REQUEST';
export const Team_LIST_SUCCESS = 'Team_LIST_SUCCESS';
export const Team_LIST_FAIL = 'Team_LIST_FAIL';

export const SAVE_REQUEST = 'SAVE_REQUEST';
export const SAVE_SUCCESS = 'SAVE_SUCCESS';
export const SAVE_FAIL = 'SAVE_FAIL';
export const SAVE_RESET = 'SAVE_RESET';

export const SAVE_TEAM_REQUEST = 'SAVE_TEAM_REQUEST';
export const SAVE_TEAM_SUCCESS = 'SAVE_TEAM_SUCCESS';
export const SAVE_TEAM_FAIL = 'SAVE_TEAM_FAIL';
export const SAVE_TEAM_RESET = 'SAVE_TEAM_RESET';

export const SAVE_EmployeeBenifits_REQUEST = 'SAVE_EmployeeBenifits_REQUEST';
export const SAVE_EmployeeBenifits_SUCCESS = 'SAVE_EmployeeBenifits_SUCCESS';
export const SAVE_EmployeeBenifits_FAIL = 'SAVE_EmployeeBenifits_FAIL';
export const SAVE_EmployeeBenifits_RESET = 'SAVE_EmployeeBenifits_RESET';

export const SAVE_OTHERPermission_REQUEST = 'SAVE_OTHERPermission_REQUEST';
export const SAVE_OTHERPermission_SUCCESS = 'SAVE_OTHERPermission_SUCCESS';
export const SAVE_OTHERPermission_FAIL = 'SAVE_OTHERPermission_FAIL';
export const SAVE_OTHERPermission_RESET = 'SAVE_OTHERPermission_RESET';

export const SAVE_ROLE_REQUEST = 'SAVE_ROLE_REQUEST';
export const SAVE_ROLE_SUCCESS = 'SAVE_ROLE_SUCCESS';
export const SAVE_ROLE_FAIL = 'SAVE_ROLE_FAIL';
export const SAVE_ROLE_RESET = 'SAVE_ROLE_RESET';


export const GradeId_LIST_REQUEST = 'GradeId_LIST_REQUEST';
export const GradeId_LIST_SUCCESS = 'GradeId_LIST_SUCCESS';
export const GradeId_LIST_FAIL = 'GradeId_LIST_FAIL';


export const RoleMapedWithEmp_LIST_REQUEST = 'RoleMapedWithEmp_LIST_REQUEST';
export const RoleMapedWithEmp_LIST_SUCCESS = 'RoleMapedWithEmp_LIST_SUCCESS';
export const RoleMapedWithEmp_LIST_FAIL = 'RoleMapedWithEmp_LIST_FAIL';


export const Designation_SAVE_REQUEST = 'Designation_SAVE_REQUEST';
export const Designation_SAVE_SUCCESS = 'Designation_SAVE_SUCCESS';
export const Designation_SAVE_FAIL = 'Designation_SAVE_FAIL';

export const EMPLOYEE_FACILITY_MAPPING_SAVE_REQUEST = 'EMPLOYEE_FACILITY_MAPPING_SAVE_REQUEST';
export const EMPLOYEE_FACILITY_MAPPING_SAVE_SUCCESS = 'EMPLOYEE_FACILITY_MAPPING_SAVE_SUCCESS';
export const DEMPLOYEE_FACILITY_MAPPING_SAVE_FAIL = 'DEMPLOYEE_FACILITY_MAPPING_SAVE_FAIL';
export const RoleWithEmployee_List_REQUEST = 'RoleWithEmployee_List_REQUEST';
export const RoleWithEmployee_List_SUCCESS = 'RoleWithEmployee_List_SUCCESS';
export const RoleWithEmployee_List_FAIL = 'RoleWithEmployee_List_FAIL';

export const User_Doctor_Permission_SAVE_REQUEST = 'User_Doctor_Permission_SAVE_REQUEST';
export const User_Doctor_Permission_SUCCESS = 'User_Doctor_Permission_SUCCESS';
export const User_Doctor_Permission_FAIL = 'User_Doctor_Permission_FAIL';

export const User_Doctor_Visit_SAVE_REQUEST = 'User_Doctor_Visit_SAVE_REQUEST';
export const User_Doctor_Visit_SUCCESS = 'User_Doctor_Visit_SUCCESS';
export const User_Doctor_Visit_FAIL = 'User_Doctor_Visit_FAIL';


export const SEARCH_EMPLOYEE_DATA_SAVE_REQUEST = 'SEARCH_EMPLOYEE_DATA_SAVE_REQUEST';
export const SEARCH_EMPLOYEE_DATA_SUCCESS = 'SEARCH_EMPLOYEE_DATA_SUCCESS';
export const SEARCH_EMPLOYEE_DATA_FAIL = 'SEARCH_EMPLOYEE_DATA  _FAIL';



export const CheckMobileEmailIdExistOrNot_LIST_REQUEST = 'CheckMobileEmailIdExistOrNot_LIST_REQUEST';
export const CheckMobileEmailIdExistOrNot_LIST_SUCCESS = 'CheckMobileEmailIdExistOrNot_LIST_SUCCESS';
export const CheckMobileEmailIdExistOrNot_LIST_FAIL = 'CheckMobileEmailIdExistOrNot_LIST_FAIL';



export const GetEmployeeAudit_LIST_REQUEST = 'GetEmployeeAudit_LIST_REQUEST';
export const GetEmployeeAudit_LIST_SUCCESS = 'GetEmployeeAudit_LIST_SUCCESS';
export const GetEmployeeAudit_LIST_FAIL = 'GetEmployeeAudit_LIST_FAIL';


export const GetFacilityMappingAudit_LIST_REQUEST = 'GetFacilityMappingAudit_LIST_REQUEST';
export const GetFacilityMappingAudit_LIST_SUCCESS = 'GetFacilityMappingAudit_LIST_SUCCESS';
export const GetFacilityMappingAudit_LIST_FAIL = 'GetFacilityMappingAudit_LIST_FAIL';


export const GetEmployeePageEncounterPermissionsAudit_LIST_REQUEST = 'GetEmployeePageEncounterPermissionsAudit_LIST_REQUEST';
export const GetEmployeePageEncounterPermissionsAudit_LIST_SUCCESS = 'GetEmployeePageEncounterPermissionsAudit_LIST_SUCCESS';
export const GetEmployeePageEncounterPermissionsAudit_LIST_FAIL = 'GetEmployeePageEncounterPermissionsAudit_LIST_FAIL';


export const GetTeamAudit_LIST_REQUEST = 'GetTeamAudit_LIST_REQUEST';
export const GetTeamAudit_LIST_SUCCESS = 'GetTeamAudit_LIST_SUCCESS';
export const GetTeamAudit_LIST_FAIL = 'GetTeamAudit_LIST_FAIL';


export const GetServiceAttribute_Audit_LIST_REQUEST = 'GetServiceAttribute_Audit_LIST_REQUEST';
export const GetServiceAttribute_Audit_LIST_SUCCESS = 'GetServiceAttribute_Audit_LIST_SUCCESS';
export const GetServiceAttribute_Audit_LIST_FAIL = 'GetServiceAttribute_Audit_LIST_FAIL';

export const GetControlledPriceAudit_LIST_REQUEST = 'GetControlledPriceAudit_LIST_REQUEST';
export const GetControlledPriceAudit_LIST_SUCCESS = 'GetControlledPriceAudit_LIST_SUCCESS';
export const GetControlledPriceAudit_LIST_FAIL = 'GetControlledPriceAudit_LIST_FAIL';


export const GetEquipmentChargeSetupAudit_LIST_REQUEST = 'GetEquipmentChargeSetupAudit_LIST_REQUEST';
export const GetEquipmentChargeSetupAudit_LIST_SUCCESS = 'GetEquipmentChargeSetupAudit_LIST_SUCCESS';
export const GetEquipmentChargeSetupAudit_LIST_FAIL = 'GetEquipmentChargeSetupAudit_LIST_FAIL';


export const GetDiscountConfigurationt_Audit_LIST_REQUEST = 'GetDiscountConfigurationt_Audit_LIST_REQUEST';
export const GetDiscountConfiguration_Audit_LIST_SUCCESS = 'GetDiscountConfiguration_Audit_LIST_SUCCESS';
export const GetDiscountConfiguration_Audit_LIST_FAIL = 'GetDiscountConfiguration_Audit_LIST_FAIL';

export const GetDiscountConfigurationTemplate_Audit_LIST_REQUEST = 'GetDiscountConfigurationTemplate_Audit_LIST_REQUEST';
export const GetDiscountConfigurationTemplate_Audit_LIST_SUCCESS = 'GetDiscountConfigurationTemplate_Audit_LIST_SUCCESS';
export const GetDiscountConfigurationTemplate_Audit_LIST_FAIL = 'GetDiscountConfigurationTemplate_Audit_LIST_FAIL';

