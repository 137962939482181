import * as AMCnst from '../AppConstants/AppConstants';

const intialstate = {};
const intialstate2 = [];

//Registration Type Reducer
function AppRegistrationTypeReducer(state = {
    RegistrationTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.REGISTRATIONTYPE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.REGISTRATIONTYPE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.REGISTRATIONTYPE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                RegistrationTypeData: action.payload
            }

        }
        default: return state;
    }

}


function TariffChargeAuditReducer(state = {
    TariffChargeAuditData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.TariffChargeAudit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.TariffChargeAudit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.TariffChargeAudit_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                TariffChargeAuditData: action.payload
            }

        }
        default: return intialstate;
    }

}




function ServiceMstAuditReducer(state = {
    ServiceMstAuditData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ServiceMstAudit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ServiceMstAudit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ServiceMstAudit_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceMstAuditData: action.payload
            }

        }
        default: return state;
    }

}



function GetReasonMasterAuditReducer(state = {
    TariffChargeAuditData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetReasonMasterAudit_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetReasonMasterAudit_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetReasonMasterAudit_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReasonMasterAuditData: action.payload
            }

        }
        default: return state;
    }

}

function getUploadPrescriptionAuditReducer(state = {
    UploadPresciptionAuditData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.getUploadPrescriptionAudit_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.getUploadPrescriptionAudit_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.getUploadPrescriptionAudit_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                UploadPresciptionAuditData: action.payload
            }

        }
        default: return state;
    }

}

function getInpatientRequestAuditReducer(state = {
    InpatientRequestAuditData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.getInpatientRequestAudit_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.getInpatientRequestAudit_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.getInpatientRequestAudit_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InpatientRequestAuditData: action.payload
            }

        }
        default: return state;
    }

}




function GetModuleReasonAuditReducer(state = {
    ModuleReasonAuditData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetModuleReasonAudit_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetModuleReasonAudit_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetModuleReasonAudit_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ModuleReasonAuditData: action.payload
            }

        }
        default: return state;
    }

}





//Title  Reducer
function AppTitleReducer(state = {
    TitleData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.TITLE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.TITLE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.TITLE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                TitleData: action.payload
            }
        }
        default: return state;
    }

}
//Status By Code  Reducer
function fetchStatusByType(state = {
    StatusByTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.STATUSBYTYPE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.STATUSBYTYPE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.STATUSBYTYPE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                StatusByTypeData: action.payload
            }
        }
        default: return state;
    }

}

//Status By Code  Reducer
function fetchStatusByTypeForGlobal(state = {
    StatusByTypeDataGlobal: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.STATUSBYTYPE_LIST_REQUEST_Global: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.STATUSBYTYPE_LIST_FAIL_Global: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.STATUSBYTYPE_LIST_SUCCESS_Global: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                StatusByTypeDataGlobal: action.payload
            }
        }
        default: return state;
    }

}
//Nationality Reducer
function AppNationalityReducer(state = {
    NationalityData: [intialstate],
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.NATIONALITY_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.NATIONALITY_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.NATIONALITY_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                NationalityData: action.payload
            }
        }
        default: return state;
    }
}
//Relationship Reducer
function AppRelationshipReducer(state = {
    RelationshipData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RELATIONSHIP_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RELATIONSHIP_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RELATIONSHIP_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                RelationshipData: action.payload
            }
        }
        default: return state;
    }

}



function AppReligionReducer(state = {
    ReligionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RELIGION_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RELIGION_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RELIGION_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReligionData: action.payload
            }
        }
        default: return state;
    }

}

function AppOccupationReducer(state = {
    OccupationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.OCCUPATION_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.OCCUPATION_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.OCCUPATION_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OccupationData: action.payload
            }
        }
        default: return state;
    }
}



function AppReferralReducer(state = {
    ReferralData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.REFERRAL_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.REFERRAL_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.REFERRAL_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReferralData: action.payload
            }
        }
        default: return state;
    }
}


function AppReferralTypeReducer(state = {
    ReferralTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.REFERRALTYPE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.REFERRALTYPE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.REFERRALTYPE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReferralTypeData: action.payload
            }
        }
        default: return state;
    }
}


function AppDocumentTypeReducer(state = {
    DocumentTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DOCUMENTTYPE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DOCUMENTTYPE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DOCUMENTTYPE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DocumentTypeData: action.payload
            }
        }
        default: return state;
    }
}

function AppEducationReducer(state = {
    EducationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EDUCATION_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EDUCATION_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EDUCATION_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EducationData: action.payload
            }
        }
        default: return state;
    }

}

function AppLanguageReducer(state = {
    LanguageData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.LANGUAGE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.LANGUAGE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.LANGUAGE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                LanguageData: action.payload
            }
        }
        default: return state;
    }

}


function AppHWCTypeReducer(state = {
    HWCTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HWCTYPE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HWCTYPE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HWCTYPE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HWCTypeData: action.payload
            }
        }
        default: return state;
    }

}


function AppCountryReducer(state = {
    CountryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COUNTRY_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COUNTRY_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COUNTRY_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CountryData: action.payload
            }
        }
        default: return state;
    }

}

function AppBillingCountryReducer(state = {
    BillingCountryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COUNTRY_LIST_BILLING_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COUNTRY_LIST_BILLING_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COUNTRY_LIST_BILLING_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BillingCountryData: action.payload
            }
        }
        default: return state;
    }

}


function AppStateByCountryReducer(state = {
    StateData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.STATE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.STATE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.STATE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                StateData: action.payload
            }
        }
        default: return state;
    }

}

function AppBillingStateByCountryReducer(state = {
    BillingStateData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.STATE_LIST_BILLING_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.STATE_LIST_BILLING_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.STATE_LIST_BILLING_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BillingStateData: action.payload
            }
        }
        default: return state;
    }

}

function AppCityReducer(state = {
    CityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CITY_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CITY_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CITY_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CityData: action.payload
            }
        }
        default: return state;
    }

}


function AppBillingCityReducer(state = {
    BillingCityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CITY_LIST_BILLING_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CITY_LIST_BILLING_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CITY_LIST_BILLING_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BillingCityData: action.payload
            }
        }
        default: return state;
    }

}

function AppAreaReducer(state = {
    AreaData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AREA_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AREA_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AREA_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AreaData: action.payload
            }
        }
        default: return state;
    }

}

function AppBillingAreaReducer(state = {
    BillingAreaData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AREA_LIST_BILLING_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AREA_LIST_BILLING_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AREA_LIST_BILLING_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BillingAreaData: action.payload
            }
        }
        default: return state;
    }

}


function AppAreaPERReducer(state = {
    AreaDataPer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AREAPER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AREAPER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AREAPER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AreaDataPer: action.payload
            }
        }
        default: return state;
    }

}
function AppServiceNomenclatureReducer(state = {
    AppServiceNomenclature: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ServiceNomenclature_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ServiceNomenclature_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ServiceNomenclature_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AppServiceNomenclature: action.payload
            }
        }
        default: return state;
    }
}


function AppHCFReducer(state = {
    HCFData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HCF_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HCF_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HCF_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HCFData: action.payload
            }
        }
        default: return state;
    }

}

function AppPayorCategoryReducer(state = {
    PayorCategoryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PAYORCAT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PAYORCAT_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PAYORCAT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PayorCategoryData: action.payload
            }
        }
        default: return state;
    }

}

function AppCompanyReducer(state = {
    CompanyData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PAYOR_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PAYOR_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PAYOR_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompanyData: action.payload
            }
        }
        default: return state;
    }

}

function EmployerReducer(state = {
    EmployerData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EMPLOYER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EMPLOYER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EMPLOYER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmployerData: action.payload
            }
        }
        default: return state;
    }

}

function AppCurrencyReducer(state = {
    CurrencyData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CURRENCY_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CURRENCY_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CURRENCY_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CurrencyData: action.payload
            }
        }
        default: return state;
    }

}
function AppHospitalperformancelistReducer(state = {
    Hospitalperformancelist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Hospitalperformance_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Hospitalperformance_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Hospitalperformance_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                Hospitalperformancelist: action.payload
            }
        }
        default: return state;
    }

}

function ReportRevenueDepartmentWiseReducer(state = {
    ReportRevenueDepartmentWiseData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ReportRevenueDepartmentWise_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ReportRevenueDepartmentWise_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ReportRevenueDepartmentWise_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReportRevenueDepartmentWiseData: action.payload
            }
        }
        default: return state;
    }

}

function MisInventoryAnalysisReportReducer(state = {
    MisInventoryAnalysisReportData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.MisInventoryAnalysisReport_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.MisInventoryAnalysisReport_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.MisInventoryAnalysisReport_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                MisInventoryAnalysisReportData: action.payload
            }
        }
        default: return state;
    }

}
function AppHospitalperformanceDocterDetailsListReducer(state = {
    HospitalperformanceDocterDetailsList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HospitalperformanceDocterDetailsList_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HospitalperformanceDocterDetailsList_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HospitalperformanceDocterDetailsList_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitalperformanceDocterDetailsList: action.payload
            }
        }
        default: return state;
    }

}
function AppHospitalPerformanceDaslistListReducer(state = {
    HospitalPerformanceDaslist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HospitalPerformanceDaslist_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HospitalPerformanceDaslist_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HospitalPerformanceDaslist_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitalPerformanceDaslist: action.payload
            }
        }
        default: return state;
    }

}
function AppHospitalPerformanceREVlistReducer(state = {
    HospitalPerformanceREVlist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HospitalPerformanceREVlist_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HospitalPerformanceREVlist_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HospitalPerformanceREVlist_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitalPerformanceREVlist: action.payload
            }
        }
        default: return state;
    }

}
function AppHospitalPerformanceREV_divlistReducer(state = {
    HospitalPerformanceREV_divlist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HospitalPerformanceREV_divlist_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HospitalPerformanceREV_divlist_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HospitalPerformanceREV_divlist_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitalPerformanceREV_divlist: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitalPerformanceSCM_divlistReducer(state = {
    HospitalPerformanceSCM_divlist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HospitalPerformanceSCM_divlist_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HospitalPerformanceSCM_divlist_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HospitalPerformanceSCM_divlist_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitalPerformanceSCM_divlist: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitaldashboardDetailslistReducer(state = {
    HospitaldashbaordDetailslist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HospitaldashboardDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HospitaldashboardDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HospitaldashboardDetails_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitaldashbaordDetailslist: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitaldashboardSCMlistReducer(state = {
    HospitaldashbaordSCMlist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HospitaldashboardSCM_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HospitaldashboardSCM_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HospitaldashboardSCM_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitaldashbaordSCMlist: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitaldashboardREVlistReducer(state = {
    HospitaldashbaordREV: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HospitaldashboardREV_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HospitaldashboardREV_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HospitaldashboardREV_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HospitaldashbaordREV: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitaldashbaordsetuplistReducer(state = {
    Hospitaldashbaordsetuplist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Hospitaldashbaordsetup_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Hospitaldashbaordsetup_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Hospitaldashbaordsetup_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                Hospitaldashbaordsetuplist: action.payload
            }
        }
        default: return state;
    }

}

function AppHospitalperformancelistdetailsReducer(state = {
    Hospitalperformancelistdetails: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HospitalperformanceDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HospitalperformanceDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HospitalperformanceDetails_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                Hospitalperformancelistdetails: action.payload
            }
        }
        default: return state;
    }

}
function AppDepartmentNameReducer(state = {
    DepartmentName: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DepartmentName_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DepartmentName_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DepartmentName_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DepartmentName: action.payload
            }
        }
        default: return state;
    }

}
function AppMonthReducer(state = {
    MonthData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Month_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Month_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Month_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                MonthData: action.payload
            }
        }
        default: return state;
    }

}
function AppYearMonthReducer(state = {
    YearMonth: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.YearMonth_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.YearMonth_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.YearMonth_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                YearMonth: action.payload
            }
        }
        default: return state;
    }

}

function CurrencyMasterReducer(state = {
    CurrencyMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CURRENCYMASTER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CURRENCYMASTER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CURRENCYMASTER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CurrencyMasterData: action.payload
            }
        }
        default: return state;
    }

}

function SaveCurrencyMasterReducer(state = {
    saveCurrencyData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CURRENCYMASTER_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CURRENCYMASTER_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CURRENCYMASTER_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveCurrencyData: action.payload
            }
        }
        default: return state;
    }

}


function AppCurrencyexReducer(state = {
    CurrencyexData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CURRENCYEX_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CURRENCYEX_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true, CurrencyexData: intialstate } }
        case AMCnst.CURRENCYEX_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CurrencyexData: action.payload
            }
        }
        default: return state;
    }

}






function AppCompanyBeneficiaryReducer(state = {
    CompBeneficiaryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPBENE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPBENE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPBENE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompBeneficiaryData: action.payload
            }
        }
        default: return state;
    }

}

function AppCompanyFacilityReducer(state = {
    CompFacilityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPFAC_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPFAC_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPFAC_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompFacilityData: action.payload
            }
        }
        default: return state;
    }

}


function AppCompanywiseFacilityReducer(state = {
    CompWFacilityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPWFAC_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPWFAC_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPWFAC_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompWFacilityData: action.payload
            }
        }
        default: return state;
    }

}

function AppCompanySponsorReducer(state = {
    CompSponsorTPAData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPSPO_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPSPO_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPSPO_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompSponsorTPAData: action.payload
            }
        }
        default: return state;
    }

}



function GetPayorPageAuditExclusionItemReducer(state = {
    PayorPageAuditExclusionItemData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetPayorPageAuditExclusionItem_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetPayorPageAuditExclusionItem_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetPayorPageAuditExclusionItem_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PayorPageAuditExclusionItemData: action.payload
            }
        }
        default: return state;
    }

}

function AppCompanyTariffReducer(state = {
    CompTariffData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPTAR_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPTAR_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPTAR_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompTariffData: action.payload
            }
        }
        default: return state;
    }

}

function AppCompanyMarkupReducer(state = {
    CompMarkupData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPMARK_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPMARK_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPMARK_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompMarkupData: action.payload
            }
        }
        default: return state;
    }

}

function AppCompanyMarkupExlReducer(state = {
    CompMarkupExlData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPMARKEXL_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPMARKEXL_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPMARKEXL_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompMarkupExlData: action.payload
            }
        }
        default: return state;
    }

}


function AppCompanyExclReducer(state = {
    CompExclData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPSEREXL_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPSEREXL_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPSEREXL_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompExclData: action.payload
            }
        }
        default: return state;
    }

}

function AppCompNonDiscReducer(state = {
    CompNonDiscData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPNDISC_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPNDISC_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPNDISC_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompNonDiscData: action.payload
            }
        }
        default: return state;
    }

}

function AppCompBedCatReducer(state = {
    CompBedCatData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPBEDCAT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPBEDCAT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPBEDCAT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompBedCatData: action.payload
            }
        }
        default: return state;
    }

}

function AppCompanyExlAutoReducer(state = {
    CompanyExlAutoData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPEXLAUTO_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPEXLAUTO_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPEXLAUTO_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompanyExlAutoData: action.payload
            }
        }
        default: return state;
    }

}


function AppGetCompanyDataReducer(state = {
    GetCompanyData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMP_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMP_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMP_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetCompanyData: action.payload
            }
        }
        default: return state;
    }

}


function AppReportFormatOPReducer(state = {
    ReportFormatDataOP: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.REPFOROP_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.REPFOROP_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.REPFOROP_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReportFormatDataOP: action.payload
            }
        }
        default: return state;
    }

}

function AppReportFormatERReducer(state = {
    ReportFormatDataER: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.REPFORER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.REPFORER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.REPFORER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReportFormatDataER: action.payload
            }
        }
        default: return state;
    }
}

function AppReportFormatIPReducer(state = {
    ReportFormatDataIP: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.REPFORIP_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.REPFORIP_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.REPFORIP_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReportFormatDataIP: action.payload
            }
        }
        default: return state;
    }

}



function AppFacilityReducer(state = {
    FacilityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FACILITY_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FACILITY_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FACILITY_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FacilityData: action.payload
            }
        }
        default: return state;
    }

}
function AppFacilityEMPReducer(state = {
    FacilityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FACILITYEMP_LIST_REQUEST: { return { ...state, fetched: false, fetching: true, error: null } }
        case AMCnst.FACILITYEMP_LIST_FAIL: { return { ...state, fetching: false, fetched: true, FacilityData: intialstate, error: action.payload, fetched: true } }
        case AMCnst.FACILITYEMP_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FacilityData: action.payload
            }
        }
        default: return state;
    }

}


function GetReportMasterDataReducer(state = {
    ReportMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.REPORT_DATA_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.REPORT_DATA_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.REPORT_DATA_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReportMasterData: action.payload
            }
        }
        default: return state;
    }

}


function GetReportMasterParametersOPtionReducer(state = {
    ReportMasterParametersOptionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.REPORT_DATA_Parameter_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.REPORT_DATA_Parameter_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.REPORT_DATA_Parameter_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReportMasterParametersOptionData: action.payload
            }
        }
        default: return state;
    }

}


function AppEmployeeReducer(state = {
    EmployeeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EMP_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EMP_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EMP_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmployeeData: action.payload
            }
        }
        default: return state;
    }

}

function AppEmployeeBySpecialityReducer(state = {
    EmployeeBySpecialityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EMPBYSPECIALITY_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EMPBYSPECIALITY_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EMPBYSPECIALITY_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmployeeBySpecialityData: action.payload
            }
        }
        default: return state;
    }

}

function AppEmployeeCopyReducer(state = {
    EmployeeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EMP_COPY_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EMP_COPY_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EMP_COPY_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmployeeData: action.payload
            }
        }
        default: return state;
    }

}

function AppServiceByServiceCategoryReducer(state = {
    ServiceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ServiceByServiceCategory_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ServiceByServiceCategory_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ServiceByServiceCategory_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceData: action.payload
            }
        }
        default: return state;
    }

}

function AppPayorPlanReducer(state = {
    PayorPlanData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PAYORPLAN_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PAYORPLAN_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PAYORPLAN_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PayorPlanData: action.payload
            }
        }
        default: return state;
    }

}


function AppTariffReducer(state = {
    TariffData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.TARIFF_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.TARIFF_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.TARIFF_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                TariffData: action.payload
            }
        }
        default: return state;
    }

}
function AppTariffSaveReducer(state = {
    TariffSaveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.TARIFF_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.TARIFF_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.TARIFF_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                TariffSaveData: action.payload
            }
        }
        default: return state;
    }

}
function AppLoginTariffReducer(state = {
    TariffLoginData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.TARIFFLOGIN_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.TARIFFLOGIN_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.TARIFFLOGIN_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                TariffLoginData: action.payload
            }
        }
        default: return state;
    }

}

function AppTariffRuleReducer(state = {
    TariffRuleData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.TARIFFRULE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.TARIFFRULE_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true } }
        case AMCnst.TARIFFRULE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                TariffRuleData: action.payload
            }
        }
        default: return state;
    }

}


function AppDiscountTemplateReducer(state = {
    DiscTempData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DISCTEM_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DISCTEM_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DISCTEM_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DiscTempData: action.payload
            }
        }
        default: return state;
    }

}

function saveCompanyReducer(state = {
    CompanySavedData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMP_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMP_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMP_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompanySavedData: action.payload
            }
        }
        default: return state;
    }

}


function AppDepartmentReducer(state = {
    DeptMstData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DEPTMST_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DEPTMST_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DEPTMST_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DeptMstData: action.payload
            }
        }
        default: return state;
    }

}


function AppDeptByServiceTypeReducer(state = {
    DeptMstDataByServiceType: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DEPTMSTBYSERVICETYPE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DEPTMSTBYSERVICETYPE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DEPTMSTBYSERVICETYPE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DeptMstDataByServiceType: action.payload
            }
        }
        default: return state;
    }

}

function AppDepartmentSubReducer(state = {
    DeptSubMstData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DEPTSUBMST_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DEPTSUBMST_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DEPTSUBMST_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DeptSubMstData: action.payload
            }
        }
        default: return state;
    }

}

function AppDepartmentSubByTypeReducer(state = {
    DeptSubMstByTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DEPTSUBMSTBYTYPE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DEPTSUBMSTBYTYPE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DEPTSUBMSTBYTYPE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DeptSubMstByTypeData: action.payload
            }
        }
        default: return state;
    }

}

function AppPrintGroupReducer(state = {
    PrintGroupData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PRNGRP_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PRNGRP_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PRNGRP_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PrintGroupData: action.payload
            }
        }
        default: return state;
    }

}

function AppServiceCategoryReducer(state = {
    ServiceCategoryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERCAT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERCAT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERCAT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceCategoryData: action.payload
            }
        }
        default: return state;
    }

}

function AppServiceTariffReducer(state = {
    ServiceTariffData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERTAR_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERTAR_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERTAR_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceTariffData: action.payload
            }
        }
        default: return state;
    }

}


function AppServiceTariffChargeReducer(state = {
    ServiceTariffCharge: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERTARDTL_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERTARDTL_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERTARDTL_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceTariffCharge: action.payload
            }
        }
        default: return state;
    }

}

function AppConsultationTariffChargeReducer(state = {
    ConsultationTariffCharge: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CONSTAR_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CONSTAR_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CONSTAR_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ConsultationTariffCharge: action.payload
            }
        }
        default: return state;
    }

}
function AppServiceMstReducer(state = {
    ServiceMstData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERVMST_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERVMST_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERVMST_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceMstData: action.payload
            }
        }
        default: return state;
    }

}
function AppServiceMstByServiceTypeReducer(state = {
    ServiceMstByServiceTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERVMST_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERVMST_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERVMST_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceMstByServiceTypeData: action.payload
            }
        }
        default: return state;
    }

}
function AppBEDCATMstReducer(state = {
    BEDCATMstData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BEDCAT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BEDCAT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BEDCAT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BEDCATMstData: action.payload
            }
        }
        default: return state;
    }
}
function AppBEDCATByEncReducer(state = {
    BEDCATByEncData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BEDCATENC_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BEDCATENC_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BEDCATENC_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BEDCATByEncData: action.payload
            }
        }
        default: return state;
    }
}

function AppERBEDCATMstReducer(state = {
    ERBEDCATMstData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ERBEDCAT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ERBEDCAT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ERBEDCAT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ERBEDCATMstData: action.payload
            }
        }
        default: return state;
    }

}

function AppServiceByFacilityReducer(state = {
    ServiceByFacilityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERBYFAC_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERBYFAC_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERBYFAC_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceByFacilityData: action.payload
            }
        }
        default: return state;
    }

}
function AppPackageByFacilityReducer(state = {
    PackageByFacilityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PACBYFAC_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PACBYFAC_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PACBYFAC_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PackageByFacilityData: action.payload
            }
        }
        default: return state;
    }

}
function AppSUBServiceByFacilityReducer(state = {
    SUBServiceByFacilityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SUBSERBYFAC_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SUBSERBYFAC_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SUBSERBYFAC_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SUBServiceByFacilityData: action.payload
            }
        }
        default: return state;
    }

}

function AppSUBServiceByFacilityTNIReducer(state = {
    SUBServiceByFacilityTNIData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SUBSERBYFACTNI_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SUBSERBYFACTNI_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SUBSERBYFACTNI_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SUBServiceByFacilityTNIData: action.payload
            }
        }
        default: return state;
    }

}

function AppServiceLinkedReducer(state = {
    ServiceLinkedData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERLINK_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERLINK_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERLINK_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceLinkedData: action.payload
            }
        }
        default: return state;
    }
}

function AppSpecializationReducer(state = {
    SpecializationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SPECIALITY_LIST_REQUEST: { return { ...state, fetched: false, fetching: true, error: null } }
        case AMCnst.SPECIALITY_LIST_FAIL: { return { ...state, fetching: false, fetched: true, SpecializationData: intialstate, error: action.payload, fetched: true } }
        case AMCnst.SPECIALITY_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SpecializationData: action.payload
            }
        }
        default: return state;
    }
}


function AppServiceFacilityReducer(state = {
    ServiceFacilityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERFAC_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERFAC_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERFAC_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceFacilityData: action.payload
            }
        }
        default: return state;
    }
}



function saveServiceMstReducer(state = {
    ServiceSavedData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERVMST_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERVMST_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERVMST_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceSavedData: action.payload
            }
        }
        default: return state;
    }

}

function saveCouponDtlReducer(state = {
    CouponSavedData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CUPNDTL_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CUPNDTL_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CUPNDTL_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CouponSavedData: action.payload
            }
        }
        default: return state;
    }

}

function AppExclusionMainReducer(state = {
    ExclusionMainData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ExclusionMain_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ExclusionMain_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ExclusionMain_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ExclusionMainData: action.payload
            }
        }
        default: return state;
    }

}
function AppExclusionDetailReducer(state = {
    ExclusionDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ExclusionDetail_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ExclusionDetail_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ExclusionDetail_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ExclusionDetailData: action.payload
            }
        }
        default: return state;
    }

}
function AppUserGroupReducer(state = {
    UserGroupData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.UserGroup_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.UserGroup_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.UserGroup_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                UserGroupData: action.payload
            }
        }
        default: return state;
    }

}
function AppUserSubGroupReducer(state = {
    UserSubGroupData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.UserSubGroup_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.UserSubGroup_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.UserSubGroup_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                UserSubGroupData: action.payload
            }
        }
        default: return state;
    }

}
function AppDesignationReducer(state = {
    DesignationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Designation_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Designation_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Designation_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DesignationData: action.payload
            }
        }
        default: return state;
    }

}
function AppGradeReducer(state = {
    GradeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Grade_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Grade_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Grade_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GradeData: action.payload
            }
        }
        default: return state;
    }

}
function AppOPPackageSaveReducer(state = {
    OPPackageSaveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.OPPackage_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.OPPackage_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.OPPackage_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OPPackageSaveData: action.payload
            }
        }
        default: return state;
    }

}
function AppOPPackageGetReducer(state = {
    OPPackageGetData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.OPPackage_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.OPPackage_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.OPPackage_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OPPackageGetData: action.payload
            }
        }
        default: return state;
    }

}


function AppServiceBedCategoryReducer(state = {
    ServiceBedCategory: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BEDCATSER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BEDCATSER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BEDCATSER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceBedCategory: action.payload
            }
        }
        default: return state;
    }

}


function AppServiceTariffFactorReducer(state = {
    ServiceTariffFactor: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BEDCATFAC_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BEDCATFAC_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BEDCATFAC_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceTariffFactor: action.payload
            }
        }
        default: return state;
    }

}


function AppServiceTariffFactorDtlReducer(state = {
    ServiceTariffFactorDtl: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BEDCATFACDTL_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BEDCATFACDTL_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BEDCATFACDTL_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceTariffFactorDtl: action.payload
            }
        }
        default: return state;
    }

}
//-------------------------------------------------------------------------------------------------------Shashi
function BankListReducer(state = {
    BankListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BANK_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BANK_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BANK_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BankListData: action.payload
            }
        }
        default: return state;
    }

}


// ujjwal work start
function AppBedTypeReducer(state = {
    BedTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BedType_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BedType_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BedType_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BedTypeData: action.payload
            }
        }
        default: return state;
    }

}
function AppModuleReducer(state = {
    ModuleData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Module_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Module_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Module_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ModuleData: action.payload
            }
        }
        default: return state;
    }

}
function AppPagePermissionMaster(state = {
    PagePermissionMaster: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PagePermission_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PagePermission_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PagePermission_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PagePermissionMaster: action.payload
            }
        }
        default: return state;
    }

}
function AppPagePermissionMasterEmployee(state = {
    PagePermissionMasterEmployee: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EmployeePagePermission_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EmployeePagePermission_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EmployeePagePermission_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PagePermissionMasterEmployee: action.payload
            }
        }
        default: return state;
    }

}
function AppFacilitywiseRoleMaster(state = {
    FacilitywiseRole: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FacilitywiseRole_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FacilitywiseRole_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FacilitywiseRole_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FacilitywiseRole: action.payload
            }
        }
        default: return state;
    }

}
function AppFacilitywiseEmployeeMaster(state = {
    FacilitywiseEmployee: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FacilitywiseEmployee_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FacilitywiseEmployee_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FacilitywiseEmployee_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FacilitywiseEmployee: action.payload
            }
        }
        default: return state;
    }

}
function AppsaveRolePermissionsReducer(state = {
    saveRolePermissions: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.saveRolePermissions_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.saveRolePermissions_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.saveRolePermissions_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveRolePermissions: action.payload
            }
        }
        default: return state;
    }

}
function AppsaveEmployeePermissions(state = {
    saveEmployeePermissions: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.saveEmployeePermissions_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.saveEmployeePermissions_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.saveEmployeePermissions_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveEmployeePermissions: action.payload
            }
        }
        default: return state;
    }

}

function AppsaveCopyRolePermissions(state = {
    saveCopyRolePermissions: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.saveCopyRolePermissions_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.saveCopyRolePermissions_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.saveCopyRolePermissions_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveCopyRolePermissions: action.payload
            }
        }
        default: return state;
    }

}
function AppPageFuncionPermission(state = {
    PageFuncionPermission: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PageFunctionPermission_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PageFunctionPermission_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PageFunctionPermission_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PageFuncionPermission: action.payload
            }
        }
        default: return state;
    }

}
function AppPageFuncionPermissionEmployee(state = {
    PageFuncionPermissionEmployee: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EmployeePageFunctionPermission_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EmployeePageFunctionPermission_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EmployeePageFunctionPermission_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PageFuncionPermissionEmployee: action.payload
            }
        }
        default: return state;
    }

}
function AppFormReducer(state = {
    FormData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Form_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Form_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Form_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FormData: action.payload
            }
        }
        default: return state;
    }

}
function AppSectionReducer(state = {
    SectionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Section_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Section_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Section_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SectionData: action.payload
            }
        }
        default: return state;
    }

}
// ujjwal work end
//#region  Rajesh Yati
function AppAgeGroupReducer(state = {
    AgeGroupData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AgeGroup_LIST_REQUEST: { return { ...state, fetched: false, fetching: true, error: null } }
        case AMCnst.AgeGroup_LIST_FAIL: { return { ...state, fetching: false, fetched: true, AgeGroupData: intialstate, error: action.payload, fetched: true } }
        case AMCnst.AgeGroup_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AgeGroupData: action.payload
            }
        }
        default: return state;
    }

}
function AppSaveAgeGroupReducer(state = {
    SaveAgeGroupData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AgeGroup_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AgeGroup_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AgeGroup_SAVE_SUCCESS: {

            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveAgeGroupData: action.payload
            }
        }
        default: return state;
    }

}
function AppRoomMasterReducer(state = {
    RoomMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RoomMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RoomMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RoomMaster_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                RoomMasterData: action.payload
            }
        }
        default: return state;
    }

}
function SaveRoomMasterReducer(state = {
    SaveRoomMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RoomMaster_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RoomMaster_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RoomMaster_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveRoomMasterData: action.payload
            }
        }
        default: return state;
    }

}
function AppBlockReducer(state = {
    BlockData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Block_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Block_LIST_FAIL: { return { ...state, fetching: false, fetched: true, BlockData: intialstate, error: action.payload, fetched: true } }
        case AMCnst.Block_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BlockData: action.payload
            }
        }
        default: return state;
    }

}
function AppBuildingReducer(state = {
    BuildingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Building_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Building_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true, BuildingData: intialstate } }
        case AMCnst.Building_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BuildingData: action.payload
            }
        }
        default: return state;
    }

}
function SaveBlockReducer(state = {
    SaveBlockData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Block_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Block_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Block_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveBlockData: action.payload
            }
        }
        default: return state;
    }

}
function AppAdmissionTypeReducer(state = {
    AdmissionTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AdmissionType_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AdmissionType_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AdmissionType_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AdmissionTypeData: action.payload
            }
        }
        default: return state;
    }

}
function AppCaseTypeReducer(state = {
    CaseTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CaseType_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CaseType_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CaseType_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CaseTypeData: action.payload
            }
        }
        default: return state;
    }

}

function AppCaseSubTypeReducer(state = {
    CaseSubtypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CaseSubType_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CaseSubType_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CaseSubType_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CaseSubtypeData: action.payload
            }
        }
        default: return state;
    }

}


function AppSAPItemPurchageGroupMasterReducer(state = {
    SAPItemPurchageGroupMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SAPItemPurchageGroupMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SAPItemPurchageGroupMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SAPItemPurchageGroupMaster_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SAPItemPurchageGroupMasterData: action.payload
            }
        }
        default: return state;
    }

}

function AppItemSapIntegrationReducer(state = {
    ItemSapIntegrationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ItemSapIntegration_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ItemSapIntegration_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ItemSapIntegration_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ItemSapIntegrationData: action.payload
            }
        }
        default: return state;
    }

}


function AppFloorReducer(state = {
    FloorData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Floor_LIST_REQUEST: { return { ...state, fetched: false, fetching: true, error: null } }
        case AMCnst.Floor_LIST_FAIL: { return { ...state, fetching: false, fetched: true, FloorData: intialstate, error: action.payload, fetched: true } }
        case AMCnst.Floor_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FloorData: action.payload
            }
        }
        default: return state;
    }

}
function SaveFloorReducer(state = {
    SaveFloorData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Floor_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Floor_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Floor_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveFloorData: action.payload
            }
        }
        default: return state;
    }

}
function AppNursingUnitReducer(state = {
    NursingUnitData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.NursingUnit_MarkArvl_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.NursingUnit_MarkArvl_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.NursingUnit_MarkArvl_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                NursingUnitData: action.payload
            }
        }
        default: return state;
    }

}
function SaveNursingUnitReducer(state = {
    SaveNursingUnitData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.NursingUnit_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.NursingUnit_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.NursingUnit_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveNursingUnitData: action.payload
            }
        }
        default: return state;
    }

}
function FromAppNursingUnitReducer(state = {
    FromNursingUnitData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.NursingUnit_Transfer_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.NursingUnit_Transfer_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.NursingUnit_Transfer_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FromNursingUnitData: action.payload
            }
        }
        default: return state;
    }

}
function ToAppNursingUnitReducer(state = {
    ToNursingUnitData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.NursingUnit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.NursingUnit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.NursingUnit_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ToNursingUnitData: action.payload
            }
        }
        default: return state;
    }

}
function AppBedMasterReducer(state = {
    BedMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Bed_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Bed_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true } }
        case AMCnst.Bed_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                error: null,
                BedMasterData: action.payload
            }
        }
        default: return state;
    }
}
function getBedListReducer(state = {
    getBedListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.getBedList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.getBedList_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true, getBedListData: intialstate } }
        case AMCnst.getBedList_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                error: null,
                getBedListData: action.payload
            }
        }
        default: return state;
    }
}
function AppBedMasterByStatusTypeReducer(state = {
    BedMasterByStatusTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ERBed_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ERBed_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ERBed_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BedMasterByStatusTypeData: action.payload
            }
        }
        default: return state;
    }

}
function SaveBedMasterReducer(state = {
    SaveBedMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Bed_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Bed_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Bed_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveBedMasterData: action.payload
            }
        }
        default: return state;
    }

}
function FromAppBedMasterReducer(state = {
    FromBedMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FromBed_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FromBed_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FromBed_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FromBedMasterData: action.payload
            }
        }
        default: return state;
    }

}
function ToAppBedMasterReducer(state = {
    ToBedMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ToBed_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ToBed_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ToBed_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ToBedMasterData: action.payload
            }
        }
        default: return state;
    }

}
function AppAnesthesiaTypeReducer(state = {
    AnesthesiaTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AnesthesiaType_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AnesthesiaType_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AnesthesiaType_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AnesthesiaTypeData: action.payload
            }
        }
        default: return state;
    }

}
function SaveAnesthesiaTypeReducer(state = {
    SaveAnesthesiaTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AnesthesiaType_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AnesthesiaType_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AnesthesiaType_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveAnesthesiaTypeData: action.payload
            }
        }
        default: return state;
    }

}
function saveDiscountTemplateReducer(state = {
    DiscTempSavedData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DISCTEM_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DISCTEM_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DISCTEM_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DiscTempSavedData: action.payload
            }
        }
        default: return state;
    }

}

function AppCouponTemplateReducer(state = {
    CouponTempData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COUPTEM_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COUPTEM_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COUPTEM_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CouponTempData: action.payload
            }
        }
        default: return state;
    }

}

function AppDiscountTempConfigReducer(state = {
    DiscTempConfigData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DISCTEMCON_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DISCTEMCON_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DISCTEMCON_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DiscTempConfigData: action.payload
            }
        }
        default: return state;
    }

}
function AppfetchClinic(state = {
    ClinicData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Clinic_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Clinic_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true, ClinicData: intialstate } }
        case AMCnst.Clinic_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ClinicData: action.payload
            }
        }
        default: return state;
    }
}
function AppfetchPaymentMode(state = {
    PaymentModeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PAYMENTMODE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PAYMENTMODE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PAYMENTMODE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PaymentModeData: action.payload
            }
        }
        default: return state;
    }

}
function AppRemarksMasterReducer(state = {
    RemarksMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RemarksMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RemarksMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RemarksMaster_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                RemarksMasterData: action.payload
            }
        }
        default: return state;
    }

}
function GetCompanyFacilityWiseReducer(state = {
    CompanyFacilityWiseData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPANY_FACILITY_WISE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPANY_FACILITY_WISE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPANY_FACILITY_WISE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompanyFacilityWiseData: action.payload
            }
        }
        default: return state;
    }

}
function AppBedOccupiReducer(state = {
    BedOccupiData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BedOCCUP_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BedOCCUP_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BedOCCUP_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BedOccupiData: action.payload
            }
        }
        default: return state;
    }

}
function AppGetMOUDetailsListReducer(state = {
    MOUDetailList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.MOUDetails_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.MOUDetails_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.MOUDetails_List_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                MOUDetailList: action.payload
            }
        }
        default: return state;
    }

}

function AppGetCompanyItemExclusionListReducer(state = {
    ItemExclusionList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ItemExclusion_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ItemExclusion_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ItemExclusion_List_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ItemExclusionList: action.payload
            }
        }
        default: return state;
    }

}
function GroupExclusion_SUCCESSReducer(state = {
    GroupExclusionList1: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GroupExclusion_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GroupExclusion_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GroupExclusion_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GroupExclusionList1: action.payload
            }
        }
        default: return state;
    }

}
function SaveCityMstReducer(state = {
    SaveCityMstData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CITY_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CITY_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CITY_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveCityMstData: action.payload
            }
        }
        default: return state;
    }

}
function SaveStateMstReducer(state = {
    SaveStateMstData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.STATE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.STATE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.STATE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveStateMstData: action.payload
            }
        }
        default: return state;
    }

}
function SaveCountryMstReducer(state = {
    SaveCountryMstData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COUNTRY_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COUNTRY_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COUNTRY_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveCountryMstData: action.payload
            }
        }
        default: return state;
    }

}
function SaveRefTypeMstReducer(state = {
    SaveRefTypeMstData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.REFERRALTYPE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.REFERRALTYPE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.REFERRALTYPE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveRefTypeMstData: action.payload
            }
        }
        default: return state;
    }

}


function AppGetGroupExclusionListReducer(state = {
    GroupExclusionList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GroupExclusion_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GroupExclusion_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GroupExclusion_List_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GroupExclusionList: action.payload
            }
        }
        default: return state;
    }

}
function AppGetNonDiscountItemListReducer(state = {
    NonDiscountItemList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.NONDISCOUNT_ITEM_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.NONDISCOUNT_ITEM_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.NONDISCOUNT_ITEM_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                NonDiscountItemList: action.payload
            }
        }
        default: return state;
    }

}
function AppGetNonDiscountGroupListReducer(state = {
    NonDiscountGroupList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.NONDISCOUNT_GROUP_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.NONDISCOUNT_GROUP_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.NONDISCOUNT_GROUP_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                NonDiscountGroupList: action.payload
            }
        }
        default: return state;
    }

}


function AppSaveDepartmentReducer(state = {
    SaveDeptStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DEPTMST_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DEPTMST_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DEPTMST_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveDeptStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppSaveDepartmentSubReducer(state = {
    SaveDeptSubStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DEPTSUBMST_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DEPTSUBMST_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DEPTSUBMST_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveDeptSubStatus: action.payload
            }
        }
        default: return state;
    }

}

function AppSaveCompanExclusionListReducer(state = {
    CompanyExclusionSaveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ExclusionDetail_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ExclusionDetail_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ExclusionDetail_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompanyExclusionSaveData: action.payload
            }
        }
        default: return state;
    }

}
function AppEmploymentTypeReducer(state = {
    EmploymentTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EmploymentType_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EmploymentType_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EmploymentType_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmploymentTypeData: action.payload
            }
        }
        default: return state;
    }

}


// function AppPaymentModeReducer(state = {
//     PaymentModeData: intialstate,
//     fetching: false,
//     fetched: false,
//     error: null
// }, action) {
//     switch (action.type) {
//         case AMCnst.PAYMENTMODE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
//         case AMCnst.PAYMENTMODE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
//         case AMCnst.PAYMENTMODE_LIST_SUCCESS: {
//             return {
//                 ...state,
//                 fetching: false,
//                 fetched: true,
//                 PaymentModeData: action.payload
//             }
//         }
//         default: return state;
//     }

// }

function AppSaveRegistrationTypeReducer(state = {
    RegistrationTypeSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.REGISTRATIONTYPE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.REGISTRATIONTYPE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.REGISTRATIONTYPE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                RegistrationTypeSave: action.payload
            }
        }
        default: return state;
    }

}
function AppSaveReligionReducer(state = {
    ReligionDataSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RELIGION_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RELIGION_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RELIGION_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReligionDataSave: action.payload
            }
        }
        default: return state;
    }

}

function AppsaveEducationReducer(state = {
    EducationSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EDUCATION_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EDUCATION_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EDUCATION_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EducationSaveStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppsaveHWCTypeReducer(state = {
    HWCTypeSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HWCTYPE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HWCTYPE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HWCTYPE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HWCTypeSaveStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppsaveLanguageReducer(state = {
    LanguageSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.LANGUAGE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.LANGUAGE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.LANGUAGE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                LanguageSaveStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppsaveOccupationReducer(state = {
    OccupationSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.OCCUPATION_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.OCCUPATION_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.OCCUPATION_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OccupationSaveStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppsaveReferralReducer(state = {
    ReferralSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.REFERRAL_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.REFERRAL_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.REFERRAL_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReferralSaveStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppsaveAreaReducer(state = {
    AreaSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AREA_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AREA_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AREA_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AreaSaveStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppsaveDocumentTypeReducer(state = {
    DocumentTypeSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DOCUMENTTYPE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DOCUMENTTYPE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DOCUMENTTYPE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DocumentTypeSaveStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppsaveNationalityReducer(state = {
    NationalitySaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.NATIONALITY_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.NATIONALITY_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.NATIONALITY_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                NationalitySaveStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppsaveRelationReducer(state = {
    RelationSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RELATIONSHIP_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RELATIONSHIP_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RELATIONSHIP_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                RelationSaveStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppsaveTitleReducer(state = {
    TitleSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.TITLE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.TITLE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.TITLE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                TitleSaveStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppsaveEmploymentTypeReducer(state = {
    EmploymentTypeSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EmploymentType_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EmploymentType_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EmploymentType_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmploymentTypeSaveStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppGetRolePageSectionMaster(state = {
    RolePageSectionMaster: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RolePageSection_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RolePageSection_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RolePageSection_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                RolePageSectionMaster: action.payload
            }
        }
        default: return state;
    }

}
function AppGetRolePageMaster(state = {
    RolePageMaster: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RolePage_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RolePage_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RolePage_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                RolePageMaster: action.payload
            }
        }
        default: return state;
    }

}
function RolePermissionMasterReducer(state = {
    RolePermissionMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RolePermissionMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RolePermissionMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RolePermissionMaster_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                RolePermissionMasterData: action.payload
            }
        }
        default: return state;
    }

}

function AccessControlRoleReportReducer(state = {
    AccessControlRoleReportData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AccessControlRoleReport_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AccessControlRoleReport_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AccessControlRoleReport_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AccessControlRoleReportData: action.payload
            }
        }
        default: return state;
    }

}

function AppGetEmployeePageSectionMaster(state = {
    EmployeePageSectionMaster: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EmployeePageSection_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EmployeePageSection_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EmployeePageSection_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmployeePageSectionMaster: action.payload
            }
        }
        default: return state;
    }

}
function AppGetEmployeePageMaster(state = {
    EmployeePageMaster: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EmployeePage_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EmployeePage_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EmployeePage_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmployeePageMaster: action.payload
            }
        }
        default: return state;
    }

}
function AppGetClinicForEmployee(state = {
    ClinicForEmployeeMaster: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ClinicForEmployee_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ClinicForEmployee_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ClinicForEmployee_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ClinicForEmployeeMaster: action.payload
            }
        }
        default: return state;
    }

}
function AppGetDoctorVisitMaster(state = {
    DoctorVisitMaster: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DoctorVisitMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DoctorVisitMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DoctorVisitMaster_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DoctorVisitMaster: action.payload
            }
        }
        default: return state;
    }

}
function AppGetEmployeeFacility(state = {
    EmployeeFacility: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EmployeeFacility_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EmployeeFacility_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EmployeeFacility_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmployeeFacility: action.payload
            }
        }
        default: return state;
    }

}

function AppEquipmentListReducer(state = {
    EquipmentListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EQUIPMENT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EQUIPMENT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EQUIPMENT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EquipmentListData: action.payload
            }
        }
        default: return state;
    }
}

function AppsaveEquipmentListReducer(state = {
    EquipmentListSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EQUIPMENTLIST_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EQUIPMENTLIST_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EQUIPMENTLIST_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EquipmentListSaveStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppCompanyAllReducer(state = {
    CompanyAllData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PAYOR_ALL_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PAYOR_ALL_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PAYOR_ALL_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompanyAllData: action.payload
            }
        }
        default: return state;
    }

}
function fetchReasonListReducer(state = {
    fetchReasonListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.REASON_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.REASON_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.REASON_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                fetchReasonListData: action.payload
            }
        }
        default: return state;
    }

}
function fetchApprovalMatrixListReducer(state = {
    fetchApprovalMatrixListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.APPROVALMATRIX_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.APPROVALMATRIX_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.APPROVALMATRIX_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                fetchApprovalMatrixListData: action.payload
            }
        }
        default: return state;
    }

}
//#endregion

//Added By Ritesh
function fetchBankMasterListReducer(state = {
    fetchBankMasterListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BANK_MASTER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BANK_MASTER_REASON_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BANK_MASTER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                fetchBankMasterListData: action.payload
            }
        }
        default: return state;
    }

}

function BankMasterSaveReducer(state = {
    BankMasterSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BANK_MASTER_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BANK_MASTER_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BANK_MASTER_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BankMasterSave: action.payload
            }
        }
        default: return state;
    }

}
function ModulePageMenuListReducer(state = {
    ModulePageMenuList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GET_MODULEPAGE_MENU_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GET_MODULEPAGE_MENU_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GET_MODULEPAGE_MENU_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ModulePageMenuList: action.payload
            }
        }
        default: return state;
    }

}


function AppCardMasterListReducer(state =
    {
        fetchAllCardMaster: intialstate,
        fetching: false,
        fetched: false,
        error: null

    }, action) {
    switch (action.type) {
        case AMCnst.CARD_MASTER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CARD_MASTER_REASON_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CARD_MASTER_LIST_SUCCESS: { return { ...state, fetching: false, fetched: true, fetchAllCardMaster: action.payload } }
        default: return state;

    }
}

function AppCardMasterSave(state = {
    CardMasterSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CARD_MASTER_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CARD_MASTER_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CARD_MASTER_SAVE_SUCCESS: {
            return { ...state, fetching: false, fetched: true, CardMasterSave: action.payload }
        }
        default: return state;
    }
}


//End

function AppDesinationReducer(state = {
    DesinationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Desination_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Desination_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Desination_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DesinationData: action.payload
            }
        }
        default: return state;
    }

}
function SaveApprovalMatrixReducer(state = {
    SaveApprovalMatrixData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.APPROVALMATRIX_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.APPROVALMATRIX_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.APPROVALMATRIX_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveApprovalMatrixData: action.payload
            }
        }
        default: return state;
    }

}
function GetEmployeeValidateLoginReducer(state = {
    ValidateLogin: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GET_USERLOGIN_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GET_USERLOGIN_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GET_USERLOGIN_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ValidateLogin: action.payload
            }
        }
        default: return state;
    }

}

function ModulePageButtonListReducer(state = {
    ModulePageButtonList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GET_MODULEPAGE_BUTTON_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GET_MODULEPAGE_BUTTON_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GET_MODULEPAGE_BUTTON_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ModulePageButtonList: action.payload
            }
        }
        default: return state;
    }

}
function AppPatientSeriesReducer(state = {
    PatientSeriesData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientSeries_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientSeries_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PatientSeries_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PatientSeriesData: action.payload
            }
        }
        default: return state;
    }

}
function SavePatientSeriesReducer(state = {
    SavePatientSeriesData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientSeries_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientSeries_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PatientSeries_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SavePatientSeriesData: action.payload
            }
        }
        default: return state;
    }

}
function saveCompanyServiceExclReducer(state = {
    saveCompanyServiceExclStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPSEREXL_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPSEREXL_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPSEREXL_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveCompanyServiceExclStatus: action.payload
            }
        }
        default: return state;
    }

}

function SaveDesigationMstReducer(state = {
    DesigationMstReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Designation_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Designation_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Designation_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DesigationMstReducer: action.payload
            }
        }
        default: return state;
    }

}

function SaveGradeMstReducer(state = {
    SaveGradeMstReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Grade_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Grade_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Grade_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveGradeMstReducer: action.payload
            }
        }
        default: return state;
    }

}

function SaveUserGropMstReducer(state = {
    SaveUserGropMstReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.UserGroup_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.UserGroup_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.UserGroup_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveUserGropMstReducer: action.payload
            }
        }
        default: return state;
    }

}
function SaveUserSubGropMstReducer(state = {
    SaveUserSubGropMstReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.UserSubGroup_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.UserSubGroup_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.UserSubGroup_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveUserSubGropMstReducer: action.payload
            }
        }
        default: return state;
    }

}

function GetAuditDataReducer(state = {
    GetAuditData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AUDIT_MASTER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AUDIT_MASTER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AUDIT_MASTER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetAuditData: action.payload
            }
        }
        default: return state;
    }

}
function AppEnterpriseReducer(state = {
    EnterpriseData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Enterprise_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Enterprise_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Enterprise_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EnterpriseData: action.payload
            }
        }
        default: return state;
    }

}
function SaveModulePagePinnedReducer(state = {
    ModulePagePinned: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ModulePagePinned_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ModulePagePinned_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ModulePagePinned_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ModulePagePinned: action.payload
            }
        }
        default: return state;
    }

}
function BulkRegistrationReducer(state = {
    BulkRegistrationSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BulkRegistration_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BulkRegistration_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BulkRegistration_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BulkRegistrationSave: action.payload
            }
        }
        default: return state;
    }

}
function AppEmployeeByTeamReducer(state = {
    EmployeeByTeamData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EmployeeByTeam_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EmployeeByTeam_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EmployeeByTeam_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmployeeByTeamData: action.payload
            }
        }
        default: return state;
    }

}
function GetFieldModificationPermissionReducer(state = {
    FieldPermissionList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FIELDPERMISSION_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FIELDPERMISSION_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FIELDPERMISSION_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FieldPermissionList: action.payload
            }
        }
        default: return state;
    }
}

function WeeklyOffListReducer(state = {
    WeeklyOffListReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WEEKLYOFF_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WEEKLYOFF_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.WEEKLYOFF_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                WeeklyOffListReducer: action.payload
            }
        }
        default: return state;
    }
}


function HolidayListReducer(state = {
    HolidayListReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HOLIDAYLIST_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HOLIDAYLIST_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HOLIDAYLIST_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HolidayListReducer: action.payload
            }
        }
        default: return state;
    }
}
function HolidaySaveReducer(state = {
    HolidaySaveReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HOLIDAY_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HOLIDAY_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HOLIDAY_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HolidaySaveReducer: action.payload
            }
        }
        default: return state;
    }
}

function WeeklyOffSaveReducer(state = {
    WeeklyOffSaveReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WEEKLYOFF_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WEEKLYOFF_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.WEEKLYOFF_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                WeeklyOffSaveReducer: action.payload
            }
        }
        default: return state;
    }
}

function HolidayMarkupReducer(state = {
    HolidayMarkupReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HOLIDAYMARKUP_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HOLIDAYMARKUP_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HOLIDAYMARKUP_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HolidayMarkupReducer: action.payload
            }
        }
        default: return state;
    }
}
function HolidayMarkupSaveReducer(state = {
    HolidayMarkupSaveReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HOLIDAYMARKUP_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HOLIDAYMARKUP_SAVE__FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HOLIDAYMARKUP_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                HolidayMarkupSaveReducer: action.payload
            }
        }
        default: return state;
    }
}
function getSurgeryComponentReducer(state = {
    SurgeryComponentList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SURGERYCOMPONENT_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SURGERYCOMPONENT_SAVE__FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SURGERYCOMPONENT_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SurgeryComponentList: action.payload
            }
        }
        default: return state;
    }
}
function getMappedSurgeryComponentListReducer(state = {
    MappedSurgeryComponentList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.MappedSurgeryComponent_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.MappedSurgeryComponent_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.MappedSurgeryComponent_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                MappedSurgeryComponentList: action.payload
            }
        }
        default: return state;
    }
}

function getMappedIPPackageComponentListReducer(state = {
    MappedIPPAckageComponentList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.MappedIPPackageComponent_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.MappedIPPackageComponent_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.MappedIPPackageComponent_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                MappedIPPAckageComponentList: action.payload
            }
        }
        default: return state;
    }
}

function saveIpPackageReducer(state = {
    IpPackageSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPACKAGE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPACKAGE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPACKAGE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IpPackageSave: action.payload
            }
        }
        default: return state;
    }
}

function saveIPPackageServiceInclusionReducer(state = {
    IpPckgServiceInclSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPACKAGE_SERVICEINCLUSION_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPACKAGE_SERVICEINCLUSION_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPACKAGE_SERVICEINCLUSION_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IpPckgServiceInclSave: action.payload
            }
        }
        default: return state;
    }
}
function saveIPPackageItemInclusionReducer(state = {
    IpPckgItemInclSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPACKAGE_ITEMINCLUSION_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPACKAGE_ITEMINCLUSION_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPACKAGE_ITEMINCLUSION_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IpPckgItemInclSave: action.payload
            }
        }
        default: return state;
    }
}
function saveIPPackageExclusionsReducer(state = {
    IpPckgExclSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPACKAGE_EXCLUSIONS_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPACKAGE_EXCLUSIONS_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPACKAGE_EXCLUSIONS_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IpPckgExclSave: action.payload
            }
        }
        default: return state;
    }
}
function saveIPPackageBedChargeReducer(state = {
    IpPckgBedChargeSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPACKAGE_BEDCHARGE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPACKAGE_BEDCHARGE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPACKAGE_BEDCHARGE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IpPckgBedChargeSave: action.payload
            }
        }
        default: return state;
    }
}
function saveIPPackageSurgeryComponentReducer(state = {
    IpPckgSurgeryCompSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPACKAGE_SURGERYCOMP_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPACKAGE_SURGERYCOMP_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPACKAGE_SURGERYCOMP_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IpPckgSurgeryCompSave: action.payload
            }
        }
        default: return state;
    }
}
function saveIPPackagePayorReducer(state = {
    IpPckgPayorSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPACKAGE_PAYOR_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPACKAGE_PAYOR_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPACKAGE_PAYOR_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IpPckgPayorSave: action.payload
            }
        }
        default: return state;
    }
}
function GetIpPackageListReducer(state = {
    IpPackageList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPACKAGE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPACKAGE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPACKAGE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IpPackageList: action.payload
            }
        }
        default: return state;
    }
}
function GetIpPackageAllListReducer(state = {
    IpPackageAllList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPACKAGE_ALL_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPACKAGE_ALL_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPACKAGE_ALL_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IpPackageAllList: action.payload
            }
        }
        default: return state;
    }
}
function getIpPackageBedChargeLimitReducer(state = {
    IpPackageBedChargeList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPACKAGE_GETBEDCHARGE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPACKAGE_GETBEDCHARGE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPACKAGE_GETBEDCHARGE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IpPackageBedChargeList: action.payload
            }
        }
        default: return state;
    }
}

function AppsaveClinicReducer(state = {
    ClinicDataSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CLINIC_SAVE_REQUEST: { return { ...state, fetched: false, fetching: true, error: null } }
        case AMCnst.CLINIC_SAVE_FAIL: { return { ...state, fetching: false, fetched: true, ClinicDataSave: intialstate, error: action.payload, fetched: true } }
        case AMCnst.CLINIC_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ClinicDataSave: action.payload
            }
        }
        default: return state;
    }
}
function getIpPackageSurgeryCompDataReducer(state = {
    surgeryCompList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPACKAGE_GETSURGERY_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPACKAGE_GETSURGERY_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPACKAGE_GETSURGERY_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                surgeryCompList: action.payload
            }
        }
        default: return state;
    }
}
function saveCopyFromExistingPackageReducer(state = {
    copyIpPackageSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPACKAGE_SAVECOPYFROM_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPACKAGE_SAVECOPYFROM_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPACKAGE_SAVECOPYFROM_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                copyIpPackageSave: action.payload
            }
        }
        default: return state;
    }
}
function SaveHCFReducer(state = {
    SaveHCFData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.HCF_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.HCF_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.HCF_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveHCFData: action.payload
            }
        }
        default: return state;
    }

}

function SavePayorCategoryReducer(state = {
    SavePayorCategoryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PAYORCAT_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PAYORCAT_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PAYORCAT_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SavePayorCategoryData: action.payload
            }
        }
        default: return state;
    }
}

function DispositionReducer(state = {
    DispositionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Disposition_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Disposition_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Disposition_List_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DispositionData: action.payload
            }
        }
        default: return state;
    }
}

function CurrEXRateReducer(state = {
    CurrEXRateData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CurrEXRate_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CurrEXRate_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CurrEXRate_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CurrEXRateData: action.payload
            }
        }
        default: return state;
    }
}

function savePayorPlanReducer(state = {
    SavePayorPlan: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PAYORPLAN_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PAYORPLAN_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PAYORPLAN_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SavePayorPlan: action.payload
            }
        }
        default: return state;
    }
}

function getSurgeryCompSvcMappingDataReducer(state = {
    getSurgeryCompSvcMappList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SurgeryCompSvcMapp_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SurgeryCompSvcMapp_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SurgeryCompSvcMapp_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getSurgeryCompSvcMappList: action.payload
            }
        }
        default: return state;
    }
}
function saveSurgeryCompSvcMappingDataReducer(state = {
    saveSurgeryCompSvcMappStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SurgeryCompSvcMapp_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SurgeryCompSvcMapp_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SurgeryCompSvcMapp_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveSurgeryCompSvcMappStatus: action.payload
            }
        }
        default: return state;
    }
}
function fetchSurgeryComponent(state = {
    SurgeryComponent: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SurgeryComponent_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SurgeryComponent_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SurgeryComponent_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SurgeryComponent: action.payload
            }
        }
        default: return state;
    }
}

function SaveSurgeryComponent(state = {
    SaveSurgeryComponent: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SurgeryComponent_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SurgeryComponent_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SurgeryComponent_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveSurgeryComponent: action.payload
            }
        }
        default: return state;
    }
}

function getSurgeryCompTatiffMappingReducer(state = {
    getSurgeryCompTatiffMappingReducerData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SurgeryCompTariffMapp_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SurgeryCompTariffMapp_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SurgeryCompTariffMapp_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getSurgeryCompTatiffMappingReducerData: action.payload
            }
        }
        default: return state;
    }
}
function saveSurgeryCompTariffMappingReducer(state = {
    SaveSurgeryCompTariffMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SurgeryCompTariffMapp_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SurgeryCompTariffMapp_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SurgeryCompTariffMapp_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveSurgeryCompTariffMappingData: action.payload
            }
        }
        default: return state;
    }

}

function SaveServiceBedCategoryFactorReducer(state = {
    SaveServiceBedCategoryStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BEDCATFAC_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BEDCATFAC_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BEDCATFAC_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveServiceBedCategoryStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppItemDiscountTempConfigReducer(state = {
    ItemDiscTempConfigData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ITEMDISCTEMCON_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ITEMDISCTEMCON_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ITEMDISCTEMCON_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ItemDiscTempConfigData: action.payload
            }
        }
        default: return state;
    }

}

function ServiceChangeRuleFetchReducer(state = {
    ServiceChangeRuleFetchData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERVICECHANGERULE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERVICECHANGERULE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERVICECHANGERULE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceChangeRuleFetchData: action.payload
            }
        }
        default: return state;
    }

}

function ServiceChangeRuleSaveReducer(state = {
    ServiceChangeRuleSaveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERVICECHANGERULE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERVICECHANGERULE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERVICECHANGERULE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceChangeRuleSaveData: action.payload
            }
        }
        default: return state;
    }

}

function userValidatePasswordResetReducer(state = {
    userValidatePassReset: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.USERPASSWORD_RESET_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.USERPASSWORD_RESET_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.USERPASSWORD_RESET_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                userValidatePassReset: action.payload
            }
        }
        default: return state;
    }

}
function userValidateOTPReducer(state = {
    validateOTP: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.VALIDATE_OTP_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.VALIDATE_OTP_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.VALIDATE_OTP_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                validateOTP: action.payload
            }
        }
        default: return state;
    }

}
function getUserDataForResetPasswordReducer(state = {
    userDataForResetPassword: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RESETPASSWORD_GET_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RESETPASSWORD_GET_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RESETPASSWORD_GET_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                userDataForResetPassword: action.payload
            }
        }
        default: return state;
    }

}
function userPasswordResetSaveReducer(state = {
    userResetPasswordStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RESETPASSWORD_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RESETPASSWORD_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RESETPASSWORD_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                userResetPasswordStatus: action.payload
            }
        }
        default: return state;
    }

}
function AssociateEmployeeSaveReducer(state = {
    AssociateEmployeeSaveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ASSOCIATE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ASSOCIATE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ASSOCIATE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AssociateEmployeeSaveData: action.payload
            }
        }
        default: return state;
    }

}

function AssociateEmployeefetchReducer(state = {
    AssociateEmployeefetchData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ASSOCIATE_LIST_FAIL: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ASSOCIATE_LIST_REQUEST: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ASSOCIATE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AssociateEmployeefetchData: action.payload
            }
        }
        default: return state;
    }

}


function changePasswordAfterLoginReducer(state = {
    changePasswordStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CHANGEPASSWORD_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CHANGEPASSWORD_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CHANGEPASSWORD_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                changePasswordStatus: action.payload
            }
        }
        default: return state;
    }

}
function changePinAfterLoginReducer(state = {
    changePinStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CHANGEPIN_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CHANGEPIN_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CHANGEPIN_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                changePinStatus: action.payload
            }
        }
        default: return state;
    }

}

function savePaymentModeReducer(state = {
    savePaymentModeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PAYMENTMODE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PAYMENTMODE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PAYMENTMODE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                savePaymentModeData: action.payload
            }
        }
        default: return state;
    }

}

function SaveCompanyPrintReducer(state = {
    saveCompPrintStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPPRINT_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPPRINT_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPPRINT_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveCompPrintStatus: action.payload
            }
        }
        default: return state;
    }

}
function roleAccessReducer(state = {
    roleAccessData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ROLE_ACCESS_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ROLE_ACCESS_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ROLE_ACCESS_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                roleAccessData: action.payload
            }
        }
        default: return state;
    }

}

function getCheckListMasterReducer(state = {
    checkListMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GET_CHECK_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GET_CHECK_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GET_CHECK_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                checkListMasterData: action.payload
            }
        }
        default: return state;
    }

}

function AppErDashBoardReducer(state = {
    GetErDashBoard: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ErDashBoard_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ErDashBoard_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ErDashBoard_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetErDashBoard: action.payload
            }
        }
        default: return state;
    }
}

function SaveErDashBoardReducer(state = {
    SaveErDashBoard: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ErDashBoard_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ErDashBoard_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ErDashBoard_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveErDashBoard: action.payload
            }
        }
        default: return state;
    }
}


function AppTriageReducer(state = {
    GetTriageData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.TriageCategory_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.TriageCategory_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.TriageCategory_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetTriageData: action.payload
            }
        }
        default: return state;
    }
}

function SaveTriageReducer(state = {
    SaveTriageData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.TriageCategory_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.TriageCategory_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.TriageCategory_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveTriageData: action.payload
            }
        }
        default: return state;
    }
}
function getSearchEmployeeListReducer(state = {
    employeeList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SeachEmployee_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SeachEmployee_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SeachEmployee_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                employeeList: action.payload
            }
        }
        default: return state;
    }

}
function getSearchFacilityEmployeeListReducer(state = {
    facilitWiseEmployeeList: intialstate,
    fetching: false,
    fetched: false,
    error: null

}, action) {
    switch (action.type) {
        case AMCnst.SeachFacilityWiseEmployee_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SeachFacilityWiseEmployee_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SeachFacilityWiseEmployee_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                facilitWiseEmployeeList: action.payload
            }
        }
        default: return state;
    }
}
function getSearchFacilityDocListReducer(state = {
    facilitWiseDocList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DOC_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null, facilitWiseDocList: [] } }
        case AMCnst.DOC_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true, facilitWiseDocList: [] } }
        case AMCnst.DOC_LIST_SUCCESS: { return { ...state, fetching: false, fetched: true, error: null, facilitWiseDocList: action.payload } }
        default: return state;
    }
}
function saveCompanyChecklistReducer(state = {
    saveCompanyCheckList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SAVECOMPANY_CHECKLIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SAVECOMPANY_CHECKLIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SAVECOMPANY_CHECKLIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveCompanyCheckList: action.payload
            }
        }
        default: return state;
    }

}


function saveModuleFromUserReducer(state = {
    saveModuleFromUser: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SAVECOMPANY_CHECKLIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SAVECOMPANY_CHECKLIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SAVECOMPANY_CHECKLIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveModuleFromUser: action.payload
            }
        }
        default: return state;
    }

}

function CurrencyDenominationReducer(state = {
    CurrencyDenominationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CurrencyDenomination_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CurrencyDenomination_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CurrencyDenomination_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CurrencyDenominationData: action.payload
            }

        }
        default: return state;
    }

}
// *******************KC****************
function FreeServiceOrderReducer(state = {
    FreeServiceOrderData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CurrencyDenomination_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CurrencyDenomination_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CurrencyDenomination_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FreeServiceOrderData: action.payload
            }

        }
        default: return state;
    }

}
// *******************KC****************


function fetchServiceLastOrderDtlReducer(state = {
    fetchServiceLastOrderDtlData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ServiceLastOrderDtl_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ServiceLastOrderDtl_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ServiceLastOrderDtl_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                fetchServiceLastOrderDtlData: action.payload
            }
        }
        default: return state;
    }

}

function SaveEREncounterReducer(state = {
    EREncounterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ERencounter_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ERencounter_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ERencounter_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EREncounterData: action.payload
            }
        }
        default: return state;
    }

}

function fetchFormNamesReducer(state = {
    FormNamesData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FormName_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FormName_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FormName_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FormNamesData: action.payload
            }
        }
        default: return state;
    }

}
function SaveChiefComplaintReducer(state = {
    SaveChiefComplaintData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ChiefComplaint_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ChiefComplaint_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ChiefComplaint_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveChiefComplaintData: action.payload
            }
        }
        default: return state;
    }

}
function AppChiefComplaintReducer(state = {
    ChiefComplaintData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ChiefComplaint_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ChiefComplaint_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ChiefComplaint_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ChiefComplaintData: action.payload
            }
        }
        default: return state;
    }

}
function AppDiagnoseMasterReducer(state = {
    DiagnoseMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DiagnoseMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DiagnoseMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DiagnoseMaster_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DiagnoseMasterData: action.payload
            }
        }
        default: return state;
    }

}
function SaveCampReducer(state = {
    SaveCampData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Camp_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Camp_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Camp_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveCampData: action.payload
            }
        }
        default: return state;
    }

}
function AppCampReducer(state = {
    CampData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Camp_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Camp_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true } }
        case AMCnst.Camp_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CampData: action.payload
            }
        }
        default: return state;
    }

}

function saveExclusionMainReducer(state = {
    saveExclusionMain: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ExclusionMain_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ExclusionMain_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ExclusionMain_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveExclusionMain: action.payload
            }
        }
        default: return state;
    }

}
function saveExclusionDetailReducer(state = {
    saveExclusionDetail: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ExclusionDetail_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ExclusionDetail_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ExclusionDetail_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveExclusionDetail: action.payload
            }
        }
        default: return state;
    }

}


function AnatomicSiteMstReducer(state = {
    AnatomicSiteMstData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AnatomicSiteMst_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AnatomicSiteMst_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AnatomicSiteMst_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AnatomicSiteMstData: action.payload
            }
        }
        default: return state;
    }

}
function AppFacilitySaveReducer(state = {
    FacilityMasterSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FACILITY_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FACILITY_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FACILITY_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FacilityMasterSave: action.payload
            }
        }
        default: return state;
    }

}

function SaveServiceTariffDetailReducer(state = {
    SaveServiceTariffDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ServiceNomenclature_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ServiceNomenclature_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ServiceNomenclature_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveServiceTariffDetailData: action.payload
            }
        }
        default: return state;
    }
}

function AppFacilityMasterListReducer(state = {
    FacilityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FACILITY_MASTER_LIST_REQUEST: { return { ...state, fetched: false, fetching: true, error: null } }
        case AMCnst.FACILITY_MASTER_LIST_FAIL: { return { ...state, fetching: false, fetched: true, FacilityData: intialstate, error: action.payload, fetched: true } }
        case AMCnst.FACILITY_MASTER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FacilityData: action.payload
            }
        }
        default: return state;
    }
}

function saveServiceNomenclatureReducer(state = {
    saveServiceNomenclatureData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ServiceNomenclature_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ServiceNomenclature_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ServiceNomenclature_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveServiceNomenclatureData: action.payload
            }
        }
        default: return state;
    }
}

function AppServiceTariffHistoryReducer(state = {
    ServiceTariffHistoryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERTARHistory_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERTARHistory_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERTARHistory_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceTariffHistoryData: action.payload
            }
        }
        default: return state;
    }

}

function getCompanyDisTemplateMappingReducer(state = {
    companyTariffMappingList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPANY_DISC_MAPPING_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPANY_DISC_MAPPING_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPANY_DISC_MAPPING_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                companyTariffMappingList: action.payload
            }
        }
        default: return state;
    }

}
function GetPayorPackageDetailsMaster(state = {
    OPPackagePayorGetData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.OPPackagePayor_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.OPPackagePayor_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.OPPackagePayor_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OPPackagePayorGetData: action.payload
            }
        }
        default: return state;
    }

}
function SaveServiceBedCategoryFactorDtlReducer(state = {
    SaveServiceBedCategoryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BEDCATFACDTL_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BEDCATFACDTL_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BEDCATFACDTL_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveServiceBedCategoryData: action.payload
            }
        }
        default: return state;
    }
}

function fetchgetCompanyNomenclatureReducer(state = {
    CompanyNomenclatureData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CompanyNomenclature_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CompanyNomenclature_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CompanyNomenclature_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompanyNomenclatureData: action.payload
            }
        }
        default: return state;
    }
}
//added 31August2021 for BuildingMaster
function SaveBuildingReducer(state = {
    SaveBuildingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Building_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Building_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Building_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveBuildingData: action.payload
            }
        }
        default: return state;
    }
}
//end added 31August2021 for BuildingMaster

function CompanyTariffPlanReducer(state = {
    CompanyTariffPlanData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PLAN_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PLAN_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PLAN_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompanyTariffPlanData: action.payload
            }
        }
        default: return state;
    }
}


//pradhyuman API 1
function AppServiceControlledPriceSaveReducer(state = {
    ServiceControlPriceSaveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERVICE_CONTROL_PRICE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERVICE_CONTROL_PRICE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERVICE_CONTROL_PRICE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceControlPriceSaveData: action.payload
            }
        }
        default: return state;
    }

}


//pradhyuman API 2
function AppServiceControlledPriceReducer(state = {
    ServiceControlPriceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERVICE_CONTROL_PRICE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERVICE_CONTROL_PRICE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERVICE_CONTROL_PRICE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceControlPriceData: action.payload
            }
        }
        default: return state;
    }

}

function AppConsultSetupSaveReducer(state = {
    ConsultSetupSaveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ConsultSetup_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ConsultSetup_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ConsultSetup_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ConsultSetupSaveData: action.payload
            }
        }
        default: return state;
    }

}

function AppConsultSetupReducer(state = {
    ConsultSetupData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ConsultSetup_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ConsultSetup_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ConsultSetup_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ConsultSetupData: action.payload
            }
        }
        default: return state;
    }

}

function AppCompMOUDetailSaveReducer(state = {
    CompMOUDetailSaveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.MOUDetails_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.MOUDetails_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.MOUDetails_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompMOUDetailSaveData: action.payload
            }
        }
        default: return state;
    }

}
function AppDoctorDetailfetchReducer(state = {
    DoctorDetailfetch: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DoctorDetailList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DoctorDetailList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DoctorDetailList_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DoctorDetailfetch: action.payload
            }
        }
        default: return state;
    }

}
function AppDoctorDetailSaveReducer(state = {
    DoctorDetailSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DoctorDetail_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DoctorDetail_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DoctorDetail_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DoctorDetailSave: action.payload
            }
        }
        default: return state;
    }

}
function AppServiceAttributeReducer(state = {
    ServiceAttributeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SERATT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SERATT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SERATT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceAttributeData: action.payload
            }
        }
        default: return state;
    }
}

function AppSaveServiceAttributeReducer(state = {
    SaveServiceAttributeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SAVESERATT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SAVESERATT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SAVESERATT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveServiceAttributeData: action.payload
            }
        }
        default: return state;
    }
}

function ReportFacilityMappingReducer(state = {
    ReportFacilitydata: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ReportFacilityMapping_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ReportFacilityMapping_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ReportFacilityMapping_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReportFacilitydata: action.payload
            }
        }
        default: return state;
    }
}

function SaveReportFacilityMappingReducer(state = {
    SaveReportFacilityMapping: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ReportFacilityMapping_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ReportFacilityMapping_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ReportFacilityMapping_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveReportFacilityMapping: action.payload
            }
        }
        default: return state;
    }
}
function AppEmpByTypeReducer(state = {
    EmpByTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EMPBYTYPE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EMPBYTYPE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EMPBYTYPE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmpByTypeData: action.payload
            }
        }
        default: return state;
    }

}

function getSearchServiceMasterReducer(state = {
    SearchServiceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SearchSVCMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SearchSVCMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SearchSVCMaster_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SearchServiceData: action.payload
            }
        }
        default: return state;
    }

}
function SaveCompanyExlAutoServiceReducer(state = {
    SaveCompanyExlAutoServiceStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPEXLAUTO_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPEXLAUTO_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPEXLAUTO_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveCompanyExlAutoServiceStatus: action.payload
            }
        }
        default: return state;
    }

}


function reasonMasterReducer(state = {
    reasonMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.reasonMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.reasonMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.reasonMaster_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,

                reasonMasterData: action.payload
            }
        }
        default: return state;
    }

}

function SaveReasonMasterReducer(state = {
    SaveReasonMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SaveReasonMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SaveReasonMaster_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SaveReasonMaster_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,

                SaveReasonMasterData: action.payload
            }
        }
        default: return state;
    }

}

function SavemoduleReasonReducer(state = {
    SavemoduleReasonData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ModuleReason_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ModuleReason_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ModuleReason_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,

                SavemoduleReasonData: action.payload
            }
        }
        default: return state;
    }

}

function GetModuleReasonReducer(state = {
    GetModuleReasonData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetModuleReason_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetModuleReason_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetModuleReason_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,

                GetModuleReasonData: action.payload
            }
        }
        default: return state;
    }

}



function GetModulePageEventsReducer(state = {
    modulePageEventsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetModulePageEvents_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetModulePageEvents_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetModulePageEvents_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,

                modulePageEventsData: action.payload
            }
        }
        default: return state;
    }

}
function getPageListForValidationSetupReducer(state = {
    GetPageList: intialstate, fetching: false, fetched: false, error: null
}, action) {
    switch (action.type) {
        case AMCnst.PAGE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PAGE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PAGE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetPageList: action.payload
            }
        }
        default: return state;
    }

}

function getPageValidationSetupMasterReducer(state = {
    GetPageMaster: intialstate, fetching: false, fetched: false, error: null
}, action) {
    switch (action.type) {
        case AMCnst.GET_PAGE_VALIDATION_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GET_PAGE_VALIDATION_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GET_PAGE_VALIDATION_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetPageMaster: action.payload
            }
        }
        default: return state;
    }

}
function getNursingDischargeOptionMasterReducer(state = {
    GetOptionMaster: intialstate, fetching: false, fetched: false, error: null
}, action) {
    switch (action.type) {
        case AMCnst.GET_NURSING_DISH_OPTION_MST_REQUEST: {
            return { ...state, fetching: true, fetched: false, error: null }
        }
        case AMCnst.GET_NURSING_DISH_OPTION_MST_FAIL: {
            return { ...state, fetching: false, error: action.payload, fetched: true }
        }
        case AMCnst.GET_NURSING_DISH_OPTION_MST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetOptionMaster: action.payload
            }
        }
        default: return state;
    }

}


//---ak---------------currency reducer--------------//
function AppCurrencyDenominationReducer(state = {
    CurrencyDenominationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CurrencyDenomination_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CurrencyDenomination_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true, CurrencyDenominationData: intialstate } }
        case AMCnst.CurrencyDenomination_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CurrencyDenominationData: action.payload
            }
        }
        default: return state;
    }

}

// ***********************KC**********************
function AppFreeServiceOrderReducer(state = {
    FreeServiceOrderData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FreeServiceOrder_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FreeServiceOrder_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true, FreeServiceOrderData: intialstate } }
        case AMCnst.FreeServiceOrder_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FreeServiceOrderData: action.payload
            }
        }
        default: return state;
    }

}


function GetSurgeryGradeServiceReducer(state = {
    SurgeryGradeServiceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SurgeryGradeService_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SurgeryGradeService_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true, SurgeryGradeServiceData: intialstate } }
        case AMCnst.SurgeryGradeService_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SurgeryGradeServiceData: action.payload
            }
        }
        default: return state;
    }

}

//----------------------ak-----------------------//

function SaveCurrencyDenominationReducer(state = {
    SaveCurrencyDenominationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CurrencyDenomination_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CurrencyDenomination_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CurrencyDenomination_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveCurrencyDenominationData: action.payload
            }
        }
        default: return state;
    }
}

// ***********************kc**************
function SaveRevenueAndTargetReducer(state = {
    SaveRevenueAndTargetData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RevenueAndTarget_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RevenueAndTarget_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RevenueAndTarget_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveRevenueAndTargetData: action.payload
            }
        }
        default: return state;
    }
}

function SaveFreeServiceOrderReducer(state = {
    saveFreeServiceOrderData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FreeServiceOrder_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FreeServiceOrder_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FreeServiceOrder_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveFreeServiceOrderData: action.payload
            }
        }
        default: return state;
    }
}
// ***************kc***********************



function SaveSurgeryGradeServiceReducer(state = {
    SaveSurgeryGradeServiceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SurgeryGradeService_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SurgeryGradeService_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SurgeryGradeService_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveSurgeryGradeServiceData: action.payload
            }
        }
        default: return state;
    }
}
//-----------------------ak----------------------

function SaveCurrencyReducer(state = {
    SaveCurrencyData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Currency_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Currency_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Currency_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveCurrencyData: action.payload
            }
        }
        default: return state;
    }
}


function AppGetModulePageFormTaggingReducer(state = {
    GetModulePageFormTaggingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetModulePageFormTagging_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetModulePageFormTagging_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true, CurrencyDenominationData: intialstate } }
        case AMCnst.GetModulePageFormTagging_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetModulePageFormTaggingData: action.payload
            }
        }
        default: return state;
    }

}

function getModulePageFromUserReducer(state = {
    GetModulePageFormUserData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.getModulePageFromUser_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.getModulePageFromUser_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true, CurrencyDenominationData: intialstate } }
        case AMCnst.getModulePageFromUser_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetModulePageFormUserData: action.payload
            }
        }
        default: return state;
    }

}







//------------------------------------------ak------------------------------

function SaveGetModulePageFormTaggingReducer(state = {
    SaveGetModulePageFormTaggingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetModulePageFormTagging_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetModulePageFormTagging_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetModulePageFormTagging_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveGetModulePageFormTaggingData: action.payload
            }
        }
        default: return state;
    }
}

function ModulePagesByModuleReducer(state = {
    ModulePagesByModuleData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GET_ModulePagesByModule_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GET_ModulePagesByModule_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GET_ModulePagesByModule_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ModulePagesByModuleData: action.payload
            }

        }
        default: return state;
    }

}

function SaveModulePageEventReducer(state = {
    SaveModulePageEventData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SAVE_ModulePageEvent_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SAVE_ModulePageEvent_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SAVE_ModulePageEvent_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveModulePageEventData: action.payload
            }
        }
        default: return state;
    }
}
//---------------------------------ak--------------------
function getModulePageEventReducer(state = {
    ModulePageEventData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GET_ModulePageEvent_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GET_ModulePageEvent_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GET_ModulePageEvent_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ModulePageEventData: action.payload
            }

        }
        default: return state;
    }

}

function getReasonWiseModulePageEventReducer(state = {
    ReasonWiseModulePageEventData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GET_ReasonWiseModulePageEvent_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GET_ReasonWiseModulePageEvent_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GET_ReasonWiseModulePageEvent_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReasonWiseModulePageEventData: action.payload
            }

        }
        default: return state;
    }

}
function SaveCompanyBedCatReducer(state = {
    SaveCompanyBedCatStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPBEDCAT_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPBEDCAT_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPBEDCAT_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveCompanyBedCatStatus: action.payload
            }
        }
        default: return state;
    }

}



function GetPayorPageAuditReducer(state = {
    PayorPageAuditData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetPayorPageAudit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetPayorPageAudit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetPayorPageAudit_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PayorPageAuditData: action.payload
            }
        }
        default: return state;
    }

}



function GetPayorPageAuditFacilityMappingReducer(state = {
    PayorPageAuditFacilityMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetPayorPageAuditFacilityMapping_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetPayorPageAuditFacilityMapping_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetPayorPageAuditFacilityMapping_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PayorPageAuditFacilityMappingData: action.payload
            }
        }
        default: return state;
    }

}




function GetPayorPageAuditPlanReducer(state = {
    PayorPageAuditPlanData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetPayorPageAuditPlan_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetPayorPageAuditPlan_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetPayorPageAuditPlan_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PayorPageAuditPlanData: action.payload
            }
        }
        default: return state;
    }

}



function GetPayorPageAuditTariffAndDiscountReducer(state = {
    PayorPageAuditTariffAndDiscountData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetPayorPageAuditTariffAndDiscount_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetPayorPageAuditTariffAndDiscount_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetPayorPageAuditTariffAndDiscount_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PayorPageAuditTariffAndDiscountData: action.payload
            }
        }
        default: return state;
    }

}




function GetPayorPageAuditCompNonDiscServiceReducer(state = {
    PayorPageAuditCompNonDiscServiceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetPayorPageAuditCompNonDiscService_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetPayorPageAuditTariffAndDiscount_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetPayorPageAuditCompNonDiscService_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PayorPageAuditCompNonDiscServiceData: action.payload
            }
        }
        default: return state;
    }

}






function GetPayorPageAuditExclusionServiceReducer(state = {
    PayorPageAuditExclusionServiceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetPayorPageAuditCompServiceExl_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetPayorPageAuditCompNonDiscService_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetPayorPageAuditCompServiceExl_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PayorPageAuditExclusionServiceData: action.payload
            }
        }
        default: return state;
    }

}


function GetPayorPageAuditExclusionGroupMappingReducer(state = {
    PayorPageAuditExclusionGroupMappingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetPayorPageAuditExclusionGroupMapping_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetPayorPageAuditExclusionGroupMapping_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetPayorPageAuditExclusionGroupMapping_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PayorPageAuditExclusionGroupMappingData: action.payload
            }
        }
        default: return state;
    }

}



function GetPayorPageAuditBedCategoryReducer(state = {
    PayorPageAuditBedCategoryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetPayorPageAuditBedCategory_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetPayorPageAuditBedCategory_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetPayorPageAuditBedCategory_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PayorPageAuditBedCategoryData: action.payload
            }
        }
        default: return state;
    }

}



function GetPayorPageAuditExlAutoServiceDtlReducer(state = {
    PayorPageAuditExlAutoServiceDtlData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetPayorPageAuditExlAutoServiceDtl_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetPayorPageAuditExlAutoServiceDtl_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetPayorPageAuditExlAutoServiceDtl_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PayorPageAuditExlAutoServiceDtlData: action.payload
            }
        }
        default: return state;
    }

}



function GetPayorPageAuditMarkupByServiceReducer(state = {
    PayorPageAuditMarkupByServiceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetPayorPageAuditMarkupByService_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetPayorPageAuditMarkupByService_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetPayorPageAuditMarkupByService_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PayorPageAuditMarkupByServiceData: action.payload
            }
        }
        default: return state;
    }

}



function GetPayorPageAuditMarkupExclusionReducer(state = {
    PayorPageAuditMarkupExclusionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetPayorPageAuditMarkupExclusion_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetPayorPageAuditMarkupExclusion_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetPayorPageAuditMarkupExclusion_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PayorPageAuditMarkupExclusionData: action.payload
            }
        }
        default: return state;
    }

}

function GetFormBuilderReducer(state = {
    GetFormBuilderData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetFormBuilderStatus_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetFormBuilderStatus_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetFormBuilderStatus_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetFormBuilderData: action.payload
            }

        }
        default: return state;
    }

}




function AppSponsorReducer(state = {
    SaveSponsorData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.COMPSPO_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.COMPSPO_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.COMPSPO_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveSponsorData: action.payload
            }
        }
        default: return state;
    }

}
function AppFIStatusReducer(state = {
    FIStatusData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FIStatus_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FIStatus_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FIStatus_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FIStatusData: action.payload
            }
        }
        default: return state;
    }

}
function AppFITCReducer(state = {
    FITCData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FITC_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FITC_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FITC_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FITCData: action.payload
            }
        }
        default: return state;
    }

}

function GetSMSConfigParameterReducer(state = {
    GetSMSConfigParameterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Get_SMS_Config_Parameter_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Get_SMS_Config_Parameter_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Get_SMS_Config_Parameter_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetSMSConfigParameterData: action.payload
            }
        }
        default: return state;
    }

}

function GetSMSConfigSubParameterReducer(state = {
    GetSMSConfigSubParameterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Get_SMS_Config_Sub_Parameter_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Get_SMS_Config_Sub_Parameter_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Get_SMS_Config_Sub_Parameter_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetSMSConfigSubParameterData: action.payload
            }
        }
        default: return state;
    }
}

function GetSMSConfigurationReducer(state = {
    GetSMSConfigurationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Get_SMS_Configuration_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Get_SMS_Configuration_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Get_SMS_Configuration_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetSMSConfigurationData: action.payload
            }
        }
        default: return state;
    }

}

function SaveSMSConfigurationReducer(state = {
    SMSConfigSaveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SMS_Configuration_Save_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SMS_Configuration_Save_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SMS_Configuration_Save_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SMSConfigSaveData: action.payload
            }
        }
        default: return state;
    }

}

function GetSMSEventDetailReducer(state = {
    GetSMSEventData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Get_SMS_EventDetail_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Get_SMS_EventDetail_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Get_SMS_EventDetail_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetSMSEventData: action.payload
            }
        }
        default: return state;
    }

}


function GetSMSEventWithReceiverDetailReducer(state = {
    GetSMSEventWithReceiverData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Get_SMS_Event_WithReceiverDetail_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Get_SMS_Event_WithReceiverDetail_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Get_SMS_Event_WithReceiverDetail_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetSMSEventWithReceiverData: action.payload
            }
        }
        default: return state;
    }

}

function GetReportConfigListReducer(state = {
    GetReportConfigListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Get_Report_Config_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Get_Report_Config_List_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload } }
        case AMCnst.Get_Report_Config_List_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                GetReportConfigListData: action.payload
            }
        }
        default: return state;
    }
}


const get_emr_comp_Reducer = (state = {
    get_emr_comp_data: intialstate2,
    fetching: false,
    fetched: false,
    error: null,
}, action) => {
    switch (action.type) {
        case AMCnst.EMR_comp_LIST_REQUEST: { return { ...state, fetching: true, error: null, fetched: false } }
        case AMCnst.EMR_comp_LIST_SUCCESS: { return { ...state, fetching: false, fetched: true, get_emr_comp_data: action.payload } }
        case AMCnst.EMR_comp_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        default: return state;

    }


}


const hpf_grw_sum_Reducer = (state = {
    hpf_grw_sum_data: intialstate2,
    fetching: false,
    fetched: false,
    error: null,
}, action) => {
    switch (action.type) {
        case AMCnst.hpf_grw_sum_LIST_REQUEST: { return { ...state, fetching: true, error: null, fetched: false } }
        case AMCnst.hpf_grw_sum_LIST_SUCCESS: { return { ...state, fetching: false, fetched: true, hpf_grw_sum_data: action.payload } }
        case AMCnst.hpf_grw_sum_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        default: return state;

    }
}

const GetScheduleData = (state = {
    getFetchData: intialstate2,
    fetching: false,
    fetched: false,
    error: null,
}, action) => {
    switch (action.type) {
        case AMCnst.DOCTOR_SCHEDULE_LIST_REQUEST: { return { ...state, fetching: true, error: null, fetched: false } }
        case AMCnst.DOCTOR_SCHEDULE_LIST_SUCCESS: { return { ...state, fetching: false, fetched: true, getFetchData: action.payload } }
        case AMCnst.DOCTOR_SCHEDULE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        default: return state;

    }


}
function GetEmailSetupReducer(state = {
    GetEmailSetupData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Get_Email_Setup_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Get_Email_Setup_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload } }
        case AMCnst.Get_Email_Setup_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                GetEmailSetupData: action.payload
            }
        }
        default: return state;
    }
}
function AppMappedGradeReducer(state = {
    GradeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Mapped_Grade_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Mapped_Grade_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Mapped_Grade_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GradeData: action.payload
            }
        }
        default: return state;
    }
}

function ValidateExcelListBulkReducer(state = {
    ValidexcelData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ImportExcel_Bulk_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ImportExcel_Bulk_List_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload } }
        case AMCnst.ImportExcel_Bulk_List_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                ValidexcelData: action.payload
            }
        }
        default: return state;
    }
}

function SaveExcelListBulkReducer(state = {
    SaveExcelData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SaveExcel_Bulk_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SaveExcel_Bulk_List_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload } }
        case AMCnst.SaveExcel_Bulk_List_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                SaveExcelData: action.payload
            }
        }
        default: return state;
    }
}


function ValidateItemMasterExcelReducer(state = {
    ValidateItemExcelData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ValidateItemExcel_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ValidateItemExcel_List_FAIL: { return { ...state, fetching: false, fetched: true, error: action.error } }
        case AMCnst.ValidateItemExcel_List_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                ValidateItemExcelData: action.payload
            }
        }
        default: return state;
    }
}

function SaveItemMasterExcelReducer(state = {
    SaveItemExcelData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SaveItemExcel_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SaveItemExcel_List_FAIL: { return { ...state, fetching: false, fetched: true, error: action.error } }
        case AMCnst.SaveItemExcel_List_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                SaveItemExcelData: action.payload
            }
        }
        default: return state;
    }
}
function AppDepartmentEmpReducer(state = {
    DeptMstEmpData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DEPTMSTEMP_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DEPTMSTEMP_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DEPTMSTEMP_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DeptMstEmpData: action.payload
            }
        }
        default: return state;
    }

}

function AppDepartmentEmpResultReducer(state = {
    DeptMstEmpDataResult: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DEPTMSTEMP_LIST_Result_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DEPTMSTEMP_LIST_Result_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DEPTMSTEMP_LIST_Result_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DeptMstEmpDataResult: action.payload
            }
        }
        default: return state;
    }

}


function GetSAPPayorCategoryDiscountSetup(state = {
    GetSAPPayorCategoryDiscount: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SAPPayorCategoryDiscountSetup_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SAPPayorCategoryDiscountSetup_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SAPPayorCategoryDiscountSetup_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetSAPPayorCategoryDiscount: action.payload
            }
        }
        default: return state;
    }
}
function CategoryListReducer(state = {
    CategoryListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CATEGORY_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CATEGORY_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CATEGORY_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CategoryListData: action.payload
            }
        }
        default: return state;
    }

}

function SaveSAPPayorCategoryDiscountSetupReducer(state = {
    SaveSAPPayorCategoryDiscountSetup: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SAPPayorCategoryDiscountSetup_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SAPPayorCategoryDiscountSetup_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SAPPayorCategoryDiscountSetup_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveSAPPayorCategoryDiscountSetup: action.payload
            }
        }
        default: return state;
    }

}

function taxsetupListReducer(state = {
    taxsetupListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.TAXSETUP_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.TAXSETUP_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.TAXSETUP_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                taxsetupListData: action.payload
            }

        }
        default: return state;
    }

}
function ReSyncdataListReducer(state = {
    ReSyncListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ReSync_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ReSync_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ReSync_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReSyncListData: action.payload
            }

        }
        default: return state;
    }

}

function ReSyncIntegrationdataListReducer(state = {
    ReSyncIntegrationdataListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ReSyncIntergation_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ReSyncIntergation_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ReSyncIntergation_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReSyncIntegrationdataListData: action.payload
            }

        }
        default: return state;
    }

}


function InsertInvoicedataListReducer(state = {
    InsertInvoiceListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.InvoiceData_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.InvoiceData_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.InvoiceData_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InsertInvoiceListData: action.payload
            }

        }
        default: return state;
    }

}

function SettlementReSyncdataListReducer(state = {
    SettlementReSyncListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.settlementReSync_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.settlementReSync_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.settlementReSync_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SettlementReSyncListData: action.payload
            }

        }
        default: return state;
    }

}


function InsertSettlementdataListReducer(state = {
    InsertSettlementListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SettlementData_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SettlementData_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SettlementData_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InsertSettlementListData: action.payload
            }

        }
        default: return state;
    }

}

function ReceiptReSyncdataListReducer(state = {
    ReceiptReSyncListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.receiptReSync_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.receiptReSync_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.receiptReSync_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReceiptReSyncListData: action.payload
            }

        }
        default: return state;
    }

}
function ReceiptpayorReSyncdataListReducer(state = {
    ReceiptpayorReSyncListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.receiptpayReSync_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.receiptpayReSync_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.receiptpayReSync_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReceiptpayorReSyncListData: action.payload
            }

        }
        default: return state;
    }

}

function RefundReSyncdataListReducer(state = {
    RefundReSyncListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.refundReSync_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.refundReSync_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.refundReSync_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                RefundReSyncListData: action.payload
            }

        }
        default: return state;
    }

}
function InsertCollectiondataListReducer(state = {
    InsertCollectionListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CollectionData_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CollectionData_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CollectionData_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InsertCollectionListData: action.payload
            }

        }
        default: return state;
    }

}

function CreditnoteReSyncdataListReducer(state = {
    CreditnoteReSyncListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.creditnoteReSync_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.creditnoteReSync_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.creditnoteReSync_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CreditnoteReSyncListData: action.payload
            }

        }
        default: return state;
    }

}
function InsertCreditnotedataListReducer(state = {
    InsertcreditnoteListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CreditnoteData_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CreditnoteData_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CreditnoteData_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InsertcreditnoteListData: action.payload
            }

        }
        default: return state;
    }

}

function InvoicewriteoffReSyncdataListReducer(state = {
    WriteoffReSyncListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.writeoffReSync_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.writeoffReSync_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.writeoffReSync_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                WriteoffReSyncListData: action.payload
            }

        }
        default: return state;
    }

}
function InsertWriteoffdataListReducer(state = {
    InsertwriteoffListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WriteoffData_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WriteoffData_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.WriteoffData_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InsertwriteoffListData: action.payload
            }

        }
        default: return state;
    }

}
function EncounterReSyncdataListReducer(state = {
    encounterReSyncListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.encounterReSync_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.encounterReSync_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.encounterReSync_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                encounterReSyncListData: action.payload
            }

        }
        default: return state;
    }

}
function InsertencounterdataListReducer(state = {
    InsertencounterListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.encounterData_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.encounterData_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.encounterData_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InsertencounterListData: action.payload
            }

        }
        default: return state;
    }

}
function CxlinvoiceReSyncdataListReducer(state = {
    cxlinvoiceReSyncListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.cxlinvoiceReSync_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.cxlinvoiceReSync_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.cxlinvoiceReSync_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                cxlinvoiceReSyncListData: action.payload
            }

        }
        default: return state;
    }

}
function InsertcxlinvoicedataListReducer(state = {
    InsertcxlinvoiceListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.cxlinvoiceData_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.cxlinvoiceData_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.cxlinvoiceData_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InsertcxlinvoiceListData: action.payload
            }

        }
        default: return state;
    }

}
function ClassifiedinvoiceReSyncdataListReducer(state = {
    classifiedinvoiceReSyncListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.classifiedinvoiceReSync_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.classifiedinvoiceReSync_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.classifiedinvoiceReSync_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                classifiedinvoiceReSyncListData: action.payload
            }

        }
        default: return state;
    }

}
function InsertclassifiedinvoicedataListReducer(state = {
    InsertclassifiedinvoiceListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.classifiedinvoiceData_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.classifiedinvoiceData_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.classifiedinvoiceData_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InsertclassifiedinvoiceListData: action.payload
            }

        }
        default: return state;
    }

}
function tdsReSyncdataListReducer(state = {
    tdsReSyncListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.tdsReSync_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.tdsReSync_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.tdsReSync_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                tdsReSyncListData: action.payload
            }

        }
        default: return state;
    }

}
function InserttdsdataListReducer(state = {
    InserttdsinvoiceListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.tdsData_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.tdsData_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.tdsData_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InserttdsinvoiceListData: action.payload
            }

        }
        default: return state;
    }

}

function serviceReSyncdataListReducer(state = {
    serviceReSyncListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.serviceReSync_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.serviceReSync_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.serviceReSync_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                serviceReSyncListData: action.payload
            }

        }
        default: return state;
    }

}
function InsertservicedataListReducer(state = {
    InsertserviceorderListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.serviceData_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.serviceData_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.serviceData_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InsertserviceorderListData: action.payload
            }

        }
        default: return state;
    }

}

function Getchecklist_masterReducer(state = {
    checkListMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GET_CHECK_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GET_CHECK_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GET_CHECK_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                checkListMasterData: action.payload
            }
        }
        default: return state;
    }

}
function saveChecklistReducer(state = {
    saveMaterCheckList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SAVEMaster_CHECKLIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SAVEMaster_CHECKLIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SAVEMaster_CHECKLIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveMaterCheckList: action.payload
            }
        }
        default: return state;
    }

}
function GetCourierListReducer(state = {
    GetCourierList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Get_CourierList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Get_CourierList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Get_CourierList_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetCourierList: action.payload
            }
        }
        default: return state;
    }

}

function GetStockDashboardReducer(state = {
    GetStockDashboard: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetStockDashboard_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetStockDashboard_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetStockDashboard_List_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetStockDashboard: action.payload
            }
        }
        default: return state;
    }

}


function GetVitalGroupReducer(state = {
    vitalGroupdata: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Get_Vital_Group_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Get_Vital_Group_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Get_Vital_Group_List_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                vitalGroupdata: action.payload
            }
        }
        default: return state;
    }

}
function Ins_Coll_ReportDBReducer(state = {
    Ins_Coll_ReportDBData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Ins_Coll_ReportDB_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Ins_Coll_ReportDB_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Ins_Coll_ReportDB_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                Ins_Coll_ReportDBData: action.payload
            }
        }
        default: return state;
    }

}
function AppInPatientReqCategoryReducer(state = {
    InPatientReqCategory: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.INPATIENTREQCATEGORY_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.INPATIENTREQCATEGORY_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.INPATIENTREQCATEGORY_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InPatientReqCategory: action.payload
            }
        }
        default: return state;
    }

}
// function AppsaveInPatientReqCategoryReducer(state = {
//     InPatientReqCategorySave: intialstate,
//     fetching: false,
//     fetched: false,
//     error: null 
// }, action) {
//     switch (action.type) { 
//         case AMCnst.INPATIENTREQCATEGORY_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
//         case AMCnst.INPATIENTREQCATEGORY_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
//         case AMCnst.INPATIENTREQCATEGORY_SAVE_SUCCESS: {
//             return {
//                 ...state, error: null,
//                 fetching: false,
//                 fetched: true,
//                 InPatientReqCategory: action.payload
//             }
//         }
//         default: return state;
//     }

// }

const validateSettlementDataReducer = (state = {}, { type, payload }) => {

    console.log(type);
    switch (type) {
        case AMCnst.VALIDATE_SETTLEMENT_DATA:
            { return { ...state, ...payload } }
        case AMCnst.ERROR_VALIDATE_SETTLEMENT_DATA: { return { ...state, ...payload } }
        default:
            return state;
    }
};

const getSettlementDataReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case AMCnst.GET_SETTLEMENT_DATA_CONST:
            { return { ...state, ...payload } }
        case AMCnst.ERROR_GET_SETTLEMENT_DATA_CONST: { return { ...state, ...payload } }
        default:
            return state;
    }
};

const ExcleFileUploadActionReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case AMCnst.EXCEL_FILE_UPLOAD_SETTLEMENT:
            { return { ...state, ...payload } }
        case AMCnst.ERROR_EXCEL_FILE_UPLOAD_SETTLEMENT: { return { ...state, ...payload } }
        default:
            return state;
    }
};
const BulkSettlementAdjustmentReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case AMCnst.BULK_SETTLEMENT_ADJUSTMENT:
            { return { ...state, ...payload } }
        case AMCnst.Error_BULK_SETTLEMENT_ADJUSTMENT: { return { ...state, ...payload } }
        default:
            return state;
    }
};

const DownlodBulkEnvActionReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case AMCnst.DOWNLOD_BULK_ENV_CONST:
            { return { ...state, ...payload } }
        case AMCnst.ERROR_DOWNLOD_BULK_ENV_CONST: { return { ...state, ...payload } }
        default:
            return state;
    }
};

function UpdateReportMasterDataReducer(state = {
    ReportMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.UPDATE_MASTER_REPORT_DATA_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.UPDATE_MASTER_REPORT_DATA_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.UPDATE_MASTER_REPORT_DATA_SUCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReportMasterData: action.payload
            }
        }
        default: return state;
    }

}

function AppDoctorSlotTimingReducer(state = {
    DoctorVisitSlotList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DoctorSlotTiming_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DoctorSlotTiming_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DoctorSlotTiming_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DoctorVisitSlotList: action.payload
            }
        }
        default: return state;
    }

}
function AppWebsiteServiceTariffChargeReducer(state = {
    ServiceTariffCharge: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WEBSITE_SERTARDTL_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WEBSITE_SERTARDTL_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.WEBSITE_SERTARDTL_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                WebsiteServiceTariffCharge: action.payload
            }
        }
        default: return state;
    }

}

function AppWebsiteConsultationTariffChargeReducer(state = {
    ConsultationTariffCharge: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WEBSITE_CONSTAR_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WEBSITE_CONSTAR_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.WEBSITE_CONSTAR_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                WebsiteConsultationTariffCharge: action.payload
            }
        }
        default: return state;
    }

}

function SaveWebsiteServiceTariffDetailReducer(state = {
    SaveServiceTariffDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WEBSITE_SERVICE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WEBSITE_SERVICE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.WEBSITE_SERVICE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveWebsiteServiceTariffDetailData: action.payload
            }
        }
        default: return state;
    }
}
function AppResourceDepReducer(state = {
    ResourceDepList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RESOURCEDEPARTMENT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RESOURCEDEPARTMENT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RESOURCEDEPARTMENT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ResourceDepList: action.payload
            }
        }
        default: return state;
    }

}
function AppResourceSubDepReducer(state = {
    ResourceSubDepList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RESOURCESUBDEPARTMENT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RESOURCESUBDEPARTMENT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RESOURCESUBDEPARTMENT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ResourceSubDepList: action.payload
            }
        }
        default: return state;
    }

}
function AppResourceMasterReducer(state = {
    ResourceMasterList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RESOURCEMASTER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RESOURCEMASTER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RESOURCEMASTER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ResourceMasterList: action.payload
            }
        }
        default: return state;
    }

}
function AppVulnerableTypeReducer(state = {
    VulnerableTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.VulnerableType_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.VulnerableType_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.VulnerableType_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                VulnerableTypeData: action.payload
            }
        }
        default: return state;
    }

}

function AppsaveVulnerableTypeReducer(state = {
    VulnerableTypeSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.VulnerableType_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.VulnerableType_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.VulnerableType_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                VulnerableTypeSaveStatus: action.payload
            }
        }
        default: return state;
    }

}


function StandardCodesAuditReducer(state = {
    StandardCodeAuditData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.StandardCodesAudit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.StandardCodesAudit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.StandardCodesAudit_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                StandardCodeAuditData: action.payload
            }

        }
        default: return state;
    }

}
function PaymentTransactionStatusReducer(state = {
    PaymentTransactionStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PAYMENTTRANSACTION_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PAYMENTTRANSACTION_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PAYMENTTRANSACTION_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PaymentTransactionStatus: action.payload
            }
        }
        default: return state;
    }

}


function GetBatchNoListReducer(state = {
    GetBatchNoListReducerdata: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BATCH_NO_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BATCH_NO_LIST_FAIL_DATA: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BATCH_NO_LIST_SUCCESS_DATA: {
            console.log("GetBatchNoListReducer>>>", action.type, action.payload)

            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetBatchNoListReducerdata: action.payload
            }
        }
        default: return state;
    }
}

function AppPinLabReducer(state = {
    PinLabData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PINLAB_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PINLAB_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PINLAB_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PinLabData: action.payload
            }
        }
        default: return state;
    }

}

//Shabal

function PaymentGatewayConfigurationReducer(state = {
    PaymentGatewayConfigurationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PaymentGateway_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PaymentGateway_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PaymentGateway_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PaymentGatewayConfigurationData: action.payload
            }
        }
        default: return state;
    }
}
function FacilityPaymentGatewayConfigurationReducer(state = {
    FacilityPaymentGatewayConfigurationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FacilityPaymentGateway_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FacilityPaymentGateway_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FacilityPaymentGateway_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FacilityPaymentGatewayConfigurationData: action.payload
            }
        }
        default: return state;
    }


}



export {
    GetPayorPageAuditExclusionItemReducer,
    GetPayorPageAuditExlAutoServiceDtlReducer,
    GetPayorPageAuditBedCategoryReducer,
    GetPayorPageAuditExclusionGroupMappingReducer,
    GetPayorPageAuditExclusionServiceReducer,
    GetPayorPageAuditCompNonDiscServiceReducer,
    GetPayorPageAuditMarkupExclusionReducer,
    GetPayorPageAuditMarkupByServiceReducer,
    GetPayorPageAuditTariffAndDiscountReducer,
    GetPayorPageAuditPlanReducer,
    GetPayorPageAuditFacilityMappingReducer,
    GetPayorPageAuditReducer,
    GetModuleReasonAuditReducer,
    GetReasonMasterAuditReducer,
    //pradhyuman work start
    AppServiceControlledPriceSaveReducer,
    AppServiceControlledPriceReducer,
    //pradhyuman work end
    AppRegistrationTypeReducer, AppNationalityReducer, AppReligionReducer, AppOccupationReducer, AppReferralTypeReducer, AppHWCTypeReducer,
    AppTitleReducer, AppDocumentTypeReducer, AppCountryReducer, AppStateByCountryReducer, AppCityReducer, AppAreaReducer, AppBillingCountryReducer, AppBillingStateByCountryReducer, AppBillingCityReducer, AppBillingAreaReducer,
    AppEducationReducer, AppLanguageReducer, AppRelationshipReducer, AppHCFReducer, AppPayorCategoryReducer, AppCompanyReducer, GetSAPPayorCategoryDiscountSetup, EmployerReducer,
    AppReferralReducer, AppYearMonthReducer, AppHospitalperformanceDocterDetailsListReducer, AppHospitalPerformanceDaslistListReducer, AppHospitalPerformanceREVlistReducer, AppHospitalperformancelistdetailsReducer, AppHospitalPerformanceREV_divlistReducer, AppHospitalperformancelistReducer, AppDepartmentNameReducer, AppMonthReducer, AppCurrencyReducer, AppCompanyBeneficiaryReducer, AppGetCompanyDataReducer,
    AppReportFormatOPReducer, AppReportFormatERReducer, AppReportFormatIPReducer, AppEmployeeReducer,
    AppFacilityReducer, AppFacilityEMPReducer, AppCompanyFacilityReducer, AppCompanySponsorReducer, AppPayorPlanReducer, fetchStatusByType,
    saveCompanyReducer, AppTariffReducer, AppCompanyTariffReducer, AppDiscountTemplateReducer, AppDepartmentReducer, AppDeptByServiceTypeReducer,
    AppDepartmentSubReducer, AppCompanyMarkupReducer, AppCompanyMarkupExlReducer, AppCompanyExclReducer, AppCompNonDiscReducer,
    AppPrintGroupReducer, AppServiceCategoryReducer, AppServiceMstReducer, AppServiceMstByServiceTypeReducer, AppCompBedCatReducer,
    AppServiceNomenclatureReducer, AppAreaPERReducer,
    AppServiceByFacilityReducer, GetReportMasterDataReducer, GetReportMasterParametersOPtionReducer,
    AppServiceLinkedReducer, AppServiceFacilityReducer, saveServiceMstReducer, AppLoginTariffReducer
    , AppTariffSaveReducer, AppSpecializationReducer, AppCompanyExlAutoReducer,
    AppExclusionMainReducer, AppExclusionDetailReducer, AppUserGroupReducer, AppUserSubGroupReducer,
    AppPackageByFacilityReducer, AppSUBServiceByFacilityReducer,
    AppOPPackageSaveReducer, AppOPPackageGetReducer, AppDepartmentSubByTypeReducer,
    AppSUBServiceByFacilityTNIReducer, AppServiceTariffReducer, AppServiceTariffChargeReducer, AppConsultationTariffChargeReducer
    , AppDesignationReducer, AppGradeReducer, AppServiceBedCategoryReducer, AppServiceTariffFactorReducer,
    AppServiceTariffFactorDtlReducer, BankListReducer, saveDiscountTemplateReducer, AppDiscountTempConfigReducer,
    AppfetchClinic, AppfetchPaymentMode, AppCompanywiseFacilityReducer, GetCompanyFacilityWiseReducer, AppGetMOUDetailsListReducer
    , AppGetCompanyItemExclusionListReducer, AppGetGroupExclusionListReducer, AppGetNonDiscountItemListReducer, AppGetNonDiscountGroupListReducer
    // ujjwal work start
    , AppBedTypeReducer, AppModuleReducer, AppFormReducer, AppSectionReducer, AppBEDCATMstReducer, AppERBEDCATMstReducer
    , AppSaveCompanExclusionListReducer
    , SaveCityMstReducer, SaveStateMstReducer, SaveCountryMstReducer, SaveRefTypeMstReducer
    , AppGetRolePageSectionMaster, AppGetRolePageMaster, AppGetEmployeePageSectionMaster, AppGetEmployeePageMaster, AppGetClinicForEmployee
    , AppGetDoctorVisitMaster, AppGetEmployeeFacility
    // ujjwal work end
    //rajesh work start
    , AppAgeGroupReducer, AppBlockReducer, AppFloorReducer, AppNursingUnitReducer
    , AppAnesthesiaTypeReducer, AppCaseTypeReducer, AppAdmissionTypeReducer, AppServiceByServiceCategoryReducer
    , AppRemarksMasterReducer, AppRoomMasterReducer, AppBedMasterReducer, AppBedOccupiReducer, GroupExclusion_SUCCESSReducer
    //rajesh work end
    , AppSaveDepartmentReducer, AppSaveDepartmentSubReducer, AppEmploymentTypeReducer,
    //  AppPaymentModeReducer,
    AppSaveRegistrationTypeReducer, AppSaveReligionReducer
    , AppsaveEducationReducer, AppsaveHWCTypeReducer, AppsaveLanguageReducer, AppsaveOccupationReducer, AppsaveReferralReducer, AppsaveAreaReducer, AppsaveDocumentTypeReducer
    , AppsaveNationalityReducer, AppsaveRelationReducer, AppsaveTitleReducer, AppsaveEmploymentTypeReducer
    , FromAppBedMasterReducer, ToAppBedMasterReducer, FromAppNursingUnitReducer, ToAppNursingUnitReducer, AppFacilitywiseEmployeeMaster
    , AppFacilitywiseRoleMaster, AppPageFuncionPermissionEmployee, AppPagePermissionMaster, AppPagePermissionMasterEmployee, AppsaveEmployeePermissions,
    AppPageFuncionPermission
    , AppSaveAgeGroupReducer, SaveAnesthesiaTypeReducer, SaveBlockReducer, SaveFloorReducer, SaveNursingUnitReducer, SaveRoomMasterReducer, SaveSAPPayorCategoryDiscountSetupReducer, SaveBedMasterReducer
    //Chandan
    , AppEquipmentListReducer, AppsaveEquipmentListReducer,
    //End Here
    //Start Amarjeet Code
    AppEmployeeCopyReducer
    //End Amarjeet Code
    , AppCompanyAllReducer
    , fetchReasonListReducer, fetchApprovalMatrixListReducer
    , AppsaveRolePermissionsReducer, BankMasterSaveReducer, fetchBankMasterListReducer, AppCardMasterListReducer, AppCardMasterSave
    , AppDesinationReducer
    , SaveApprovalMatrixReducer
    , ModulePageMenuListReducer
    , RolePermissionMasterReducer, AccessControlRoleReportReducer, GetEmployeeValidateLoginReducer, ModulePageButtonListReducer
    , AppPatientSeriesReducer
    , SavePatientSeriesReducer, saveCompanyServiceExclReducer
    , SaveDesigationMstReducer, SaveGradeMstReducer, SaveUserGropMstReducer, SaveUserSubGropMstReducer
    , GetAuditDataReducer, AppEnterpriseReducer,
    AppBuildingReducer, SaveModulePagePinnedReducer, BulkRegistrationReducer
    , AppEmployeeByTeamReducer, GetFieldModificationPermissionReducer
    , WeeklyOffListReducer, HolidayListReducer, HolidaySaveReducer, WeeklyOffSaveReducer
    , HolidayMarkupReducer, HolidayMarkupSaveReducer, getSurgeryComponentReducer, saveIpPackageReducer,
    saveIPPackageServiceInclusionReducer, saveIPPackageItemInclusionReducer, saveIPPackageExclusionsReducer, saveIPPackageBedChargeReducer, saveIPPackageSurgeryComponentReducer,
    GetIpPackageListReducer, GetIpPackageAllListReducer
    , AppsaveClinicReducer, getIpPackageBedChargeLimitReducer, getIpPackageSurgeryCompDataReducer, saveCopyFromExistingPackageReducer
    , SaveHCFReducer, SavePayorCategoryReducer, DispositionReducer, CurrEXRateReducer, savePayorPlanReducer, getMappedSurgeryComponentListReducer,
    getSurgeryCompSvcMappingDataReducer, saveSurgeryCompSvcMappingDataReducer
    , fetchSurgeryComponent, SaveSurgeryComponent
    , getSurgeryCompTatiffMappingReducer, saveSurgeryCompTariffMappingReducer, SaveServiceBedCategoryFactorReducer, AppsaveCopyRolePermissions
    , AppItemDiscountTempConfigReducer
    , ServiceChangeRuleFetchReducer
    , ServiceChangeRuleSaveReducer, userValidatePasswordResetReducer, userValidateOTPReducer, getUserDataForResetPasswordReducer
    , AppBedMasterByStatusTypeReducer
    , userPasswordResetSaveReducer
    , AssociateEmployeefetchReducer
    , AssociateEmployeeSaveReducer
    , changePasswordAfterLoginReducer
    , changePinAfterLoginReducer
    , savePaymentModeReducer, SaveCompanyPrintReducer, roleAccessReducer, getCheckListMasterReducer
    , AppErDashBoardReducer, SaveErDashBoardReducer
    , AppTriageReducer, SaveTriageReducer, getSearchEmployeeListReducer, saveCompanyChecklistReducer,
    saveIPPackagePayorReducer, CurrencyDenominationReducer, FreeServiceOrderReducer, fetchServiceLastOrderDtlReducer, SaveEREncounterReducer, fetchFormNamesReducer
    , AppEmployeeBySpecialityReducer, SaveChiefComplaintReducer, AppChiefComplaintReducer, AppDiagnoseMasterReducer,
    fetchStatusByTypeForGlobal, AppFacilityMasterListReducer, AppFacilitySaveReducer,
    SaveCampReducer, AppCampReducer, saveExclusionMainReducer, saveExclusionDetailReducer, AnatomicSiteMstReducer, SaveServiceTariffDetailReducer, saveServiceNomenclatureReducer, AppServiceTariffHistoryReducer
    , getMappedIPPackageComponentListReducer, getCompanyDisTemplateMappingReducer, GetPayorPackageDetailsMaster
    , SaveServiceBedCategoryFactorDtlReducer, fetchgetCompanyNomenclatureReducer, SaveBuildingReducer, CompanyTariffPlanReducer, AppTariffRuleReducer
    , AppConsultSetupSaveReducer, AppConsultSetupReducer, AppCompMOUDetailSaveReducer, AppDoctorDetailSaveReducer, AppDoctorDetailfetchReducer
    , AppServiceAttributeReducer, getSearchFacilityEmployeeListReducer, getSearchFacilityDocListReducer, getBedListReducer
    , AppEmpByTypeReducer, getSearchServiceMasterReducer, SaveCompanyExlAutoServiceReducer, TariffChargeAuditReducer, ServiceMstAuditReducer
    , reasonMasterReducer, SaveReasonMasterReducer, SavemoduleReasonReducer, GetModuleReasonReducer, GetModulePageEventsReducer, getPageListForValidationSetupReducer, getPageValidationSetupMasterReducer
    , getNursingDischargeOptionMasterReducer, AppCurrencyDenominationReducer, GetSurgeryGradeServiceReducer, SaveRevenueAndTargetReducer, SaveCurrencyDenominationReducer, SaveSurgeryGradeServiceReducer, AppGetModulePageFormTaggingReducer, SaveGetModulePageFormTaggingReducer, ModulePagesByModuleReducer, SaveModulePageEventReducer, getModulePageEventReducer
    , getReasonWiseModulePageEventReducer, AppBEDCATByEncReducer, SaveCompanyBedCatReducer
    , SaveCurrencyReducer, AppCurrencyexReducer, AppSaveServiceAttributeReducer, AppSponsorReducer, SaveFreeServiceOrderReducer, AppFreeServiceOrderReducer, CurrencyMasterReducer, SaveCurrencyMasterReducer
    , AppFIStatusReducer, AppFITCReducer, GetFormBuilderReducer, GetSMSConfigurationReducer, SaveSMSConfigurationReducer, GetSMSEventDetailReducer, GetSMSEventWithReceiverDetailReducer,
    GetReportConfigListReducer, GetEmailSetupReducer, AppMappedGradeReducer, ValidateExcelListBulkReducer, SaveExcelListBulkReducer, ReportRevenueDepartmentWiseReducer, MisInventoryAnalysisReportReducer,
    ValidateItemMasterExcelReducer, SaveItemMasterExcelReducer, GetScheduleData, AppCaseSubTypeReducer, get_emr_comp_Reducer, hpf_grw_sum_Reducer, AppDepartmentEmpReducer, Getchecklist_masterReducer, GetCourierListReducer, saveChecklistReducer, GetStockDashboardReducer, saveModuleFromUserReducer
    , AppItemSapIntegrationReducer, AppSAPItemPurchageGroupMasterReducer, GetVitalGroupReducer, AppDepartmentEmpResultReducer, GetSMSConfigParameterReducer, GetSMSConfigSubParameterReducer, Ins_Coll_ReportDBReducer, taxsetupListReducer, ReSyncdataListReducer, InsertInvoicedataListReducer, SettlementReSyncdataListReducer, InsertSettlementdataListReducer, ReceiptReSyncdataListReducer, RefundReSyncdataListReducer, InsertCollectiondataListReducer,
    AppHospitaldashboardDetailslistReducer, AppHospitaldashboardREVlistReducer, AppHospitaldashboardSCMlistReducer,
    CreditnoteReSyncdataListReducer, InsertCreditnotedataListReducer, InvoicewriteoffReSyncdataListReducer, InsertWriteoffdataListReducer, EncounterReSyncdataListReducer, InsertencounterdataListReducer, CxlinvoiceReSyncdataListReducer, InsertcxlinvoicedataListReducer, ClassifiedinvoiceReSyncdataListReducer, InsertclassifiedinvoicedataListReducer, tdsReSyncdataListReducer, InserttdsdataListReducer, ReportFacilityMappingReducer, SaveReportFacilityMappingReducer, validateSettlementDataReducer, getSettlementDataReducer, ExcleFileUploadActionReducer, BulkSettlementAdjustmentReducer, DownlodBulkEnvActionReducer
    , CategoryListReducer, AppInPatientReqCategoryReducer, serviceReSyncdataListReducer, InsertservicedataListReducer, UpdateReportMasterDataReducer, AppDoctorSlotTimingReducer, AppHospitaldashbaordsetuplistReducer, AppWebsiteServiceTariffChargeReducer, AppWebsiteConsultationTariffChargeReducer, SaveWebsiteServiceTariffDetailReducer, AppResourceDepReducer, AppResourceSubDepReducer, AppResourceMasterReducer, AppHospitalPerformanceSCM_divlistReducer, AppVulnerableTypeReducer, AppsaveVulnerableTypeReducer, saveCouponDtlReducer, AppCouponTemplateReducer, getUploadPrescriptionAuditReducer, getInpatientRequestAuditReducer, StandardCodesAuditReducer,
    ReSyncIntegrationdataListReducer, PaymentTransactionStatusReducer, GetBatchNoListReducer, ReceiptpayorReSyncdataListReducer, AppPinLabReducer, PaymentGatewayConfigurationReducer,FacilityPaymentGatewayConfigurationReducer
};
