
import {
  User_SAVE_FAIL, User_SAVE_REQUEST, User_SAVE_SUCCESS, User_SAVE_RESET, Country_List_REQUEST, Country_List_SUCCESS, Country_List_FAIL,
  Nationality_List_SUCCESS, Nationality_List_REQUEST, Nationality_List_FAIL, PARAMETEREMP_SETUP_REQUEST, PARAMETEREMP_SETUP_SUCCESS, PARAMETEREMP_SETUP_FAIL, State_List_REQUEST, State_List_SUCCESS, State_List_FAIL,
  City_List_REQUEST, City_List_SUCCESS, City_List_FAIL, Area_List_REQUEST, Area_List_SUCCESS, Area_List_FAIL, UserGroup_List_REQUEST,
  UserGroup_List_SUCCESS, UserGroup_List_FAIL, UserSubGroup_List_REQUEST, UserSubGroup_List_SUCCESS, UserSubGroup_List_FAIL, Titles_List_REQUEST,
  Titles_List_SUCCESS, Titles_List_FAIL, Department_List_REQUEST, Department_List_SUCCESS, Department_List_FAIL, EmploymentType_List_REQUEST,
  EmploymentType_List_SUCCESS, EmploymentType_List_FAIL, PrimarySpecility_List_REQUEST, PrimarySpecility_List_SUCCESS, PrimarySpecility_List_FAIL,
  SecondarySpecility_List_REQUEST, SecondarySpecility_List_SUCCESS, SecondarySpecility_List_FAIL, Role_List_REQUEST, Role_List_SUCCESS, Role_List_FAIL,
  PasswordPolicy_List_REQUEST, PasswordPolicy_List_SUCCESS, PasswordPolicy_List_FAIL, DefaultlandingPage_List_FAIL, DefaultlandingPage_List_SUCCESS,
  DefaultlandingPage_List_REQUEST, PasswordPolicy_SAVE_REQUEST, PasswordPolicy_SAVE_SUCCESS, PasswordPolicy_SAVE_FAIL, Role_SAVE_REQUEST,
  Role_SAVE_SUCCESS, Role_SAVE_FAIL, Delete_REQUEST, Delete_SUCCESS, Delete_Fail, List_REQUEST, List_SUCCESS, List_FAIL, Module_List_REQUEST,
  Module_List_SUCCESS, Module_List_FAIL, Facility_List_REQUEST, Facility_List_SUCCESS, Facility_List_FAIL, Service_List_REQUEST, Service_List_SUCCESS,
  Service_List_FAIL, IPVisit_List_REQUEST, IPVisit_List_SUCCESS, IPVisit_List_FAIL, ClientVisit_List_REQUEST, ClientVisit_List_SUCCESS,
  ClientVisit_List_FAIL, Section_LIST_REQUEST, Section_LIST_SUCCESS, Section_LIST_FAIL, Service_List_OP_REQUEST, Service_List_OP_FAIL, Service_List_OP_SUCCESS, Page_LIST_REQUEST,
  Page_LIST_SUCCESS, Page_LIST_FAIL, Button_LIST_REQUEST, Button_LIST_SUCCESS, Button_LIST_FAIL, EmploymentType_LIST_FAIL, SeachEmployee_LIST_REQUEST, SeachEmployee_LIST_SUCCESS, DoctorTeamsdetails_LIST_REQUEST, DoctorTeamsdetails_LIST_SUCCESS, DoctorTeamsdetails_LIST_FAIL,
  SeachEmployee_LIST_FAIL, Team_LIST_REQUEST, Team_LIST_SUCCESS, Team_LIST_FAIL, Document_UHID_FAIL, Document_UHID_REQUEST, Document_UHID_SUCCESS, SAVE_TEAM_REQUEST, SAVE_TEAM_SUCCESS, SAVE_TEAM_FAIL, SAVE_ROLE_REQUEST, SAVE_ROLE_SUCCESS, SAVE_ROLE_FAIL, SAVE_OTHERPermission_REQUEST, SAVE_OTHERPermission_SUCCESS, SAVE_OTHERPermission_FAIL, SAVE_EmployeeBenifits_REQUEST, SAVE_EmployeeBenifits_SUCCESS, SAVE_EmployeeBenifits_FAIL,
   GradeId_LIST_REQUEST, GradeId_LIST_SUCCESS, GradeId_LIST_FAIL, Designation_SAVE_REQUEST, Designation_SAVE_SUCCESS, Designation_SAVE_FAIL,
  EMPLOYEE_FACILITY_MAPPING_SAVE_REQUEST,
  EMPLOYEE_FACILITY_MAPPING_SAVE_SUCCESS, DEMPLOYEE_FACILITY_MAPPING_SAVE_FAIL,
  RoleWithEmployee_List_REQUEST, RoleWithEmployee_List_SUCCESS, RoleWithEmployee_List_FAIL,
  User_Doctor_Permission_SAVE_REQUEST, User_Doctor_Permission_SUCCESS, User_Doctor_Permission_FAIL,
  User_Doctor_Visit_SAVE_REQUEST, User_Doctor_Visit_SUCCESS, User_Doctor_Visit_FAIL,
  RoleMapedWithEmp_LIST_REQUEST, RoleMapedWithEmp_LIST_SUCCESS, RoleMapedWithEmp_LIST_FAIL,
  User_SAVE_Emp_Img_REQUEST,
  User_SAVE_Emp_Img_SUCCESS,
  User_SAVE_Emp_Img_FAIL,
  User_SAVE_Emp_Sign_REQUEST,
  User_SAVE_Emp_Sign_SUCCESS,
  User_SAVE_Emp_Sign_FAIL,
  Role_TagFromEmployee_List_REQUEST,
  Role_TagFromEmployee_List_SUCCESS,
  Role_TagFromEmployee_List_FAIL,
  SEARCH_EMPLOYEE_DATA_SAVE_REQUEST,
  SEARCH_EMPLOYEE_DATA_SUCCESS,
  SEARCH_EMPLOYEE_DATA_FAIL,
  CheckMobileEmailIdExistOrNot_LIST_REQUEST,
  CheckMobileEmailIdExistOrNot_LIST_SUCCESS,
  CheckMobileEmailIdExistOrNot_LIST_FAIL, Department_Master_REQUEST, Department_Master_FAIL, Department_Master_SUCCESS,
  GetEmployeeAudit_LIST_REQUEST,
  GetEmployeeAudit_LIST_SUCCESS,
  GetEmployeeAudit_LIST_FAIL
} from '../Constants/UserConstants';


import * as UserCons from '../Constants/UserConstants';

const intialstate = {};




function GetEmployeePageEncounterPermissionsAuditReducer(state = {
  EncounterPermissionsAuditData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case UserCons.GetEmployeePageEncounterPermissionsAudit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case UserCons.GetEmployeePageEncounterPermissionsAudit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case UserCons.GetEmployeePageEncounterPermissionsAudit_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        EncounterPermissionsAuditData: action.payload
      }

    }
    default: return state;
  }

}



function GetLinkServiceMstAuditReducer(state = {
  LinkServiceMstAuditData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case UserCons.GetServiceAttribute_Audit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case UserCons.GetServiceAttribute_Audit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case UserCons.GetServiceAttribute_Audit_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        LinkServiceMstAuditData: action.payload
      }

    }
    default: return state;
  }

}

//add ind DiscountConfi
function GetDiscountConfigurationAuditReducer(state = {
  DiscountConfigurationAuditData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case UserCons.GetDiscountConfigurationt_Audit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case UserCons.GetDiscountConfiguration_Audit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case UserCons.GetDiscountConfiguration_Audit_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        DiscountConfigurationAuditData: action.payload
      }

    }
    default: return state;
  }

}


//add ind DiscountTemaplateAudit
function GetDiscountConfigurationTemplateAuditReducer(state = {
  DiscountConfigurationTemplateAuditData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case UserCons.GetDiscountConfigurationTemplate_Audit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case UserCons.GetDiscountConfigurationTemplate_Audit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case UserCons.GetDiscountConfigurationTemplate_Audit_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        DiscountConfigurationTemplateAuditData: action.payload
      }

    }
    default: return state;
  }

}



function GetFacilityMappingAuditReducer(state = {
  FacilityMappingAuditData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case UserCons.GetFacilityMappingAudit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case UserCons.GetFacilityMappingAudit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case UserCons.GetFacilityMappingAudit_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        FacilityMappingAuditData: action.payload
      }

    }
    default: return state;
  }

}


function GetEmployeeAuditReducer(state = {
  EmployeeAuditData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case GetEmployeeAudit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case GetEmployeeAudit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case GetEmployeeAudit_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        EmployeeAuditData: action.payload
      }

    }
    default: return state;
  }

}


function GetControlledPriceReducer(state = {
  ControlledPriceAuditData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case UserCons.GetControlledPriceAudit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case UserCons.GetServiceAttribute_Audit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case UserCons.GetServiceAttribute_Audit_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        TeamAuditData: action.payload
      }

    }
    default: return state;
  }

}


function GetEquipmentChargeSetupAuditReducer(state = {
  GetEquipmentChargeSetupAuditData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case UserCons.GetEquipmentChargeSetupAudit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case UserCons.GetEquipmentChargeSetupAudit_LIST_SUCCESS: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case UserCons.GetEquipmentChargeSetupAudit_LIST_FAIL: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        TeamAuditData: action.payload
      }

    }
    default: return state;
  }

}



function GetTeamAuditReducer(state = {
  TeamAuditData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case UserCons.GetTeamAudit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case UserCons.GetTeamAudit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case UserCons.GetTeamAudit_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        TeamAuditData: action.payload
      }

    }
    default: return state;
  }

}


function UHIDReducerU(state = {}, action) {
  switch (action.type) {
    case Document_UHID_REQUEST:
      return { loading: true };
    case Document_UHID_SUCCESS:
      return { loading: false, USERUHID: action.payload };
    case Document_UHID_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    // case USER_LOGOUT:
    //   return {};
    default: return state;
  }
}

function UserSaveReducer(state = { product: {} }, action) {
  switch (action.type) {
    case User_SAVE_REQUEST:
      return { loading: true };
    case User_SAVE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case User_SAVE_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function SaveEmpImageReducer(state = { productImg: {} }, action) {
  switch (action.type) {
    case User_SAVE_Emp_Img_REQUEST:
      return { loading: true };
    case User_SAVE_Emp_Img_SUCCESS:
      return { loading: false, success: true, productImg: action.payload };
    case User_SAVE_Emp_Img_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function SaveEmpSignatureReducer(state = { productSign: {} }, action) {
  switch (action.type) {
    case User_SAVE_Emp_Sign_REQUEST:
      return { loading: true };
    case User_SAVE_Emp_Sign_SUCCESS:
      return { loading: false, success: true, productSign: action.payload };
    case User_SAVE_Emp_Sign_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}
function TeamSaveReducer(state = { TeamSavedData: {} }, action) {
  switch (action.type) {
    case SAVE_TEAM_REQUEST:
      return { loading: true };
    case SAVE_TEAM_SUCCESS:
      return { loading: false, success: true, TeamSavedData: action.payload };
    case SAVE_TEAM_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function RolePermissionSaveReducer(state = { RoleSaveData: {} }, action) {
  switch (action.type) {
    case SAVE_ROLE_REQUEST:
      return { loading: true };
    case SAVE_ROLE_SUCCESS:
      return { loading: false, success: true, RoleSaveData: action.payload };
    case SAVE_ROLE_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function OtherPermissionSaveReducer(state = { OtherPSaveData: {} }, action) {
  switch (action.type) {
    case SAVE_OTHERPermission_REQUEST:
      return { loading: true };
    case SAVE_OTHERPermission_SUCCESS:
      return { loading: false, success: true, OtherPSaveData: action.payload };
    case SAVE_OTHERPermission_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function EmployeeBenifitsSaveReducer(state = { EmployeeBenifitsSaveData: {} }, action) {
  switch (action.type) {
    case SAVE_EmployeeBenifits_REQUEST:
      return { loading: true };
    case SAVE_EmployeeBenifits_SUCCESS:
      return { loading: false, success: true, EmployeeBenifitsSaveData: action.payload };
    case SAVE_EmployeeBenifits_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}



function EmployeeFacilityMappingSaveReducer(state = { EmployeeFacilityMappingSaveData: {} }, action) {
  switch (action.type) {
    case EMPLOYEE_FACILITY_MAPPING_SAVE_REQUEST:
      return { loading: true };
    case EMPLOYEE_FACILITY_MAPPING_SAVE_SUCCESS:
      return { loading: false, success: true, EmployeeFacilityMappingSaveData: action.payload };
    case DEMPLOYEE_FACILITY_MAPPING_SAVE_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function CountryListReducer(state = { Country: [] }, action) {

  switch (action.type) {
    case Country_List_REQUEST:
      return { loading: true, Country: [] };
    case Country_List_SUCCESS:
      return { loading: false, Country: action.payload };
    case Country_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function GradeListReducer(state = { Grade: [] }, action) {

  switch (action.type) {
    case GradeId_LIST_REQUEST:
      return { loading: true, Grade: [] };
    case GradeId_LIST_SUCCESS:
      return { loading: false, Grade: action.payload };
    case GradeId_LIST_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function RoleMapedWithEmployeListReducer(state = { RoleMapedWithEmployeList: [] }, action) {

  switch (action.type) {
    case RoleMapedWithEmp_LIST_REQUEST:
      return { loading: true, RoleMapedWithEmployeList: [] };
    case RoleMapedWithEmp_LIST_SUCCESS:
      return { loading: false, RoleMapedWithEmployeList: action.payload };
    case RoleMapedWithEmp_LIST_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function DesignationListReducer(state = { Designation: [] }, action) {

  switch (action.type) {
    case Designation_SAVE_REQUEST:
      return { loading: true, Designation: [] };
    case Designation_SAVE_SUCCESS:
      return { loading: false, Designation: action.payload };
    case Designation_SAVE_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}


function NationalityListReducer(state = { Nationality: [] }, action) {

  switch (action.type) {
    case Nationality_List_REQUEST:
      return { loading: true, Nationality: [] };
    case Nationality_List_SUCCESS:
      return { loading: false, Nationality: action.payload };
    case Nationality_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}
//Parameter setup details reducer
function ParameterEMPSetupDetailsReducer(state = {
  ParameterEMPSetupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case PARAMETEREMP_SETUP_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case PARAMETEREMP_SETUP_FAIL: {
      return { ...state, fetching: false, error: action.payload, fetched: true }
    }
    case PARAMETEREMP_SETUP_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ParameterEMPSetupData: action.payload
      }

    }
    default: return state;
  }

}

function StateListReducer(state = { State: [] }, action) {

  switch (action.type) {
    case State_List_REQUEST:
      return { loading: true, State: [] };
    case State_List_SUCCESS:
      return { loading: false, State: action.payload };
    case State_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function CityListReducer(state = { City: [] }, action) {

  switch (action.type) {
    case City_List_REQUEST:
      return { loading: true, City: [] };
    case City_List_SUCCESS:
      return { loading: false, City: action.payload };
    case City_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function AreaListReducer(state = { Area: [] }, action) {

  switch (action.type) {
    case Area_List_REQUEST:
      return { loading: true, Area: [] };
    case Area_List_SUCCESS:
      return { loading: false, Area: action.payload };
    case Area_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function UserGroupListReducer(state = { UserGroup: [] }, action) {

  switch (action.type) {
    case UserGroup_List_REQUEST:
      return { loading: true, UserGroup: [] };
    case UserGroup_List_SUCCESS:
      return { loading: false, UserGroup: action.payload };
    case UserGroup_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function UserSubGroupListReducer(state = { UserSubGroup: [] }, action) {

  switch (action.type) {
    case UserSubGroup_List_REQUEST:
      return { loading: true, UserSubGroup: [] };
    case UserSubGroup_List_SUCCESS:
      return { loading: false, UserSubGroup: action.payload };
    case UserSubGroup_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function TitlesListReducer(state = { Titles: [] }, action) {

  switch (action.type) {
    case Titles_List_REQUEST:
      return { loading: true, Titles: [] };
    case Titles_List_SUCCESS:
      return { loading: false, Titles: action.payload };
    case Titles_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function DepartmentListReducer(state = { Department: [] }, action) {

  switch (action.type) {
    case Department_List_REQUEST:
      return { loading: true, Department: [] };
    case Department_List_SUCCESS:
      return { loading: false, Department: action.payload };
    case Department_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}



function FacilitysListReducer(state = { Facilityes: [] }, action) {

  switch (action.type) {
    case Facility_List_REQUEST:
      return { loading: true, Facilityes: [] };
    case Facility_List_SUCCESS:
      return { loading: false, Facilityes: action.payload };
    case Facility_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function EmploymentTypeReducer(state = { EmploymentType: [] }, action) {

  switch (action.type) {
    case EmploymentType_List_REQUEST:
      return { loading: true, EmploymentType: [] };
    case EmploymentType_List_SUCCESS:
      return { loading: false, EmploymentType: action.payload };
    case EmploymentType_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function PrimarySpecilityReducer(state = { PrimarySpecility: [] }, action) {

  switch (action.type) {
    case PrimarySpecility_List_REQUEST:
      return { loading: true, PrimarySpecility: [] };
    case PrimarySpecility_List_SUCCESS:
      return { loading: false, PrimarySpecility: action.payload };
    case PrimarySpecility_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function SecondarySpecilityReducer(state = { SecondarySpecility: [] }, action) {

  switch (action.type) {
    case SecondarySpecility_List_REQUEST:
      return { loading: true, SecondarySpecility: [] };
    case SecondarySpecility_List_SUCCESS:
      return { loading: false, SecondarySpecility: action.payload };
    case SecondarySpecility_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function RoleReducer(state = { Role: [] }, action) {

  switch (action.type) {
    case Role_List_REQUEST:
      return { loading: true, Role: [] };
    case Role_List_SUCCESS:
      return { loading: false, Role: action.payload };
    case Role_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}



function GetRoleToTagFromEmployeeReducer(state = { TagRole: [] }, action) {

  switch (action.type) {
    case Role_TagFromEmployee_List_REQUEST:
      return { loading: true, TagRole: [] };
    case Role_TagFromEmployee_List_SUCCESS:
      return { loading: false, TagRole: action.payload };
    case Role_TagFromEmployee_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}


function PasswordPolicyReducer(state = { PasswordPloicy: [] }, action) {

  switch (action.type) {
    case PasswordPolicy_List_REQUEST:
      return { loading: true, PasswordPloicy: [] };
    case PasswordPolicy_List_SUCCESS:
      return { loading: false, PasswordPloicy: action.payload };
    case PasswordPolicy_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}


function PasswordPolicyDeleteReducer(state = {}, action) {
  switch (action.type) {
    case Delete_REQUEST:
      return { loading: true };
    case Delete_SUCCESS:
      return { loading: false, DocumentDataLI: action.payload };
    case Delete_Fail:
      return { loading: false, error: action.payload, fetched: true };
    // case USER_LOGOUT:
    //   return {};
    default: return state;
  }
}

function DefaultLandingPageReducer(state = { LandingPage: [] }, action) {

  switch (action.type) {
    case DefaultlandingPage_List_REQUEST:
      return { loading: true, LandingPage: [] };
    case DefaultlandingPage_List_SUCCESS:
      return { loading: false, LandingPage: action.payload };
    case DefaultlandingPage_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function PasswordPolicySaveReducer(state = { product: {} }, action) {
  switch (action.type) {
    case PasswordPolicy_SAVE_REQUEST:
      return { loading: true };
    case PasswordPolicy_SAVE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PasswordPolicy_SAVE_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function RoleSaveReducer(state = { product: {} }, action) {
  switch (action.type) {
    case Role_SAVE_REQUEST:
      return { loading: true };
    case Role_SAVE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case Role_SAVE_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function ClientVisitReducer(state = { ClientVisit: [] }, action) {

  switch (action.type) {
    case ClientVisit_List_REQUEST:
      return { loading: true, ClientVisit: [] };
    case ClientVisit_List_SUCCESS:
      return { loading: false, ClientVisit: action.payload };
    case ClientVisit_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function IPVisitReducer(state = { IPVisit: [] }, action) {

  switch (action.type) {
    case IPVisit_List_REQUEST:
      return { loading: true, IPVisit: [] };
    case IPVisit_List_SUCCESS:
      return { loading: false, IPVisit: action.payload };
    case IPVisit_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function ServiceReducer(state = { Service: [] }, action) {

  switch (action.type) {
    case Service_List_REQUEST:
      return { loading: true, Service: [] };
    case Service_List_SUCCESS:
      return { loading: false, Service: action.payload };
    case Service_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function ServiceOPReducer(state = { ServiceOPL: [] }, action) {

  switch (action.type) {
    case Service_List_OP_REQUEST:
      return { loading: true, ServiceOPL: [] };
    case Service_List_OP_SUCCESS:
      return { loading: false, ServiceOPL: action.payload };
    case Service_List_OP_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function MOduleReducer(state = { Module: [] }, action) {

  switch (action.type) {
    case Module_List_REQUEST:
      return { loading: true, Module: [] };
    case Module_List_SUCCESS:
      return { loading: false, Module: action.payload };
    case Module_List_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function PageReducer(state = { Page: [] }, action) {

  switch (action.type) {
    case Page_LIST_REQUEST:
      return { loading: true, Page: [] };
    case Page_LIST_SUCCESS:
      return { loading: false, Page: action.payload };
    case Page_LIST_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}


function SectionReducer(state = { Section: [] }, action) {

  switch (action.type) {
    case Section_LIST_REQUEST:
      return { loading: true, Section: [] };
    case Section_LIST_SUCCESS:
      return { loading: false, Section: action.payload };
    case Section_LIST_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function ButtonReducer(state = { Button: [] }, action) {

  switch (action.type) {
    case Button_LIST_REQUEST:
      return { loading: true, Button: [] };
    case Button_LIST_SUCCESS:
      return { loading: false, Button: action.payload };
    case Button_LIST_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}


function TeamReducer(state = { Teams: [] }, action) {

  switch (action.type) {
    case Team_LIST_REQUEST:
      return { loading: true, Teams: [] };
    case Team_LIST_SUCCESS:
      return { loading: false, Teams: action.payload };
    case Team_LIST_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}


function SearchEmployeeReducer(state = { EmployeeOList: [] }, action) {

  switch (action.type) {
    case SeachEmployee_LIST_REQUEST:
      return { loading: true, success: false, EmployeeOList: [] };
    case SeachEmployee_LIST_SUCCESS:
      return { loading: false, success: true, EmployeeOList: action.payload };
    case SeachEmployee_LIST_FAIL:
      return { loading: false, success: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function DoctorTeamsdetailsReducer(state = { DoctorTeamsList: [] }, action) {

  switch (action.type) {
    case DoctorTeamsdetails_LIST_REQUEST:
      return { loading: true, success: false, DoctorTeamsList: [] };
    case DoctorTeamsdetails_LIST_SUCCESS:
      return { loading: false, success: true, DoctorTeamsList: action.payload };
    case DoctorTeamsdetails_LIST_FAIL:
      return { loading: false, success: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

////////////Satart dhiraj
function RoleMasterReducer(state = {
  RoleMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case Role_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case Role_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case Role_List_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        RoleMasterData: action.payload
      }
    }
    default: return state;
  }

}

function RoleMasterSaveReducer(state = {
  RoleSaveData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case Role_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case Role_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case Role_SAVE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        RoleSaveData: action.payload
      }

    }
    default: return state;
  }

}

function FacilityMasterReducer(state = {
  FacilityMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case Facility_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case Facility_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case Facility_List_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        FacilityMasterData: action.payload
      }
    }
    default: return state;
  }

}

function DepartmentMasterReducer(state = {
  DepartmentMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case Department_Master_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case Department_Master_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case Department_Master_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        DepartmentMasterData: action.payload
      }
    }
    default: return state;
  }

}

function RoleWithEmployeeReducer(state = {
  RoleWithEmployee: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case RoleWithEmployee_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case RoleWithEmployee_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case RoleWithEmployee_List_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        RoleWithEmployee: action.payload
      }
    }
    default: return state;
  }

}
function PasswordPolicyMasterReducer(state = {
  PasswordPolicyData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case PasswordPolicy_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case PasswordPolicy_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case PasswordPolicy_List_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        PasswordPolicyData: action.payload
      }
    }
    default: return state;
  }

}

function savePasswordPolicyReducer(state = {
  SavePasswordPolicyData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case PasswordPolicy_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case PasswordPolicy_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case PasswordPolicy_SAVE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SavePasswordPolicyData: action.payload
      }

    }
    default: return state;
  }

}

/////////////End Dhiraj

function PasswordPolicyClassReducer(state = {
  PasswordPloicy: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case PasswordPolicy_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case PasswordPolicy_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
    case PasswordPolicy_List_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        PasswordPloicy: action.payload
      }

    }
    default: return state;
  }

}

////////////Amarjeet////
function SaveDoctorPermissionReducer(state = { product: {} }, action) {
  switch (action.type) {
    case User_Doctor_Permission_SAVE_REQUEST:
      return { loading: true };
    case User_Doctor_Permission_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case User_Doctor_Permission_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}

function SaveDoctorVisitReducer(state = { DoctorVisitDataSave: {} }, action) {
  switch (action.type) {
    case User_Doctor_Visit_SAVE_REQUEST:
      return { loading: true };
    case User_Doctor_Visit_SUCCESS:
      return { loading: false, success: true, DoctorVisitDataSave: action.payload };
    case User_Doctor_Visit_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}


function SearchEmployeeDataReducer(state = { EmployeeData: {} }, action) {
  switch (action.type) {
    case SEARCH_EMPLOYEE_DATA_SAVE_REQUEST:
      return { loading: true, success: false };
    case SEARCH_EMPLOYEE_DATA_SUCCESS:
      return { loading: false, success: true, EmployeeData: action.payload };
    case SEARCH_EMPLOYEE_DATA_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}
////////////Amarjeet///


//////////// Amarjeet //////////


function CheckMobileEmailIdExistOrNotReducer(state = { CheckMobileEmailIdExistOrNotData: [] }, action) {

  switch (action.type) {
    case CheckMobileEmailIdExistOrNot_LIST_REQUEST:
      return { loading: true, CheckMobileEmailIdExistOrNotData: [] };
    case CheckMobileEmailIdExistOrNot_LIST_SUCCESS:
      return { loading: false, CheckMobileEmailIdExistOrNotData: action.payload };
    case CheckMobileEmailIdExistOrNot_LIST_FAIL:
      return { loading: false, error: action.payload, fetched: true };
    default:
      return state;
  }
}


/////////// Amarjeet //////////


export {
  GetEmployeePageEncounterPermissionsAuditReducer,
  GetEquipmentChargeSetupAuditReducer,
  GetTeamAuditReducer,
  GetControlledPriceReducer,
  GetFacilityMappingAuditReducer,
  GetEmployeeAuditReducer,
  UserSaveReducer,
  CountryListReducer,
  NationalityListReducer,
  StateListReducer,
  CityListReducer,
  AreaListReducer,
  UserGroupListReducer,
  UserSubGroupListReducer,
  TitlesListReducer,
  DepartmentListReducer,
  FacilitysListReducer,
  EmploymentTypeReducer,
  PrimarySpecilityReducer,
  SecondarySpecilityReducer,
  RoleReducer,
  PasswordPolicyReducer,
  DefaultLandingPageReducer,
  PasswordPolicySaveReducer,
  PasswordPolicyDeleteReducer,
  RoleSaveReducer,
  ClientVisitReducer,
  IPVisitReducer,
  ServiceReducer,
  MOduleReducer,
  SectionReducer,
  ServiceOPReducer,
  SearchEmployeeReducer,
  DoctorTeamsdetailsReducer,
  PageReducer,
  ButtonReducer,
  TeamReducer,

  UHIDReducerU,

  TeamSaveReducer,
  RolePermissionSaveReducer,
  OtherPermissionSaveReducer,
  EmployeeBenifitsSaveReducer,
  ParameterEMPSetupDetailsReducer,
  GradeListReducer,
  DesignationListReducer,
  EmployeeFacilityMappingSaveReducer,
  RoleMasterReducer, FacilityMasterReducer, RoleMasterSaveReducer, DepartmentMasterReducer, RoleWithEmployeeReducer, PasswordPolicyMasterReducer, savePasswordPolicyReducer,
  SaveDoctorPermissionReducer,
  SaveDoctorVisitReducer,
  RoleMapedWithEmployeListReducer,
  SaveEmpImageReducer,
  SaveEmpSignatureReducer,
  GetRoleToTagFromEmployeeReducer,
  SearchEmployeeDataReducer,
  CheckMobileEmailIdExistOrNotReducer,
  PasswordPolicyClassReducer,GetLinkServiceMstAuditReducer,
  GetDiscountConfigurationAuditReducer,
  GetDiscountConfigurationTemplateAuditReducer
};