import * as AMCnst from '../Constants/formConstant';

const intialstate = {};


function fetchFormMasterReducer(state = {
    FormMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FORMMASTER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FORMMASTER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FORMMASTER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FormMasterData: action.payload
            }

        }
        default: return state;
    }

}

function fetchFormGroupMasterReducer(state = {
    FormGroupMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FORMGROUP_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FORMGROUP_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FORMGROUP_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FormGroupMasterData: action.payload
            }

        }
        default: return state;
    }

}
function SaveFormsubCategoryMasterReducer(state = {
    SaveFormSubCategoryMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FORMSUBCATEGORY_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FORMSUBCATEGORY_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FORMSUBCATEGORY_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveFormSubCategoryMasterData: action.payload
            }

        }
        default: return state;
    }

}


function fetchFormsubCategoryMasterReducer(state = {
    FormSubCategoryMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FORMSUBCATEGORY_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FORMSUBCATEGORY_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FORMSUBCATEGORY_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FormSubCategoryMasterData: action.payload
            }

        }
        default: return state;
    }

}

function SaveFormGroupMasterReducer(state = {
    FormGroupMasterDataSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FORMGROUP_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FORMGROUP_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FORMGROUP_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FormGroupMasterDataSave: action.payload
            }

        }
        default: return state;
    }

}
function SaveFormMasterReducer(state = {
    FormMasterDataSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FORMMASTER_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FORMMASTER_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FORMMASTER_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FormMasterDataSave: action.payload
            }

        }
        default: return state;
    }

}
function AppComponentMasterReducer(state = {
    GetComponentMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ComponentMasters_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ComponentMasters_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ComponentMasters_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetComponentMasterData: action.payload
            }
        }
        default: return state;
    }
}



function SaveComponentMasterReducer(state = {
    SaveComponentMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ComponentMasters_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ComponentMasters_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ComponentMasters_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveComponentMasterData: action.payload
            }
        }
        default: return state;
    }
}

function SaveFormsParameter(state = {
    FormsParameterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {

        case AMCnst.FORMPARAMTER_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FORMPARAMTER_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FORMPARAMTER_SAVE_SUCCESS:
            console.log('reducesr')
            console.log(action.payload)

            {

                return {
                    ...state,
                    fetching: false,
                    fetched: true,
                    FormsParameterData: action.payload
                }

            }
        default: return state;
    }
}


function fetchFormsParameter(state = {
    GetFormsParameterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FORMPARAMTER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FORMPARAMTER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FORMPARAMTER_LIST_SUCCESS: {


            return {
                ...state,
                fetching: false,
                fetched: true,
                GetFormsParameterData: action.payload
            }

        }
        default: return state;

    }
}
function AppListComponentReducer(state = {
    GetListComponentData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ListComponent_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ListComponent_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ListComponent_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetListComponentData: action.payload
            }
        }
        default: return state;
    }
}
function AppViewImageforImageComponentReducer(state = {
    ViewImageData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ViewImage_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ViewImage_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ViewImage_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ViewImageData: action.payload
            }
        }
        default: return state;
    }
}
function GetfrmDataObjectListReducer(state = {
    frmDataObjectList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.frmDataObjectList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.frmDataObjectList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.frmDataObjectList_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                frmDataObjectList: action.payload
            }
        }
        default: return state;
    }
}
function GetdataObjectClinicalOtherSetup(state = {
    ClinicalOtherSetup: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.dataObjectClinicalOtherSetup_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.dataObjectClinicalOtherSetup_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.dataObjectClinicalOtherSetup_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ClinicalOtherSetup: action.payload
            }
        }
        default: return state;
    }
}
export {
    fetchFormGroupMasterReducer, SaveFormGroupMasterReducer, fetchFormsubCategoryMasterReducer, SaveFormMasterReducer,
    fetchFormMasterReducer, SaveFormsubCategoryMasterReducer, SaveComponentMasterReducer, AppComponentMasterReducer,
    fetchFormsParameter, SaveFormsParameter, AppListComponentReducer, AppViewImageforImageComponentReducer, GetfrmDataObjectListReducer,GetdataObjectClinicalOtherSetup

}