
export const Hospitalperformance_LIST_REQUEST = 'Hospitalperformance_LIST_REQUEST';
export const Hospitalperformance_LIST_SUCCESS = 'Hospitalperformance_LIST_SUCCESS';
export const Hospitalperformance_LIST_FAIL = 'Hospitalperformance_LIST_FAIL';

export const HospitalperformanceDocterDetailsList_LIST_REQUEST = 'HospitalperformanceDocterDetailsList_LIST_REQUEST';
export const HospitalperformanceDocterDetailsList_LIST_SUCCESS = 'HospitalperformanceDocterDetailsList_LIST_SUCCESS';
export const HospitalperformanceDocterDetailsList_LIST_FAIL = 'HospitalperformanceDocterDetailsList_LIST_FAIL';

export const HospitalPerformanceDaslist_LIST_REQUEST = 'HospitalPerformanceDaslist_LIST_REQUEST';
export const HospitalPerformanceDaslist_LIST_SUCCESS = 'HospitalPerformanceDaslist_LIST_SUCCESS';
export const HospitalPerformanceDaslist_LIST_FAIL = 'HospitalPerformanceDaslist_LIST_FAIL';

export const HospitalPerformanceREVlist_LIST_REQUEST = 'HospitalPerformanceREVlist_LIST_REQUEST';
export const HospitalPerformanceREVlist_LIST_SUCCESS = 'HospitalPerformanceREVlist_LIST_SUCCESS';
export const HospitalPerformanceREVlist_LIST_FAIL = 'HospitalPerformanceREVlist_LIST_FAIL';

export const HospitalPerformanceREV_divlist_LIST_REQUEST = 'HospitalPerformanceREV_divlist_LIST_REQUEST';
export const HospitalPerformanceREV_divlist_LIST_SUCCESS = 'HospitalPerformanceREV_divlist_LIST_SUCCESS';
export const HospitalPerformanceREV_divlist_LIST_FAIL = 'HospitalPerformanceREV_divlist_LIST_FAIL';

export const HospitalPerformanceSCM_divlist_LIST_REQUEST = 'HospitalPerformanceSCM_divlist_LIST_REQUEST';
export const HospitalPerformanceSCM_divlist_LIST_SUCCESS = 'HospitalPerformanceSCM_divlist_LIST_SUCCESS';
export const HospitalPerformanceSCM_divlist_LIST_FAIL = 'HospitalPerformanceSCM_divlist_LIST_FAIL';

export const HospitaldashboardSCM_LIST_REQUEST = 'HospitaldashboardSCM_LIST_REQUEST';
export const HospitaldashboardSCM_LIST_SUCCESS = 'HospitaldashboardSCM_LIST_SUCCESS';
export const HospitaldashboardSCM_LIST_FAIL = 'HospitaldashboardSCM_LIST_FAIL';

export const HospitaldashboardDetails_LIST_REQUEST = 'HospitaldashboardDetails_LIST_REQUEST';
export const HospitaldashboardDetails_LIST_SUCCESS = 'HospitaldashboardDetails_LIST_SUCCESS';
export const HospitaldashboardDetails_LIST_FAIL = 'HospitaldashboardDetails_LIST_FAIL';

export const HospitaldashboardREV_LIST_REQUEST = 'HospitaldashboardREV_LIST_REQUEST';
export const HospitaldashboardREV_LIST_SUCCESS = 'HospitaldashboardREV_LIST_SUCCESS';
export const HospitaldashboardREV_LIST_FAIL = 'HospitaldashboardREV_LIST_FAIL';

export const HospitalperformanceDetails_LIST_REQUEST = 'HospitalperformanceDetails_LIST_REQUEST';
export const HospitalperformanceDetails_LIST_SUCCESS = 'HospitalperformanceDetails_LIST_SUCCESS';
export const HospitalperformanceDetails_LIST_FAIL = 'HospitalperformanceDetails_LIST_FAIL';

export const Hospitaldashbaordsetup_LIST_REQUEST = 'Hospitaldashbaordsetup_LIST_REQUEST';
export const Hospitaldashbaordsetup_LIST_SUCCESS = 'Hospitaldashbaordsetup_LIST_SUCCESS';
export const Hospitaldashbaordsetup_LIST_FAIL = 'Hospitaldashbaordsetup_LIST_FAIL';

export const hpf_grw_sum_LIST_REQUEST = 'hpf_grw_sum_LIST_REQUEST';
export const hpf_grw_sum_LIST_SUCCESS = 'hpf_grw_sum__LIST_SUCCESS';
export const hpf_grw_sum_LIST_FAIL = 'hpf_grw_sum_LIST_FAIL';

export const ReportRevenueDepartmentWise_LIST_REQUEST = 'ReportRevenueDepartmentWise_LIST_REQUEST';
export const ReportRevenueDepartmentWise_LIST_SUCCESS = 'ReportRevenueDepartmentWise_LIST_SUCCESS';
export const ReportRevenueDepartmentWise_LIST_FAIL = 'ReportRevenueDepartmentWise_LIST_FAIL';

export const GetStockDashboard_List_REQUEST = 'GetStockDashboard_List_REQUEST';
export const GetStockDashboard_List_SUCCESS = 'GetStockDashboard_List_SUCCESS';
export const GetStockDashboard_List_FAIL = 'GetStockDashboard_List_FAIL';