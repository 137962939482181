import {
    OPERATING_ROOM_LIST_REQUEST, OPERATING_ROOM_LIST_FAIL, OPERATING_ROOM_LIST_SUCCESS,
    OPERATING_ROOM_SAVE_REQUEST, OPERATING_ROOM_SAVE_FAIL, OPERATING_ROOM_SAVE_SUCCESS,
    PATIENT_POSITION_LIST_REQUEST, PATIENT_POSITION_LIST_FAIL, PATIENT_POSITION_LIST_SUCCESS,
    PATIENT_POSITION_SAVE_REQUEST, PATIENT_POSITION_SAVE_FAIL, PATIENT_POSITION_SAVE_SUCCESS,
    SURGERY_CATEGORY_LIST_REQUEST, SURGERY_CATEGORY_LIST_FAIL, SURGERY_CATEGORY_LIST_SUCCESS,
    SURGERY_CATEGORY_SAVE_REQUEST, SURGERY_CATEGORY_SAVE_FAIL, SURGERY_CATEGORY_SAVE_SUCCESS,
    AnesthesiaType_LIST_REQUEST, AnesthesiaType_LIST_SUCCESS, AnesthesiaType_LIST_FAIL,
    AnesthesiaType_SAVE_REQUEST, AnesthesiaType_SAVE_SUCCESS, AnesthesiaType_SAVE_FAIL,
    PREFERENCE_LIST_REQUEST, PREFERENCE_LIST_FAIL, PREFERENCE_LIST_SUCCESS,
    PREFERENCE_SAVE_REQUEST, PREFERENCE_SAVE_FAIL, PREFERENCE_SAVE_SUCCESS,
    SURGERY_MAIN_LIST_REQUEST, SURGERY_MAIN_LIST_FAIL, SURGERY_MAIN_LIST_SUCCESS,
    SURGERY_MAIN_SAVE_REQUEST, SURGERY_MAIN_SAVE_FAIL, SURGERY_MAIN_SAVE_SUCCESS,
    SURGERY_REQUEST_LIST_REQUEST, SURGERY_REQUEST_LIST_FAIL, SURGERY_REQUEST_LIST_SUCCESS,
    OTBOOKING_LIST_REQUEST, OTBOOKING_LIST_FAIL, OTBOOKING_LIST_SUCCESS,
    OTBOOKING_SAVE_REQUEST, OTBOOKING_SAVE_FAIL, OTBOOKING_SAVE_SUCCESS,
    OTBLOCK_SAVE_REQUEST, OTBLOCK_SAVE_FAIL, OTBLOCK_SAVE_SUCCESS,
    OTBLOCK_LIST_REQUEST, OTBLOCK_LIST_FAIL, OTBLOCK_LIST_SUCCESS,
    SURGERY_DATE_UPDATE_REQUEST, SURGERY_DATE_UPDATE_FAIL, SURGERY_DATE_UPDATE_SUCCESS,
    AUTO_ALLOCATE_SAVE_REQUEST, AUTO_ALLOCATE_SAVE_FAIL, AUTO_ALLOCATE_SAVE_SUCCESS,
    PREVIEW_SAVE_REQUEST, PREVIEW_SAVE_FAIL, PREVIEW_SAVE_SUCCESS,
    OTBOOKING_UPDATE_REQUEST, OTBOOKING_UPDATE_FAIL, OTBOOKING_UPDATE_SUCCESS,
    OTBOOKING_CANCEL_REQUEST, OTBOOKING_CANCEL_FAIL, OTBOOKING_CANCEL_SUCCESS,
    OTBOOKING_CHECK_REQUEST, OTBOOKING_CHECK_FAIL, OTBOOKING_CHECK_SUCCESS,
    APPLYCHANGES_SAVE_REQUEST, APPLYCHANGES_SAVE_FAIL, APPLYCHANGES_SAVE_SUCCESS,
    OTBOOKING_CHECKACTION_REQUEST, OTBOOKING_CHECKACTION_FAIL, OTBOOKING_CHECKACTION_SUCCESS,
    OTBOOKING_CHECKACTION_CANCEL_REQUEST, OTBOOKING_CHECKACTION_CANCEL_FAIL, OTBOOKING_CHECKACTION_CANCEL_SUCCESS,
    PACKAGE_LIST_REQUEST, PACKAGE_LIST_FAIL, PACKAGE_LIST_SUCCESS,
    POST_INPUT_LIST_REQUEST, POST_INPUT_LIST_FAIL, POST_INPUT_LIST_SUCCESS,
    GET_PERI_OP_REQUEST, GET_PERI_OP_SUCCESS, GET_PERI_OP_FAIL,
    PERSONNEL_SAVE_FAIL, PERSONNEL_SAVE_SUCCESS, PERSONNEL_SAVE_REQUEST,
    PERSONNEL_GET_FAIL, PERSONNEL_GET_SUCCESS, PERSONNEL_GET_REQUEST,
    SURGERYTIMEDETAILS_GET_FAIL, SURGERYTIMEDETAILS_GET_SUCCESS, SURGERYTIMEDETAILS_GET_REQUEST,
    SURGERYTIMEDETAILS_SAVE_FAIL, SURGERYTIMEDETAILS_SAVE_SUCCESS, SURGERYTIMEDETAILS_SAVE_REQUEST,
    WOUNDDETAILS_GET_REQUEST, WOUNDDETAILS_GET_SUCCESS, WOUNDDETAILS_GET_FAIL,
    WOUNDDETAILS_SAVE_REQUEST, WOUNDDETAILS_SAVE_SUCCESS, WOUNDDETAILS_SAVE_FAIL,
    UNCONFIRM_SAVE_REQUEST, UNCONFIRM_SAVE_FAIL, UNCONFIRM_SAVE_SUCCESS,
    ACKNOWLEDGE_SAVE_REQUEST, ACKNOWLEDGE_SAVE_FAIL, ACKNOWLEDGE_SAVE_SUCCESS,
    TRANSFER_OUT_SAVE_REQUEST, TRANSFER_OUT_SAVE_FAIL, TRANSFER_OUT_SAVE_SUCCESS,
    OTTIMELINE_LIST_REQUEST, OTTIMELINE_LIST_FAIL, OTTIMELINE_LIST_SUCCESS,
    SURGERYCLEARANCE_LIST_REQUEST, SURGERYCLEARANCE_LIST_FAIL, SURGERYCLEARANCE_LIST_SUCCESS,
    SURGERY_CLEARANCE_SAVE_REQUEST, SURGERY_CLEARANCE_SAVE_FAIL, SURGERY_CLEARANCE_SAVE_SUCCESS,
    SURGERY_CLEARANCE_CANCEL_REQUEST, SURGERY_CLEARANCE_CANCEL_FAIL, SURGERY_CLEARANCE_CANCEL_SUCCESS,
    PAC_CLEARANCE_SAVE_REQUEST, PAC_CLEARANCE_SAVE_FAIL, PAC_CLEARANCE_SAVE_SUCCESS,
    OTSTAT_LIST_REQUEST, OTSTAT_LIST_FAIL, OTSTAT_LIST_SUCCESS,
    OTDASHBOARD_LIST_REQUEST, OTDASHBOARD_LIST_FAIL, OTDASHBOARD_LIST_SUCCESS,
} from '../Constants/Constants';

const intialstate = {};

function fetchOperatingRoomListReducer(state = {
    fetchOperatingRoomListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case OPERATING_ROOM_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case OPERATING_ROOM_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case OPERATING_ROOM_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                fetchOperatingRoomListData: action.payload
            }
        }
        default: return state;
    }

}

function OperatingRoomSaveReducer(state = {
    OperatingRoomSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case OPERATING_ROOM_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case OPERATING_ROOM_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case OPERATING_ROOM_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OperatingRoomSave: action.payload
            }
        }
        default: return state;
    }

}

function fetchPatientPositionListReducer(state = {
    fetchPatientPositionListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case PATIENT_POSITION_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case PATIENT_POSITION_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case PATIENT_POSITION_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                fetchPatientPositionListData: action.payload
            }
        }
        default: return state;
    }

}

function PatientPositionSaveReducer(state = {
    PatientPositionSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case PATIENT_POSITION_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case PATIENT_POSITION_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case PATIENT_POSITION_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PatientPositionSave: action.payload
            }
        }
        default: return state;
    }

}

function fetchSurgeryCategoryListReducer(state = {
    fetchSurgeryCategoryListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SURGERY_CATEGORY_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SURGERY_CATEGORY_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case SURGERY_CATEGORY_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                fetchSurgeryCategoryListData: action.payload
            }
        }
        default: return state;
    }

}

function SurgeryCategorySaveReducer(state = {
    SurgeryCategorySave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SURGERY_CATEGORY_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SURGERY_CATEGORY_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case SURGERY_CATEGORY_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SurgeryCategorySave: action.payload
            }
        }
        default: return state;
    }

}

function fetchAnesthesiaTypeListReducer(state = {
    AnesthesiaTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AnesthesiaType_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AnesthesiaType_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AnesthesiaType_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AnesthesiaTypeData: action.payload
            }
        }
        default: return state;
    }

}
function AnesthesiaTypeSaveReducer(state = {
    SaveAnesthesiaTypeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AnesthesiaType_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AnesthesiaType_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AnesthesiaType_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveAnesthesiaTypeData: action.payload
            }
        }
        default: return state;
    }

}

function fetchPreferenceListReducer(state = {
    fetchPreferenceListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case PREFERENCE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case PREFERENCE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case PREFERENCE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                fetchPreferenceListData: action.payload
            }
        }
        default: return state;
    }

}

function PreferenceSaveReducer(state = {
    PreferenceSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case PREFERENCE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case PREFERENCE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case PREFERENCE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PreferenceSave: action.payload
            }
        }
        default: return state;
    }

}
function fetchSurgeryMainListReducer(state = {
    SurgeryMainListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SURGERY_MAIN_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SURGERY_MAIN_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case SURGERY_MAIN_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SurgeryMainListData: action.payload
            }
        }
        default: return state;
    }

}

function SurgeryMainSaveReducer(state = {
    SurgeryMainSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SURGERY_MAIN_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SURGERY_MAIN_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case SURGERY_MAIN_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SurgeryMainSave: action.payload
            }
        }
        default: return state;
    }

}

function fetchSurgeryRequestListReducer(state = {
    SurgeryRequestData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SURGERY_REQUEST_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SURGERY_REQUEST_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case SURGERY_REQUEST_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SurgeryRequestData: action.payload
            }
        }
        default: return state;
    }

}
function fetchOTBookingReducer(state = {
    OTBookingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case OTBOOKING_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case OTBOOKING_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case OTBOOKING_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OTBookingData: action.payload
            }
        }
        default: return state;
    }

}
function fetchOTStatReducer(state = {
    OTStatData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case OTSTAT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case OTSTAT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case OTSTAT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OTStatData: action.payload
            }
        }
        default: return state;
    }

}
function OTBookingSaveReducer(state = {
    OTBookingSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case OTBOOKING_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case OTBOOKING_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case OTBOOKING_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OTBookingSave: action.payload
            }
        }
        default: return state;
    }

}
function OTBookingUpdateReducer(state = {
    OTBookingUpdate: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case OTBOOKING_UPDATE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case OTBOOKING_UPDATE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case OTBOOKING_UPDATE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OTBookingUpdate: action.payload
            }
        }
        default: return state;
    }

}
function CheckActionBookingReducer(state = {
    CheckActionBooking: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case OTBOOKING_CHECKACTION_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case OTBOOKING_CHECKACTION_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case OTBOOKING_CHECKACTION_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CheckActionBooking: action.payload
            }
        }
        default: return state;
    }

}
function CheckActionCancelReducer(state = {
    CheckActionCancel: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case OTBOOKING_CHECKACTION_CANCEL_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case OTBOOKING_CHECKACTION_CANCEL_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case OTBOOKING_CHECKACTION_CANCEL_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CheckActionCancel: action.payload
            }
        }
        default: return state;
    }

}
function OTBookingCheckReducer(state = {
    OTBookingCheck: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case OTBOOKING_CHECK_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case OTBOOKING_CHECK_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case OTBOOKING_CHECK_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OTBookingCheck: action.payload
            }
        }
        default: return state;
    }

}
function OTBookingCancelReducer(state = {
    OTBookingCancel: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case OTBOOKING_CANCEL_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case OTBOOKING_CANCEL_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case OTBOOKING_CANCEL_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OTBookingCancel: action.payload
            }
        }
        default: return state;
    }

}
function OTBlockSlotSaveReducer(state = {
    OTBlockSlotSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case OTBLOCK_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case OTBLOCK_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case OTBLOCK_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OTBlockSlotSave: action.payload
            }
        }
        default: return state;
    }

}
function fetchOTBlockSlotReducer(state = {
    OTBlockSlotData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case OTBLOCK_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case OTBLOCK_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case OTBLOCK_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OTBlockSlotData: action.payload
            }
        }
        default: return state;
    }

}
function updatePlannedSurgeryDateReducer(state = {
    updatePlannedSurgeryDate: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SURGERY_DATE_UPDATE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SURGERY_DATE_UPDATE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case SURGERY_DATE_UPDATE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                updatePlannedSurgeryDate: action.payload
            }
        }
        default: return state;
    }

}
function autoAllocateOTReducer(state = {
    autoAllocateOT: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AUTO_ALLOCATE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AUTO_ALLOCATE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AUTO_ALLOCATE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                autoAllocateOT: action.payload
            }
        }
        default: return state;
    }

}
function previewOTReducer(state = {
    previewOT: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case PREVIEW_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case PREVIEW_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case PREVIEW_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                previewOT: action.payload
            }
        }
        default: return state;
    }

}
function unconfirmOTReducer(state = {
    unconfirmOT: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case UNCONFIRM_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case UNCONFIRM_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case UNCONFIRM_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                unconfirmOT: action.payload
            }
        }
        default: return state;
    }

}
function acknowledgeOTReducer(state = {
    acknowledgeOT: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case ACKNOWLEDGE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case ACKNOWLEDGE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case ACKNOWLEDGE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                acknowledgeOT: action.payload
            }
        }
        default: return state;
    }

}
function transferOutOTReducer(state = {
    transferOutOT: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case TRANSFER_OUT_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case TRANSFER_OUT_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case TRANSFER_OUT_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                transferOutOT: action.payload
            }
        }
        default: return state;
    }

}
function ApplyChangesReducer(state = {
    ApplyChanges: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case APPLYCHANGES_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case APPLYCHANGES_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case APPLYCHANGES_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ApplyChanges: action.payload
            }
        }
        default: return state;
    }

}
function SurgeryPostingInputReducer(state = {
    SurgeryPostingInput: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case POST_INPUT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case POST_INPUT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case POST_INPUT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SurgeryPostingInput: action.payload
            }
        }
        default: return state;
    }

}
function PackageListByServiceReducer(state = {
    PackageListByService: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case PACKAGE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case PACKAGE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case PACKAGE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PackageListByService: action.payload
            }
        }
        default: return state;
    }

}
function fetchPeriOPReducer(state = {
    fetchPeriOPData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case GET_PERI_OP_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case GET_PERI_OP_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case GET_PERI_OP_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                fetchPeriOPData: action.payload
            }
        }
        default: return state;
    }

}
function savePeriOPPersonnelReducer(state = {
    SavePeriOPPersonnelData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case PERSONNEL_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case PERSONNEL_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case PERSONNEL_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SavePeriOPPersonnelData: action.payload
            }
        }
        default: return state;
    }

}
function fetchPeriOPPersonnelReducer(state = {
    fetchPeriOPPeriOPPersonnelData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case PERSONNEL_GET_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case PERSONNEL_GET_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case PERSONNEL_GET_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                fetchPeriOPPeriOPPersonnelData: action.payload
            }
        }
        default: return state;
    }

}
function fetchPeriOPSurgeryTimeDetailReducer(state = {
    fetchPeriOPPeriOPSurgeryTimeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SURGERYTIMEDETAILS_GET_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SURGERYTIMEDETAILS_GET_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case SURGERYTIMEDETAILS_GET_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                fetchPeriOPPeriOPSurgeryTimeData: action.payload
            }
        }
        default: return state;
    }

}
function savePeriOPSurgeryTimeDetailReducer(state = {
    savePeriOPPeriOPSurgeryTimeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SURGERYTIMEDETAILS_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SURGERYTIMEDETAILS_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case SURGERYTIMEDETAILS_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                savePeriOPPeriOPSurgeryTimeData: action.payload
            }
        }
        default: return state;
    }

}
function fetchPeriOPSurgeryWoundDetailReducer(state = {
    fetchPeriOPPeriOPSurgeryWoundDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case WOUNDDETAILS_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case WOUNDDETAILS_GET_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case WOUNDDETAILS_GET_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                fetchPeriOPPeriOPSurgeryWoundDetailData: action.payload
            }
        }
        default: return state;
    }

}
function savePeriOPSurgeryWoundDetailReducer(state = {
    savePeriOPPeriOPSurgeryWoundDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case WOUNDDETAILS_GET_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case WOUNDDETAILS_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case WOUNDDETAILS_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                savePeriOPPeriOPSurgeryWoundDetailData: action.payload
            }
        }
        default: return state;
    }

}
function getOTTimelineReducer(state = {
    OTTimeline: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case OTTIMELINE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case OTTIMELINE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case OTTIMELINE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OTTimeline: action.payload
            }
        }
        default: return state;
    }

}
function GetSurgeryClearanceReducer(state = {
    SurgeryClearance: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SURGERYCLEARANCE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SURGERYCLEARANCE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case SURGERYCLEARANCE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SurgeryClearance: action.payload
            }
        }
        default: return state;
    }

}
function SaveSurgeryClearanceReducer(state = {
    SaveSurgeryClearance: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SURGERY_CLEARANCE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SURGERY_CLEARANCE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case SURGERY_CLEARANCE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveSurgeryClearance: action.payload
            }
        }
        default: return state;
    }

}
function CancelSurgeryClearanceReducer(state = {
    CancelSurgeryClearance: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SURGERY_CLEARANCE_CANCEL_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SURGERY_CLEARANCE_CANCEL_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case SURGERY_CLEARANCE_CANCEL_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CancelSurgeryClearance: action.payload
            }
        }
        default: return state;
    }

}
function SavePACClearanceReducer(state = {
    SavePACClearance: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case PAC_CLEARANCE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case PAC_CLEARANCE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case PAC_CLEARANCE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SavePACClearance: action.payload
            }
        }
        default: return state;
    }

}
function fetchOTDashboardReducer(state = {
    OTDashboardData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case OTDASHBOARD_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case OTDASHBOARD_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case OTDASHBOARD_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OTDashboardData: action.payload
            }
        }
        default: return state;
    }

}



export {
    fetchOperatingRoomListReducer, OperatingRoomSaveReducer,
    fetchPatientPositionListReducer, PatientPositionSaveReducer,
    fetchSurgeryCategoryListReducer, SurgeryCategorySaveReducer,
    fetchAnesthesiaTypeListReducer, AnesthesiaTypeSaveReducer,
    fetchPreferenceListReducer, PreferenceSaveReducer,
    fetchSurgeryMainListReducer, SurgeryMainSaveReducer,
    fetchSurgeryRequestListReducer, fetchOTBookingReducer,
    OTBookingSaveReducer, OTBlockSlotSaveReducer, fetchOTBlockSlotReducer,
    updatePlannedSurgeryDateReducer, autoAllocateOTReducer, previewOTReducer,
    OTBookingUpdateReducer, OTBookingCancelReducer, OTBookingCheckReducer,
    ApplyChangesReducer, CheckActionBookingReducer, PackageListByServiceReducer,
    SurgeryPostingInputReducer, fetchPeriOPReducer, fetchPeriOPPersonnelReducer, savePeriOPPersonnelReducer,
    fetchPeriOPSurgeryTimeDetailReducer, savePeriOPSurgeryTimeDetailReducer,
    fetchPeriOPSurgeryWoundDetailReducer, savePeriOPSurgeryWoundDetailReducer,
    CheckActionCancelReducer, unconfirmOTReducer, acknowledgeOTReducer, transferOutOTReducer, getOTTimelineReducer,
    GetSurgeryClearanceReducer, SaveSurgeryClearanceReducer,CancelSurgeryClearanceReducer, SavePACClearanceReducer, fetchOTStatReducer, fetchOTDashboardReducer

};