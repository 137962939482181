import * as Dlst from '../Constant/DLConstant';

const intialstate = {};

function GetDialysisServiceListReducer(state = {
    GetDialysisServiceListdata: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Dlst.GetDialysisService_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Dlst.GetDialysisService_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Dlst.GetDialysisService_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetDialysisServiceListdata: action.payload
            }
        }
        default: return state;
    }

}

export {
    GetDialysisServiceListReducer,
};